<template>
   <div class="seat-map-modal-header">
      <ul>
         <template v-for="(s, stepIndex) in stepList">
            <li :key="stepIndex" :class="{ activeStep: s.index == step.activeStep, done: s.status }" @click="handlerJump2Step(s.index)">
               <template v-if="s.index != 0 && s.index != 1">
                  <div class="route-informations-inner-wrapper">
                     <div class="route-informations">
                        <span> {{ handlerParseRoute(s.name).from }} - </span> <span> {{ handlerParseRoute(s.name).to }} </span>
                     </div>
                     <div class="route-time-informations" v-if="false">{{ s.departureTime | FormatterDate }}</div>
                  </div>
               </template>
               <template v-else>
                  <div class="route-informations-inner-wrapper">
                     <div class="route-informations">
                        <span> {{ s.name }} </span>
                     </div>
                  </div>
               </template>
            </li>
         </template>
      </ul>

      <div class="header-right-section">
         <div class="countdown" v-if="step.activeStep != 1">{{ formattedTime }}</div>

         <div id="close-ancillaries-modal-wrapper" @click="handlerClosePopup" v-if="step.activeStep != 0">
            <div id="close-ancillaries-modal"></div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "SeatMapModalHeader",
   props: {
      step: Object,
      stepList: Array,
   },
   data() {
      return {
         timeRemaining: 180,
         intervalId: null,
      };
   },
   mounted() {},
   beforeDestroy() {
      clearInterval(this.intervalId);
   },
   computed: {
      PaymentStatus() {
         return this.$store.state.seatmap.paymentStatus;
      },
      SteplistController() {
         return this.$store.state.seatmap.stepList;
      },
      formattedTime() {
         const minutes = Math.floor(this.timeRemaining / 60);
         const seconds = this.timeRemaining % 60;
         return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
      },
   },
   methods: {
      startCountdown() {
         this.intervalId = setInterval(() => {
            if (this.timeRemaining > 0) {
               this.timeRemaining -= 1;
            } else {
               clearInterval(this.intervalId);
               this.handlerClosePopup();
            }
         }, 1000);
      },
      handlerJump2Step(stepIndex) {
         if (this.step.activeStep != 1 && this.step.activeStep != 0) {
            if (this.stepList.length - 1 != stepIndex && stepIndex != 0 && stepIndex != 1) {
               this.$emit("gotoStep", stepIndex);
            }
         }
      },
      show(index) {
         return this.step.totalStep - 1 == index;
      },

      handlerParseRoute(route) {
         const [from, to] = route.split("-");
         return {
            from: from,
            to: to,
         };
      },
      handlerClosePopup() {
         this.$store.commit("seatmap/setStatus", false);
      },
   },
   watch: {
      SteplistController: {
         handler: function (arr) {
            if (arr.length != 0) {
               this.startCountdown();
            }
         },
      },
      PaymentStatus: {
         handler: function (status) {
            if (status) {
               clearInterval(this.intervalId);
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.seat-map-modal-header {
   display: flex;
   justify-content: space-between;
   padding-bottom: 0 !important;

   @media only screen and (max-width: 768px) {
      flex-direction: column;
      flex-flow: column-reverse;
   }

   .header-right-section {
      display: flex;
      @media only screen and (max-width: 768px) {
         justify-content: flex-end;
         margin-bottom: 1rem;
      }
      @keyframes countdownAnimation {
         from {
            color: #363636;
         }
         to {
            color: #e2e2e2;
         }
      }
      .countdown {
         margin-right: 1rem;
         font-weight: 500;
         font-size: 2rem;
         animation: countdownAnimation 1s ease-in-out infinite;
         font-family: "Roboto Mono";
         // background-color: #eee;
         // border: 1px solid #eee;
         border-radius: 0.5rem;
         padding: 0 0.5rem;
         margin-top: -5px;
      }
   }
}
ul {
   display: flex;
   position: relative;
   width: fit-content;
   flex-wrap: wrap;

   li {
      z-index: 2;
      margin: 0 0.125rem;
      // padding: 0.25rem 0.75rem;
      border-radius: 6px;
      cursor: pointer;
      // background-color: #eee;
      background-color: #fff;
      display: flex;
      // flex-direction: column;
      align-items: center;
      // border: 2px solid #eee;
      // padding-right: 1rem;
      // margin-right: 0.5rem;
      font-size: 1.25rem;
      line-height: 20px;

      @media only screen and (max-width: 768px) {
         font-size: 1rem;
         margin: 0;
      }

      .route-informations-inner-wrapper {
         border: 2px solid #eee;
         border-bottom: none !important;
         border-radius: 0.75rem;
         border-bottom-left-radius: 0;
         border-bottom-right-radius: 0;
         padding: 0.75rem 1.25rem;
         @media only screen and (max-width: 768px) {
            padding: 0.75rem 0.5rem;
         }
      }

      .route-informations {
         display: flex;
      }

      .route-time-informations {
         font-size: var(--small);
         font-weight: 400;
         opacity: 0.2;
         position: absolute;
      }

      &.dones {
         background-color: var(--accent-primary);
         color: #fff !important;
         opacity: 1;
         border-radius: 10px;
         border-bottom-left-radius: 0;
         border-bottom-right-radius: 0;
         span {
            opacity: 1;
         }
         span {
            //  background-color: #bcf5d9;
            // opacity: 1;
            font-weight: 500;
            // color: #36e28c !important;
            // color: var(--accent-primary) !important;
         }
      }

      span.icon-selected {
         // opacity: 1;
         display: flex;
         background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE1IDE1Ij4KICA8ZyBpZD0iR3JvdXBfMTIwMjEiIGRhdGEtbmFtZT0iR3JvdXAgMTIwMjEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zNDQuMTg1IC0xMDIwLjkxMikiPgogICAgPHBhdGggaWQ9IlN1YnRyYWN0aW9uXzEiIGRhdGEtbmFtZT0iU3VidHJhY3Rpb24gMSIgZD0iTTcuNSwxNUE3LjUsNy41LDAsMCwxLDIuMiwyLjIsNy41LDcuNSwwLDEsMSwxMi44LDEyLjgsNy40NSw3LjQ1LDAsMCwxLDcuNSwxNVpNMy43NSw3Ljg4NGEuODY2Ljg2NiwwLDAsMC0uNDc0LDEuNTkxTDYuMiwxMS4zOGwwLDAsMCwwLC4wMTMuMDA5YS44Ni44NiwwLDAsMCwuNDUzLjE0N2guMDY2YS4wNy4wNywwLDAsMSwuMDE4LDBINi43N2EuMDU3LjA1NywwLDAsMSwuMDE2LDBINi44YS4wMzUuMDM1LDAsMCwxLC4wMTUsMGwuMDA5LDBoLjAwOWwuMDA5LDBoLjAwNWwuMDEyLDAsLjAwOCwwLC4wMDcsMGEuMDUzLjA1MywwLDAsMCwuMDE4LDBINi45bC4wMiwwaDBsLjAwOSwwaC4wMDhsLjAwNiwwYS4wMDUuMDA1LDAsMCwwLC4wMDUsMGwuMDEsMGguMDA2bDAsMGEuMDA3LjAwNywwLDAsMCwuMDA1LDBsLjAyLS4wMDZzMCwwLDAsMGwuMDA3LDAsMCwwLC4wMDksMCwwLDBhLjE1LjE1LDAsMCwwLC4wMjItLjAxbC4wMDYsMCwuMDE3LS4wMDcsMCwwYS4wNjkuMDY5LDAsMCwxLC4wMi0uMDFsMCwwLC4wMTIsMCwuMDA4LDAsMCwwLC4wMTQtLjAwOC4wMDYsMCwwLDAsLjAwOS0uMDA2LjAwNiwwLDAsMHMwLDAsMCwwbC4wMTgtLjAxMSwwLDAsMCwwLC4wMDgtLjAwNi4wMDgsMHMwLDAsLjAwNSwwbC4wMDgtLjAwNi4wMDgsMCwuMDA1LS4wMDYsMCwwLC4wMTEtLjAwOWEuMDIyLjAyMiwwLDAsMCwuMDA2LS4wMDZsLjAwNy0uMDA2LDAsMCwuMDA2LDAsMCwwaDBsMCwwLDAsMCwuMDExLS4wMDguMDA3LS4wMDlhLjA0MS4wNDEsMCwwLDAsLjAxMS0uMDFsLjAwNi0uMDA2LDAsMCwuMDA2LDAsMCwwLDAsMCwuMDA5LS4wMTEsMCwwLDAsMGEuMDQ3LjA0NywwLDAsMCwuMDA5LS4wMTFsLjAwNy0uMDA3YS4wNjUuMDY1LDAsMCwwLC4wMTEtLjAxNS4wMTMuMDEzLDAsMCwwLC4wMDUsMGwwLDBoMGwuMDA1LS4wMDcuMDA1LS4wMDcsMCwwLDAsMCwwLS4wMDcsMC0uMDA3LjAwNy0uMDA5LDAtLjAwNywwLS4wMDcsMCwwaDBsMCwwLDQuMjktNi4zNTRhLjg2NS44NjUsMCwxLDAtMS40MzQtLjk2OEw2LjQ2Nyw5LjQ4Niw0LjIyMyw4LjAyNUEuODYzLjg2MywwLDAsMCwzLjc1LDcuODg0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzQ0LjE4NSAxMDIwLjkxMikiIGZpbGw9IiMzNGI3ZmYiLz4KICA8L2c+Cjwvc3ZnPgo=");
         background-size: contain;
         $size: 16px;
         width: $size;
         height: $size;
         margin-left: 0.5rem;
      }
      span {
         opacity: 0.5;
         font-weight: 500;
      }

      &::after {
         opacity: 1 !important;
         background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMS43NjQiIGhlaWdodD0iMTQuMzE4IiB2aWV3Qm94PSIwIDAgMTEuNzY0IDE0LjMxOCI+CiAgPGcgaWQ9Ikdyb3VwXzIwNDA2IiBkYXRhLW5hbWU9Ikdyb3VwIDIwNDA2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjg3OSAwLjg4KSIgb3BhY2l0eT0iMC4zIj4KICAgIDxwYXRoIGlkPSJQYXRoXzEwNTA3IiBkYXRhLW5hbWU9IlBhdGggMTA1MDciIGQ9Ik0xNjkyNCwxNTg4NC43NGw1LjEyOSw2LjI3OS01LjEyOSw2LjI3OSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE2OTI0IC0xNTg4NC43NCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzM2MzYzNiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuMjUiLz4KICAgIDxwYXRoIGlkPSJQYXRoXzEwNTA4IiBkYXRhLW5hbWU9IlBhdGggMTA1MDgiIGQ9Ik0xNjkyNCwxNTg4NC43NGw1LjEyOSw2LjI3OS01LjEyOSw2LjI3OSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE2OTE4Ljg2OSAtMTU4ODQuNzQpIiBmaWxsPSJub25lIiBzdHJva2U9IiMzNjM2MzYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjI1Ii8+CiAgPC9nPgo8L3N2Zz4K");
         background-repeat: no-repeat;
         background-position: center;
         // background-color: red;
         margin: auto;
         width: 20px;
         height: 20px;
         display: block;
         content: "";
         margin-left: 1rem;
         margin-right: 1rem;
         display: none;
      }

      &:last-child {
         margin-right: 0;
         &::after {
            display: none;
         }
      }

      &.activeStep {
         // background-color: #f2f2f2;
         // padding: 0.5rem;
         // transform: scale(1.15);

         .route-informations-inner-wrapper {
            background-color: var(--primary);
            .route-time-informations {
               opacity: 0.6;
               // color: var(--accent-primary);
            }
            span {
               color: var(--primary);
               color: #fff;
               opacity: 1;
               font-weight: 800;
            }
         }
      }

      .flight-icon {
         display: flex;
         $iconSize: 24px !important;
         width: $iconSize;
         height: $iconSize;
         min-width: $iconSize;
         background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNy45MTciIGhlaWdodD0iMjcuOTE3IiB2aWV3Qm94PSIwIDAgMjcuOTE3IDI3LjkxNyI+CiAgPHBhdGggaWQ9ImZsaWdodCIgZD0iTTE1LjksMTUuODYsMTQuMzY4LDcuNDA2YTEuMzIsMS4zMiwwLDAsMSwuMzI2LTEuMTIyYzEuMDU1LTEuMTYyLDEuOTkxLTIuMjc1LDMuMDU0LTMuNDE3QTEuODc0LDEuODc0LDAsMCwwLDE3LjYxMS42MTFhMS44OCwxLjg4LDAsMCwwLTIuNDIyLS4zYy0xLjE2MSwxLjA4LTIuMjkyLDIuMDI5LTMuNDc0LDMuMWExLjMyMywxLjMyMywwLDAsMS0xLC4zNDNsLTguMzI2LTEuNEwuMjksMy44ODMsOC4wNDcsNy40MzksNC44NDEsMTEuMDg2LDEuNDMsMTAuOTUzLDAsMTIuMzgzbDQuMywxLjU3MSwxLjU3MSw0LjMsMS40My0xLjQzTDcsMTMuMjQ0LDEwLjYwNSwxMGwzLjc2NCw3Ljk2NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzLjk2NyAxLjEzMSkgcm90YXRlKDQ1KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzYzNjM2IiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K");
         background-size: cover;
         margin: 0 0.75rem;
      }
      .stepNumber {
         $size: 26px;
         background: #eee;
         width: $size;
         height: $size;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 24rem;
         margin-right: 0.5rem;
         background-color: #363636;
         color: #fff;
      }
   }
}
#close-ancillaries-modal-wrapper {
   display: flex;
   justify-content: flex-end;

   #close-ancillaries-modal {
      $size: 36px;
      width: $size;
      height: $size;
      background-color: #eaeaea;
      border-radius: 50%;
      position: relative;
      display: block;
      transform: scale(1);
      cursor: pointer;

      &:hover {
         transform: scale(1.125);
         background: #ccc;
      }

      &::before,
      &::after {
         content: "";
         width: 18px;
         height: 2px;
         background: #363636;
         display: block;
         border-radius: 1rem;
         position: absolute;
         inset: 0;
         margin: auto;
      }

      &::before {
         transform: rotate(45deg);
      }

      &::after {
         transform: rotate(-45deg);
      }
   }
}
</style>
