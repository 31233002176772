<template>
   <div v-if="filters.vehicles && filters.vehicles.length > 1" id="transfer-filters-container">
      <h5>{{ $t("TransferFilters_lblFilters") }}</h5>
      <div class="transfer-filter-vehicle">
         <div class="filter-wrapper">
            <div class="filter-title-wrapper">
               <span class="filter-title">{{ $t("TransferFilters_lblVehicles") }}</span>
               <a @click="handleSelectAll(filters.vehicles, 'vehicles')" v-if="selectAll.vehicles">
                  {{ SelectAllText.selectAll }}
               </a>
               <a @click="handleClearAll(filters.vehicles, 'vehicles')" v-else>
                  {{ SelectAllText.clearAll }}
               </a>
            </div>
            <div class="checkbox-container">
               <UICheckBox
                  v-for="(vehicle, index) in filters.vehicles"
                  :key="index"
                  :label="vehicle.type | ETrfVehicleType"
                  :id="'vehicle' + index"
                  :name="vehicle.type | ETrfVehicleType"
                  v-model="vehicle.checked" />
            </div>
         </div>
      </div>
      <div v-if="filters.transferTypes && filters.transferTypes.length > 1" class="transfer-filter-transfer-type">
         <div class="filter-wrapper">
            <div class="filter-title-wrapper">
               <span class="filter-title">{{ $t("TransferFilters_lblTransferTypes") }}</span>
               <a @click="handleSelectAll(filters.transferTypes, 'transferTypes')" v-if="selectAll.transferTypes">
                  {{ SelectAllText.selectAll }}
               </a>
               <a @click="handleClearAll(filters.transferTypes, 'transferTypes')" v-else>
                  {{ SelectAllText.clearAll }}
               </a>
            </div>
            <div class="checkbox-container">
               <UICheckBox
                  v-for="(transferType, index) in filters.transferTypes"
                  :key="index"
                  :label="transferType.type | ETrfTransferType"
                  :id="'transferType' + index"
                  :name="transferType.type | ETrfTransferType"
                  v-model="transferType.checked" />
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import UICheckBox from "@/components/unitary/form2/UICheckBox.vue";

export default {
   name: "TransferFilters",
   props: {
      searchResponse: Object,
   },
   components: {
      UICheckBox,
   },
   data() {
      return {
         filters: {},
         filteredTransfers: {},
         selectAll: {
            vehicles: false,
            transferTypes: false,
         },
      };
   },
   computed: {
      SelectAllText() {
         return {
            selectAll: this.$i18n.t("TransferFilters_selectAll"),
            clearAll: this.$i18n.t("TransferFilters_deselectAll"),
         };
      },
   },
   methods: {
      handleClearAll(el, name) {
         Object.keys(el).forEach((transfer) => {
            el[transfer].checked = false;
         });
         this.selectAll[name] = !this.selectAll[name];
      },
      handleSelectAll(el, name) {
         Object.keys(el).forEach((transfer) => {
            el[transfer].checked = true;
         });
         this.selectAll[name] = !this.selectAll[name];
      },
      filterTransfers() {
         var selectedVehicles = this.filters.vehicles.filter((vehicle) => vehicle.checked).map((vehicle) => vehicle.type);
         var selectedTransferTypes = this.filters.transferTypes
            .filter((transferType) => transferType.checked)
            .map((transferType) => transferType.type);
         this.filteredTransfers.departure.forEach((transfer) => {
            transfer.isShowActive = true;

            if (!selectedVehicles.includes(transfer.vehicle.type)) {
               transfer.isShowActive = false;
            }
            if (!selectedTransferTypes.includes(transfer.transferType)) {
               transfer.isShowActive = false;
            }
         });
         this.filteredTransfers.return.forEach((transfer) => {
            transfer.isShowActive = true;

            if (!selectedVehicles.includes(transfer.vehicle.type)) {
               transfer.isShowActive = false;
            }
            if (!selectedTransferTypes.includes(transfer.transferType)) {
               transfer.isShowActive = false;
            }
         });
         this.$emit("filterResponse", this.filteredTransfers);
      },
   },
   watch: {
      searchResponse: {
         handler: function (searchResponse) {
            var transfers = searchResponse.transfers;
            var vehicles = [];
            var transferTypes = [];
            this.filteredTransfers = {
               departure: [],
               return: [],
            };
            for (let legIndex in transfers) {
               for (let transfer of transfers[legIndex]) {
                  if (legIndex == 1) {
                     this.filteredTransfers.departure.push({ ...transfer, isShowActive: true, isChecked: false });
                  } else {
                     this.filteredTransfers.return.push({ ...transfer, isShowActive: true, isChecked: false });
                  }
                  var transferType = { type: transfer.transferType, checked: true };
                  var vehicleType = { type: transfer.vehicle.type, checked: true };

                  if (transferTypes.filter((transfer) => transfer.type == transferType.type).length == 0) {
                     transferTypes.push(transferType);
                  }

                  if (vehicles.filter((transfer) => transfer.type == vehicleType.type).length == 0) {
                     vehicles.push(vehicleType);
                  }
               }
            }
            this.filteredTransfers.departure.sort((a, b) => (a.price.totalFare > b.price.totalFare ? 1 : -1));
            this.filteredTransfers.return.sort((a, b) => (a.price.totalFare > b.price.totalFare ? 1 : -1));
            this.filters = {
               vehicles: vehicles,
               transferTypes: transferTypes,
            };
         },
         immediate: true,
         deep: true,
      },
      filters: {
         handler: function () {
            this.filterTransfers();
         },
         immediate: true,
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#transfer-filters-container {
   padding: 1rem 0.75rem;
   transition-property: padding;
   transition-timing-function: ease-in;
   transition-duration: 0.15s;
   position: relative;
   background: var(--c-primary);
   padding: 2rem 18px;
   margin: 1.5rem 0rem;
   border-radius: 0.5rem;
   width: 200px;
   &.more-whitespace {
      padding: 2rem 0.75rem;
   }

   .filter-wrapper {
      border-bottom: 1px solid #dcdcdc;
      margin: 0.5rem 0;
      padding: 0.5rem 0.25rem;

      .filter-title-wrapper {
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin-bottom: 0.75rem;
         span.filter-title {
            font-weight: 500;
            font-size: var(--xsmall);
         }
         a {
            font-size: 0.75rem;
            font-weight: 500;
            color: var(--accent-primary);
         }
      }
      .checkbox-container {
         .g-checkbox-container {
            margin-bottom: 0.5rem;
            font-size: var(--xsmall);
         }
      }

      .hours-container {
         .hour-viewers-wrapper {
            display: flex;
            justify-content: space-between;
            span {
               border-radius: 0.25rem;
               border: 1px solid #ccc;
               padding: 0.125rem 0.25rem;
               font-weight: 500;
               background: #eee;
            }
         }
      }

      &:last-child {
         border-bottom: none !important;
      }
   }
}
</style>
