export const EFltPnrStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NEW",
   2: "RESERVED",
   3: "ISSUED",
   4: "CANCELED",
   5: "VOIDED",
   6: "REFUNDED",
   7: "REISSUED",
   8: "ERROR",
   9: "OPEN",
   10: "PENDING_ISSUED",
   11: "PENDING_RESERVED",
   12: "PENDING_CANCELLED",
   13: "PENDING_VOIDED",
   14: "PENDING_REFUNDED",
};
export const EFltEticketStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NEW",
   2: "RESERVED",
   3: "ISSUED",
   4: "CANCELED",
   5: "VOIDED",
   6: "REFUNDED",
   7: "REISSUED",
   8: "ERROR",
   9: "OPEN",
   10: "PENDING_ISSUED",
   11: "PENDING_RESERVED",
   12: "PENDING_CANCELLED",
   13: "PENDING_VOIDED",
   14: "PENDING_REFUNDED",
};
export const EFltCabinType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "ECONOMY",
   2: "BUSINESS",
};

export const EFltPaxType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "ADULT",
   2: "CHILD",
   3: "INFANT",
   4: "DISABLED",
   5: "DISABLED_COMPANION",
   6: "EXTRA_SEAT",
   99: "ADULT_WITH_INFANT",
};
export const EFltSsrType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "BAGGAGE",
   2: "SEAT",
   3: "MEAL",
   4: "WHEELCHAIR",
   5: "OTHER",
   6: "CHECKIN_AT_AIRPORT",
   7: "REISSUE_ALLOWANCE",
   8: "CABIN_BAG",
};

export const EFltSsrMealType = {
   "-1": "ALL", // All meals
   0: "UNKNOWN", // Unknown meal
   1: "HNML", // Hindu Meal
   2: "CHML", // Child Meal
   3: "MOML", // Moslem Meal
   4: "VGML", // Vegetarian Vegan Meal
   5: "CNML", // Chicken Meal
   6: "FSML", // Fish Meal
   7: "AVML", // Vegetarian Hindu Meal
   8: "BBML", // Baby Meal
   9: "BLML", // Bland Meal
   10: "DBML", // Diabetic Meal
   11: "FPML", // Fruit Platter Meal
   12: "GFML", // Gluten Intolerant Meal
   13: "KSML", // Kosher Meal
   14: "LCML", // Low Calorie Meal
   15: "LFML", // Low Fat Meal
   16: "LPML", // Low Protein Meal
   17: "LSML", // Meal without Salt
   18: "NLML", // Meal without Lactose
   19: "SFML", // Sea Food Meal
   20: "VJML", // Vegetarian Jain Meal
   21: "VLML", // Vegetarian Meal Lacto-Ovo
   22: "VOML", // Vegetarian Oriental Meal
   23: "SPML", // Special Meal

   // Pribas Meal Types
   100: "PRIBAS_SUN_PMID", // Kızarmış Çıtır Tavuk (international)
   101: "PRIBAS_SUN_PMIE", // Dana Gulaş (international)
   102: "PRIBAS_SUN_PMIB", // Kahvaltı Tabağı (international)
   103: "PRIBAS_SUN_PMIC", // Tavuk Şiş Kebap (international)
   104: "PRIBAS_SUN_PMDD", // Kızarmış Çıtır Tavuk (domestic)
   105: "PRIBAS_SUN_PMDE", // Dana Gulaş (domestic)
   106: "PRIBAS_SUN_PMDB", // Kahvaltı Tabağı (domestic)
   107: "PRIBAS_SUN_PMDC", // Tavuk Şiş Kebap (domestic)
   117: "PRIBAS_SUN_SPIB", // Sun Premium Omlet & 2 İçecek (international)
   118: "PRIBAS_SUN_SPIC", // Sun Premium Köfte & 2 İçecek (international)
   119: "PRIBAS_SUN_SPID", // Sun Premium Patlıcan & 2 İçecek (international)
   120: "PRIBAS_SUN_SPIE", // Sun Premium Tavuk & 2 İçecek (international)
   121: "PRIBAS_SUN_SPDB", // Sun Premium Omlet & 2 İçecek (domestic)
   122: "PRIBAS_SUN_SPDC", // Sun Premium Köfte & 2 İçecek (domestic)
   123: "PRIBAS_SUN_SPDD", // Sun Premium Patlıcan & 2 İçecek (domestic)
   124: "PRIBAS_SUN_SPDE", // Sun Premium Tavuk & 2 İçecek (domestic)
   125: "PRIBAS_SUN_SPVL", // Meze Tabağı (vejetaryen), 1 alkolsüz içecek
   126: "PRIBAS_SUN_SPVG", // Falafel (vegan), 1 alkolsüz içecek
   127: "PRIBAS_SUN_VGML", // Falafel (vegan)
   128: "PRIBAS_SUN_VLML", // Meze Tabağı (vejetaryen)
   129: "PRIBAS_SUN_SCSM", // SunClassic Snack Box
   130: "PRIBAS_SUN_CHML", // Çocuk Menüsü
};

export const EFltSsrMealTypeReverse = {
   ALL: "-1",
   UNKNOWN: 0,
   HNML: 1, // Hindu Meal
   CHML: 2, // Child Meal
   MOML: 3, // Moslem Meal
   VGML: 4, // Vegetarian Vegan Meal
   CNML: 5, // Chicken Meal
   FSML: 6, // Fish Meal
   AVML: 7, // Vegetarian Hindu Meal
   BBML: 8, // Baby Meal
   BLML: 9, // Bland Meal
   DBML: 10, // Diabetic Meal
   FPML: 11, // Fruit Platter Meal
   GFML: 12, // Gluten Intolerant Meal
   KSML: 13, // Kosher Meal
   LCML: 14, // Low Calorie Meal
   LFML: 15, // Low Fat Meal
   LPML: 16, // Low Protein Meal
   LSML: 17, // Meal without Salt
   NLML: 18, // Meal without Lactose
   SFML: 19, // Sea Food Meal
   VJML: 20, // Vegetarian Jain Meal
   VLML: 21, // Vegetarian Meal Lacto-Ovo
   VOML: 22, // Vegetarian Oriental Meal
   SPML: 23, // Special Meal

   // Pribas Meal Types
   PRIBAS_SUN_PMID: 100, // Kızarmış Çıtır Tavuk (international)
   PRIBAS_SUN_PMIE: 101, // Dana Gulaş (international)
   PRIBAS_SUN_PMIB: 102, // Kahvaltı Tabağı (international)
   PRIBAS_SUN_PMIC: 103, // Tavuk Şiş Kebap (international)
   PRIBAS_SUN_PMDD: 104, // Kızarmış Çıtır Tavuk (domestic)
   PRIBAS_SUN_PMDE: 105, // Dana Gulaş (domestic)
   PRIBAS_SUN_PMDB: 106, // Kahvaltı Tabağı (domestic)
   PRIBAS_SUN_PMDC: 107, // Tavuk Şiş Kebap (domestic)
   PRIBAS_SUN_SPIB: 117, // Sun Premium Omlet & 2 İçecek (international)
   PRIBAS_SUN_SPIC: 118, // Sun Premium Köfte & 2 İçecek (international)
   PRIBAS_SUN_SPID: 119, // Sun Premium Patlıcan & 2 İçecek (international)
   PRIBAS_SUN_SPIE: 120, // Sun Premium Tavuk & 2 İçecek (international)
   PRIBAS_SUN_SPDB: 121, // Sun Premium Omlet & 2 İçecek (domestic)
   PRIBAS_SUN_SPDC: 122, // Sun Premium Köfte & 2 İçecek (domestic)
   PRIBAS_SUN_SPDD: 123, // Sun Premium Patlıcan & 2 İçecek (domestic)
   PRIBAS_SUN_SPDE: 124, // Sun Premium Tavuk & 2 İçecek (domestic)
   PRIBAS_SUN_SPVL: 125, // Meze Tabağı (vejetaryen), 1 alkolsüz içecek
   PRIBAS_SUN_SPVG: 126, // Falafel (vegan), 1 alkolsüz içecek
   PRIBAS_SUN_VGML: 127, // Falafel (vegan)
   PRIBAS_SUN_VLML: 128, // Meze Tabağı (vejetaryen)
   PRIBAS_SUN_SCSM: 129, // SunClassic Snack Box
   PRIBAS_SUN_CHML: 130, // Çocuk Menüsü
};

export const EFltSsrWheelchairType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "WCHC", // Wheelchair (Pax completely immobile and cannot climb stairs)
   2: "WCHR", // Wheelchair for the Ramp. (Can climb stairs.)
   3: "WCHS", // Wheelchair (Can't climb stairs.)
   4: "WCBD", // Dry Cell battery operated wheelchair
   5: "WCMP", // Wheelchair Manuel (Power Driven)
   6: "WCLB", // Passenger who has own Lithium Battery powered wheelchair
};
export const EFltSsrWheelchairTypeReverse = {
   ALL: -1,
   UNKNOWN: 0,
   WCHC: 1,
   WCHR: 2,
   WCHS: 3,
   WCBD: 4,
   WCMP: 5,
   WCLB: 6,
};
export const EFltConnectionType = {
   "-1": "airlineTransfer",
   0: "noneStop",
};
export const EFltAncillaryType = {
   1: "baggage",
   2: "seat",
   3: "meal",
   4: "wheelchair",
   5: "other",
   6: "airport-checkin",
   7: "reissue-allowance",
   8: "cabin-bag",
};

export const EFltSeatTypeEnum = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "AISLE",
   2: "WINDOW",
   3: "STANDARD",
   4: "EXIT",
   5: "PRIORITY",
   6: "EXTRA_LEGROOM",
   7: "WING",
};

export const EFltSeatLayout = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "SL_1_1",
   2: "SL_1_2",
   3: "SL_2_2",
   4: "SL_3_3",
   5: "SL_1_1_1",
   6: "SL_1_2_1",
   7: "SL_2_2_2",
   8: "SL_2_3_2",
   9: "SL_2_4_2",
   10: "SL_3_3_3",
   11: "SL_3_4_3",
};
export const EFltWarnType = {
   "-1": "PRICE_UNAVAILABLE",
   0: "UNKNOWN",
   1: "INFO",
   2: "FLIGHT_TIME_CHANGED",
   3: "SEGMENT_CLASS_CHANGED",
   4: "SEGMENT_CABIN_CHANGED",
   5: "LEG_COUNT_CHANGED",
   6: "PRICE_UNAVAILABLE",
};
