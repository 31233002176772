<template>
   <div v-if="isCheckout" class="modal-backdrop">
      <div id="close-modal" @click="$emit('close')"></div>
      <div class="modal-content">
         <div v-for="(room, roomIndex) in paxCount" :key="roomIndex">
            <div class="room-informations">
               <div class="room-card-wrapper">
                  <div class="room-card-inner">
                     <span class="room-card-badge"></span>
                     <div class="room-card-section room-informations">
                        <div class="room-information-item">
                           <span>{{ $t("hotelCheckout_lblRoomName") }}</span>
                           <span class="room-name">{{ roomInfo[roomIndex].roomType }}</span>
                        </div>
                        <div class="room-information-item">
                           <span>{{ $t("hotelCheckout_lblBoardType") }}</span>
                           <span class="room-board-type">{{ roomInfo[roomIndex].boardName }}</span>
                        </div>
                     </div>
                     <div class="room-card-section room-date">
                        <div class="room-date-content">
                           <div class="room-date-item checkin-date">
                              <div class="room-date-icon">
                                 <i class="gts-icon icon-calendar-checkin"></i>
                              </div>
                              <div class="room-date-content">
                                 <span>{{ $t("hotelCheckout_lblCheckin") }}</span>
                                 <span class="date">{{ roomInfo[roomIndex].checkinDate }}</span>
                              </div>
                           </div>
                           <div class="room-date-item checkout-date">
                              <div class="room-date-icon">
                                 <i class="gts-icon icon-calendar-checkout"></i>
                              </div>
                              <div class="room-date-content">
                                 <span>{{ $t("hotelCheckout_lblCheckout") }}</span>
                                 <span class="date">{{ roomInfo[roomIndex].checkoutDate }}</span>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="room-card-section room-price">
                        <div class="room-price-info">
                           <span>{{ $t("hotelCheckout_lblRoomTotal") }}</span>
                           <span class="price">{{ roomInfo[roomIndex].price | price(roomInfo[roomIndex].currency) }}</span>
                        </div>
                     </div>
                  </div>
                  <div class="room-card-footer">
                     <div class="room-card-footer-inner">
                        <i class="gts-icon icon-information"></i>
                        <span>{{ roomInfoConvert(room.adults) }}</span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-wrapper passenger-informations">
               <div class="header">
                  <span>{{ $t("hotelCacheCheckout_lblPassengerInformations") }}</span>
               </div>
               <div class="passenger-information">
                  <div class="passenger">
                     <span class="room">{{ roomIndex + 1 + "." + $t("common_room") }}</span>
                     <div v-for="(guest, guestIndex) in guests[roomIndex].adults" :key="`adult-${guestIndex}`" class="pas-container">
                        <UISelectBox :options="genders" v-model="guest.gender" :selectedOpt="guestIndex % 2 ? 1 : 2" light-theme />
                        <UIInput id="input_guestName" ac-tag="name" v-model="guest.name" :placeholder="$t('common_name')" type="text" />
                        <UIInput id="input_guestSurname" ac-tag="surname" v-model="guest.surname" :placeholder="$t('common_surname')" type="text" />
                     </div>
                     <div v-for="(child, childIndex) in guests[roomIndex].children" :key="`child-${childIndex}`" class="pas-container">
                        <UISelectBox :options="genders" v-model="child.gender" :selectedOpt="childIndex % 2 ? 1 : 2" light-theme />
                        <UIInput id="input_guestName" ac-tag="name" v-model="child.name" :placeholder="$t('common_name')" type="text" />
                        <UIInput id="input_guestSurname" ac-tag="surname" v-model="child.surname" :placeholder="$t('common_surname')" type="text" />
                        <UIDatePicker v-model="child.birthdate" :min="calculateMinDate(child.birthdate)" :max="calculateMaxDate(child.birthdate)" />
                     </div>
                  </div>
               </div>
            </div>
            <div class="addon-content-wrapper">
               <div class="addon-wrapper" v-for="(addon, index) in roomInfo[roomIndex].addons" :key="index">
                  <div class="image-section">
                     <img :src="addon.images[0]" />
                  </div>
                  <div class="content-section">
                     <div class="addon-header">
                        <span>{{ addon.title }}</span>
                     </div>
                     <div class="addon-description">
                        <span class="description">{{ addon.description }}</span>
                     </div>
                  </div>
                  <div class="addon-price">
                     <span>{{ AddOnDate(addon.date) }}</span>
                     <span> {{ addon.amount | price(addon.currency) }}</span>
                     <div v-if="addon.childPolicy.length != 0">
                        <div class="info-section" v-for="(restr, childIndex) in addon.childPolicy" :key="childIndex">
                           <i class="gts-icon icon-info-circle-orange icon-sm"></i>
                           <span>{{ restrictionText(restr) }}</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="container-wrapper cancellation-policy-wrapper" v-if="cancellationPolicies.length != 0">
            <div class="policy-side">
               <i class="gts-icon icon-inline-alert"></i>
            </div>
            <div class="policy-content">
               <div class="content-header">
                  <span>{{ $t("hotelCacheCheckout_lblCancellationPolicy") }}</span>
               </div>
               <div class="content" v-for="(item, index) in cancellationPolicies" :key="index">
                  <ul>
                     <li>Policy Type : {{ $options.filters.EHtlAmountType(item.type) }}</li>
                     <li>FromDate : {{ item.fromDate | FormatterDateTime }} || ToDate : {{ item.toDate | FormatterDateTime }}</li>
                     <li>Value : {{ item.value }} || Currency : {{ item.currency }}</li>
                  </ul>
               </div>
            </div>
         </div>
         <div>
            <CommonCheckoutContact :isShowMail="true" :isBorderavailable="true" @update-to-contact="setCheckoutContact" />
         </div>
         <div class="price-container">
            <div class="price">
               <span>{{ $t("common_totalPrice") }} : </span>
               <span>{{ totalPrice | price(roomInfo[0]?.currency) }}</span>
            </div>
         </div>
         <div class="payment-wrapper">
            <span>{{ $t("hotelCacheOrderDetail_lblPaymentInformations") }}</span>
            <div class="payment-types">
               <img
                  :class="paymentMethod == 1 ? 'active-payment-method' : ''"
                  :src="require(`@/assets/images/cari_${$i18n.locale}.svg`)"
                  @click="setPaymentMethod(1)" />
               <img
                  :class="paymentMethod == 2 ? 'active-payment-method' : ''"
                  :src="require(`@/assets/images/creditcard_${$i18n.locale}.svg`)"
                  @click="setPaymentMethod(2)" />
            </div>
            <div class="payment-price">
               <span> {{ $t("PaymentChoiceItem_lblGrandTotal") }} : {{ totalPrice | price(roomInfo[0]?.currency) }}</span>
            </div>
            <div>
               <PaymentCreditcard v-if="paymentMethod == 2" />
            </div>
            <div class="payment-btns">
               <UIButton className="default" :text="$t('common_purchase')" @click.native="book" />
            </div>
         </div>
         <div v-if="orderDetailModal" class="inner-modal-backdrop">
            <div class="inner-modal-content">
               <h3>{{ $t("hotelCacheOrderDetail_lblSummary") }}</h3>
               <div class="details">
                  <div>
                     <div>
                        <span class="info-lbl">{{ $t("common_orderId") }} : </span>
                        <span class="room-name">{{ orderDetailData.orderId }}</span>
                     </div>
                     <div>
                        <span class="info-lbl">{{ $t("common_voucher") }} : </span>
                        <span class="room-name">{{ orderDetailData.voucherId }}</span>
                     </div>
                  </div>
                  <div class="order-info">
                     <i class="gts-icon icon-information"></i> <span>{{ $t("hotelCacheOrderDetail_lblInfoDontForgetSave") }}</span>
                  </div>
               </div>
               <div class="order-detail-content">
                  <div class="head">
                     <div class="room-informations" v-for="(room, roomIndex) in paxCount" :key="roomIndex">
                        <div class="room-card-wrapper">
                           <div class="room-card-inner">
                              <span class="room-card-badge"></span>
                              <div class="room-card-section room-informations">
                                 <div class="room-information-item">
                                    <span>{{ $t("hotelCheckout_lblRoomName") }}</span>
                                    <span class="room-name">{{ roomInfo[roomIndex].roomType }}</span>
                                 </div>
                                 <div class="room-information-item">
                                    <span>{{ $t("hotelCheckout_lblBoardType") }}</span>
                                    <span class="room-board-type">{{ roomInfo[roomIndex].boardName }}</span>
                                 </div>
                              </div>
                              <div class="room-card-section room-date">
                                 <div class="room-date-content">
                                    <div class="room-date-item checkin-date">
                                       <div class="room-date-icon">
                                          <i class="gts-icon icon-calendar-checkin"></i>
                                       </div>
                                       <div class="room-date-content">
                                          <span>{{ $t("hotelCheckout_lblCheckin") }}</span>
                                          <span class="date">{{ roomInfo[roomIndex].checkinDate }}</span>
                                       </div>
                                    </div>
                                    <div class="room-date-item checkout-date">
                                       <div class="room-date-icon">
                                          <i class="gts-icon icon-calendar-checkout"></i>
                                       </div>
                                       <div class="room-date-content">
                                          <span>{{ $t("hotelCheckout_lblCheckout") }}</span>
                                          <span class="date">{{ roomInfo[roomIndex].checkoutDate }}</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="room-card-section room-price">
                                 <div class="room-price-info">
                                    <span>{{ $t("hotelCheckout_lblRoomTotal") }}</span>
                                    <span class="price">{{ roomInfo[roomIndex].price | price(roomInfo[roomIndex].currency) }}</span>
                                 </div>
                              </div>
                           </div>
                           <div class="detail-wrapper">
                              <div class="passengerCount">
                                 <i class="gts-icon icon-information"></i>
                                 <span>{{ roomInfoConvert(room.adults) }}</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="modalBtn">
                  <UIButton className="default" :text="$t('common_close')" @click.native="closeOrderDetail"></UIButton>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UIDatePicker from "@/components/unitary/form2/UIDatePicker2.vue";
import CommonCheckoutContact from "@/components/unitary/CommonCheckoutContact.vue";
import moment from "moment";
import { hotelService } from "@/service";
import Popup from "@/gts-popup.js";
import PaymentCreditcard from "@/components/structural/payment/PaymentCreditcard.vue";

export default {
   props: {
      cancellationPolicies: Array,
      totalPrice: String,
      roomInfo: Array,
      isCheckout: {
         type: Boolean,
         required: true,
      },
      paxCount: Array,
      availabilityUuid: String,
      bookingUuid: String,
   },
   computed: {
      paxrow() {
         return this.paxCount;
      },
   },
   data() {
      return {
         orderDetailData: {
            voucherId: "",
            orderId: "",
         },
         orderDetailModal: false,
         guests: this.initializeGuests(),
         checkoutRequestModel: {
            availabilityUuid: "",
            bookingUuid: "",
            rooms: [],
            contact: {
               email: "",
               phone: {
                  iso: "DE",
                  country: "+49",
                  city: "",
                  number: "",
               },
            },
            creditCard: null,
         },
         paymentMethod: 1,
         testDate: "",
         genders: this.$GtsDropdownData.EComGender(),
      };
   },
   components: {
      PaymentCreditcard,
      UIButton,
      UISelectBox,
      UIInput,
      UIDatePicker,
      CommonCheckoutContact,
   },
   methods: {
      restrictionText(obj) {
         let ageRange = obj.fromAge + "-" + obj.toAge;
         if (obj.notAllowed) {
            let resText = this.$t("hotelcheckout_addOnCardWarning").replace("#VALUE#", ageRange);
            return resText;
         } else {
            return "";
         }
      },
      AddOnDate(val) {
         return moment(val).format("DD.MM.YYYY");
      },
      closeOrderDetail() {
         this.orderDetailModal = false;
         window.location.reload();
      },

      roomInfoConvert(val) {
         let temp = "Bu oda #VALUE# kişi konaklaması için uygundur.";
         return temp.replace("#VALUE#", val);
      },
      book() {
         this.orderDetailModal = false;
         this.$store.commit("app/setIsProgressGifActive", true);
         this.checkoutRequestModel.rooms = [];

         this.paxCount.forEach((room, roomIndex) => {
            const roomObj = {
               roomIdentifier: this.roomInfo[roomIndex].identifier,
               guests: [],
            };

            this.guests[roomIndex].adults.forEach((adult) => {
               roomObj.guests.push({
                  type: adult.type,
                  gender: adult.gender,
                  name: adult.name.toUpperCase(),
                  surname: adult.surname.toUpperCase(),
                  birthdate: adult.birthdate,
               });
            });

            this.guests[roomIndex].children.forEach((child) => {
               roomObj.guests.push({
                  type: child.type,
                  gender: child.gender,
                  name: child.name.toUpperCase(),
                  surname: child.surname.toUpperCase(),
                  birthdate: child.birthdate,
               });
            });

            this.checkoutRequestModel.rooms.push(roomObj);
         });

         this.checkoutRequestModel.availabilityUuid = this.availabilityUuid;
         this.checkoutRequestModel.bookingUuid = this.bookingUuid;

         const hasEmptyFields =
            this.checkoutRequestModel.rooms.some((room) => {
               return room.guests.some((guest) => guest.name === "" || guest.surname === "");
            }) ||
            this.checkoutRequestModel.contact.phone.city === "" ||
            this.checkoutRequestModel.contact.email === "" ||
            !this.checkoutRequestModel.contact.email.includes("@") ||
            this.checkoutRequestModel.contact.phone.number === "";

         if (hasEmptyFields) {
            Popup.warning(
               this.$i18n.t("HotelCacheCheckoutBook_WarningEmptyAreaHead"),
               this.$i18n.t("HotelCacheCheckoutBook_WarningEmptyAreaContent")
            );
            return;
         }

         if (this.paymentMethod == 1) {
            const reqModel = this.checkoutRequestModel;
            (reqModel.creditCard = null),
               hotelService.hotelCacheBook(reqModel).then((res) => {
                  if (!res.success) {
                     this.$store.commit("app/setIsProgressGifActive", false);
                     Popup.warning(this.$i18n.t("HotelCacheCheckoutBook_WarningPopup"), this.$i18n.t("HotelCacheCheckoutBook_WarningPopupInfo"));
                  } else {
                     const orderDetailData = {
                        orderId: res.orderId,
                        voucherId: res.voucherId,
                     };
                     this.orderDetailData = orderDetailData;

                     this.$store.commit("app/setIsProgressGifActive", false);
                     this.orderDetailModal = true;
                  }
               });
         } else if (this.paymentMethod == 2) {
            if (
               this.$store.state.finance?.creditCard?.pan.length != 16 ||
               this.$store.state.finance.creditCard.cvv == "" ||
               this.$store.state.finance.creditCard.holder == "" ||
               this.$store.state.finance.creditCard.year == "" ||
               this.$store.state.finance.creditCard.month == ""
            ) {
               this.$store.commit("app/setIsProgressGifActive", false);
               Popup.warning(
                  this.$i18n.t("HotelCacheCheckoutBook_WarningPopupCreditCard"),
                  this.$i18n.t("HotelCacheCheckoutBook_WarningPopupInfoCreditCard")
               );
               return;
            }
            const reqModel = this.checkoutRequestModel;
            (reqModel.creditCard = this.$store.state.finance.creditCard),
               hotelService.hotelCacheBook(reqModel).then((res) => {
                  if (!res.success) {
                     this.$store.commit("app/setIsProgressGifActive", false);
                     Popup.warning(this.$i18n.t("HotelCacheCheckoutBook_WarningPopup"), this.$i18n.t("HotelCacheCheckoutBook_WarningPopupInfo"));
                  } else {
                     const orderDetailData = {
                        orderId: res.orderId,
                        voucherId: res.voucherId,
                     };
                     this.orderDetailData = orderDetailData;
                     this.$store.commit("app/setIsProgressGifActive", false);
                     this.orderDetailModal = true;
                  }
               });
         }
      },
      calculateMinDate(birthdate) {
         const birthDate = moment(birthdate);
         return birthDate.format("YYYY-MM-DD");
      },
      calculateMaxDate(birthdate) {
         const birthDate = moment(birthdate);
         return birthDate.add(1, "years").endOf("year").format("YYYY-MM-DD");
      },
      setDefaultBirthdate(childAge) {
         const today = moment();
         const birthDate = today.clone().subtract(childAge, "years");
         return birthDate.format("YYYY-MM-DD");
      },
      initializeGuests() {
         const guests = [];
         if (Array.isArray(this.paxCount) && this.paxCount.length) {
            this.paxCount.forEach((room, roomIndex) => {
               const roomGuests = {
                  roomIndex: roomIndex,
                  adults: [],
                  children: [],
               };
               for (let i = 0; i < room.adults; i++) {
                  roomGuests.adults.push({
                     type: 1,
                     gender: 1,
                     name: "",
                     surname: "",
                     birthdate: "1999-01-01",
                  });
               }
               for (let j = 0; j < room.children; j++) {
                  roomGuests.children.push({
                     type: 2,
                     gender: 1,
                     name: "",
                     surname: "",
                     birthdate: this.setDefaultBirthdate(room.childrenAges[j]),
                  });
               }
               guests.push(roomGuests);
            });
         }
         return guests;
      },
      setCheckoutContact(val) {
         this.checkoutRequestModel.contact.email = val.email;
         this.checkoutRequestModel.contact.phone = val.phone;
      },
      setPaymentMethod(val) {
         this.paymentMethod = val;
      },
      closeModal() {
         this.$emit("close");
      },
   },
};
</script>

<style lang="scss" scoped>
#close-modal {
   // Ana modal kapatma butonu
   position: fixed !important;
   right: 16%;
   top: 5%;
   width: 42px;
   height: 42px;
   background-color: #eaeaea;
   border-radius: 50%;
   cursor: pointer;
   z-index: 998;
   &:hover {
      transform: scale(1.125);
      background: #ccc;
   }
   &::before,
   &::after {
      content: "";
      width: 22px;
      height: 2px;
      background: #363636;
      display: block;
      border-radius: 1rem;
      position: absolute;
      inset: 0;
      margin: auto;
   }
   &::before {
      transform: rotate(45deg);
   }
   &::after {
      transform: rotate(-45deg);
   }
}

.modal-backdrop {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 999;
   .modal-content {
      background: white;
      padding: 2rem;
      border-radius: 8px;
      max-width: 70%;
      width: 100%;
      height: 95%;
      overflow: auto;

      .header {
         padding: 1rem;
         border-bottom: 1px solid rgb(182, 182, 182);
         span {
            font-weight: 500;
            font-size: 1.1rem;
         }
      }

      .container-wrapper {
         width: 100%;
         border-radius: 8px;
         margin-top: 1.5rem;
         font-size: 0.9rem;
         border: 1px solid #cccccc;
      }
      .addon-content-wrapper {
         display: flex;
         flex-direction: column;
         .addon-wrapper {
            padding: 1rem;
            display: flex;
            border: 1px solid #cccccc;
            border-radius: 8px;
            margin-top: 1.5rem;
            width: 100%;
            @media (max-width: 576px) {
               flex-direction: column;
               .image-section {
                  img {
                     width: 200px;
                     border-radius: 0.5rem;
                  }
               }
            }
            .image-section {
               border-right: 1px dashed #cccccc;
               padding-right: 0.5rem;
               img {
                  max-width: 250px;
                  border-radius: 0.5rem;
               }
            }
            .content-section {
               font-size: 1rem;
               padding: 0 1rem;
               width: 60%;
               .addon-header {
                  display: flex;
                  span {
                     font-weight: 500;
                  }
               }
               .addon-description {
                  display: flex;
                  flex-direction: column;
                  .description {
                     margin-top: 0.5rem;
                  }
               }
            }
            .addon-price {
               display: flex;
               flex-direction: column;
               margin-top: 0.5rem;
               font-weight: 600;
               font-size: 1.5rem;
               text-align: center;
               color: #ff00ff;
               justify-content: center;
               .info-section {
                  display: flex;
                  align-items: center;
                  font-size: 0.75rem;
                  color: #ff6a00;
                  border: 1px solid #ff990086;
                  margin-top: auto;
                  border-radius: 0.75rem;
                  background: #ffe6000c;
                  margin-top: 0.25rem;
                  width: fit-content;
                  padding-right: 0.25rem;

                  &:not(:first-child) {
                     margin-top: 0.25rem;
                  }
                  i {
                     margin: 0.25rem;
                  }
               }
            }
         }
      }
      .passenger-informations {
         .passenger-information {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            .passenger {
               display: flex;
               flex-direction: column;
               width: 100%;
               .room {
                  font-weight: 600;
                  font-size: 1rem;
               }
               .pas-container {
                  border-radius: 8px;
                  padding: 1rem;
                  display: grid;
                  justify-content: center;
                  align-items: center;
                  grid-template-columns: 1fr 1.5fr 1.5fr 1.5fr;
                  gap: 1rem;
                  &:not(:first-child) {
                     border-top: 1px solid #cccccc;
                     margin-top: 1rem;
                  }
               }
            }
         }
      }

      .room-informations {
         &:not(:first-child) {
            margin-top: 1rem;
         }

         .room-card-wrapper {
            border: 1px solid #cccccc;
            border-radius: 8px;
            overflow: hidden;

            .room-card-inner {
               width: 100%;
               display: grid;
               grid-template-columns: 1fr 1.7fr 1fr;
               width: 100%;
               padding: 16px 32px;
               border-bottom: 1px solid #cccccc;
               position: relative;

               .room-card-badge {
                  position: absolute;
                  left: 0;
                  top: 1rem;
                  bottom: 1rem;
                  margin: auto;
                  width: 5px;
                  background: #e0e7f0;
                  border-radius: 0.5rem;
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
               }

               .room-card-section {
                  &.room-informations {
                     display: flex;
                     flex-direction: column;
                     justify-content: center;

                     .room-information-item {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 16px;

                        &:last-child {
                           margin-bottom: 0;
                        }

                        .room-name,
                        .room-board-type {
                           font-size: 16px;
                           font-weight: 700;
                           color: #363940;
                        }
                     }
                  }
                  &.room-date {
                     display: flex;
                     height: 100%;
                     padding: 0 2rem;
                     border-right: 1px solid #cccccc;
                     border-left: 1px solid #cccccc;

                     .room-date-item {
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;

                        &:last-child {
                           margin-bottom: 0;
                        }
                        .room-date-icon {
                           margin-right: 16px;
                        }
                        .room-date-content {
                           display: flex;
                           flex-direction: column;
                           width: 100%;
                           .date {
                              font-size: 16px;
                              font-weight: 700;
                              color: #363940;
                           }
                        }
                     }
                  }
                  &.room-price {
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     .room-price-info {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        width: max-content;
                        .price {
                           font-size: 20px;
                           font-weight: 700;
                           color: #363940;
                        }
                     }
                  }
               }
            }

            @media (max-width: 767.98px) {
               .room-card-inner {
                  padding: 16px 16px;
                  display: block;
               }
            }

            .room-card-footer {
               background-color: #f8f8f8;
               .room-card-footer-inner {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  padding: 8px 32px;
                  i {
                     color: #33b8fb;
                     margin-right: 12px;
                  }
                  span {
                     margin: 0;
                     font-size: var(--small);
                     font-style: italic;
                  }
               }
            }
         }

         .information-body {
            border-top: 1px solid rgb(182, 182, 182);
            display: flex;
            justify-content: space-between;
            padding: 1rem;
            .info {
               display: flex;
               flex-direction: column;
               gap: 1rem;
               justify-content: center;
            }
            font-weight: 500;
         }
      }

      .price-container {
         display: flex;
         justify-content: flex-end;
         margin-top: 1.5rem;
         .price {
            display: flex;
            border: 1px solid rgb(182, 182, 182);
            padding: 1rem;
            border-radius: 4px;
            font-weight: 500;
            gap: 4px;
         }
      }

      .payment-wrapper {
         padding: 1rem;
         font-size: 1.1rem;
         .payment-btns {
            margin-top: 1rem;
         }
         span {
            font-weight: 500;
         }

         .payment-types {
            img {
               margin-left: 0.5rem;
               &.active-payment-method {
                  border: 8px solid #74fbe5;
                  border-radius: 1.5rem;
               }
            }
         }

         .payment-price {
            margin-top: 1rem;
            border: #7dd7aa 1px solid;
            padding: 1rem;
            background: #e9fcf2;
            border-radius: 8px;
            width: 60%;
            min-width: 50%;
         }
      }

      .modal-actions {
         display: flex;
         justify-content: center;
         margin-top: 1rem;
      }

      .cancellation-policy-wrapper {
         margin-top: 1.5rem;
         display: flex;
         padding: 1rem;

         .policy-side {
            i {
               width: 80px !important;
               height: 80px !important;
            }
         }
         .policy-content {
            width: 100%;
            .content {
               display: flex;
               flex-direction: column;
               padding: 0.5rem;
               font-weight: 500;
            }
            .content-header {
               border-bottom: 1px solid #cccc;
               span {
                  font-weight: 500;
                  font-size: 1rem;
               }
            }
         }
      }
   }
}

// İç modal için SCSS stilleri
.inner-modal-backdrop {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.7);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1001;
}

.inner-modal-content {
   display: flex;
   flex-direction: column;
   background: white;
   padding: 2rem;
   border-radius: 8px;
   width: 50%;
   box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
   position: relative;
   .order-detail-content {
      .head {
         .room-informations {
            &:not(:first-child) {
               margin-bottom: 1rem;
            }

            .room-card-wrapper {
               border: 1px solid #cccccc;
               border-radius: 8px;
               overflow: hidden;
               .detail-wrapper {
                  display: flex;
                  justify-content: space-between;
                  .passengerCount {
                     display: flex;
                     padding: 1rem;
                     font-weight: 500;
                     align-items: center;
                     span {
                        margin-top: 0.25rem;
                     }
                  }
               }
               .room-card-inner {
                  width: 100%;
                  display: grid;
                  grid-template-columns: 1fr 1.7fr 1fr;
                  width: 100%;
                  padding: 16px 32px;
                  border-bottom: 1px solid #cccccc;
                  position: relative;

                  .room-card-badge {
                     position: absolute;
                     left: 0;
                     top: 1rem;
                     bottom: 1rem;
                     margin: auto;
                     width: 5px;
                     background: #e0e7f0;
                     border-radius: 0.5rem;
                     border-top-left-radius: 0;
                     border-bottom-left-radius: 0;
                  }

                  .room-card-section {
                     &.room-informations {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .room-information-item {
                           display: flex;
                           flex-direction: column;
                           margin-bottom: 16px;

                           &:last-child {
                              margin-bottom: 0;
                           }

                           .room-name,
                           .room-board-type {
                              font-size: 16px;
                              font-weight: 700;
                              color: #363940;
                           }
                        }
                     }
                     &.room-date {
                        display: flex;
                        height: 100%;
                        padding: 0 2rem;
                        border-right: 1px solid #cccccc;
                        border-left: 1px solid #cccccc;
                        margin-top: 0.5rem;

                        .room-date-item {
                           display: flex;
                           align-items: center;
                           margin-bottom: 16px;

                           &:last-child {
                              margin-bottom: 0;
                           }
                           .room-date-icon {
                              margin-right: 16px;
                           }
                           .room-date-content {
                              display: flex;
                              flex-direction: column;
                              width: 100%;
                              .date {
                                 font-size: 16px;
                                 font-weight: 700;
                                 color: #363940;
                              }
                           }
                        }
                     }
                     &.room-price {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .room-price-info {
                           display: flex;
                           flex-direction: column;
                           justify-content: flex-start;
                           width: max-content;
                           .price {
                              font-size: 20px;
                              font-weight: 700;
                              color: #363940;
                           }
                        }
                     }
                  }
               }

               @media (max-width: 767.98px) {
                  .room-card-inner {
                     padding: 16px 16px;
                     display: block;
                  }
               }

               .room-card-footer {
                  background-color: #f8f8f8;
                  .room-card-footer-inner {
                     display: flex;
                     align-items: center;
                     justify-content: flex-start;
                     padding: 8px 32px;
                     i {
                        color: #33b8fb;
                        margin-right: 12px;
                     }
                     span {
                        margin: 0;
                        font-size: var(--small);
                        font-style: italic;
                     }
                  }
               }
            }

            .information-body {
               border-top: 1px solid rgb(182, 182, 182);
               display: flex;
               justify-content: space-between;
               padding: 1rem;
               .info {
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
                  justify-content: center;
               }
               font-weight: 500;
            }
         }
      }
   }

   h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      font-weight: 500;
      text-align: center;
   }
   .details {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 1.1rem;
      .info-lbl {
         font-weight: 400;
      }
      .order-info {
         display: flex;
         margin-right: 2rem;
         align-items: center;
         font-weight: 400;
         i {
            margin-right: 0.25rem;
            font-size: 14px;
         }
      }
   }
   .modalBtn {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
   }
}
</style>
