<template>
   <div id="passenger-information-wrapper">
      <span class="section-title">{{ $t("orderDetailPage_passengerInformation") }}</span>

      <div class="passenger-information-row-header is-hidden-mobile">
         <!-- PASSENGER NAME SURNAME -->
         <div class="passenger-information-column">
            <div class="passenger-information-row-inner">
               <span class="text-bold">{{ $t("orderDetailPage_nameSurname") }}</span>
            </div>
         </div>
         <!-- PASSENGER INFORMATIONS BASIC -->
         <div
            class="passenger-information-column"
            :class="$options.filters.EOdrType(packages.type) == $options.filters.EOdrType(5) ? 'package-grid' : ''">
            <div class="passenger-information-row-inner">
               <span class="text-bold">{{ $t("orderDetailPage_status") }}</span>
            </div>
            <div class="passenger-information-row-inner" v-if="$options.filters.EOdrType(packages.type) == $options.filters.EOdrType(5)">
               <span class="text-bold"> {{ $t("orderDetailPage_lblBirthDate") }}</span>
            </div>
            <div class="passenger-information-row-inner">
               <span class="text-bold"> {{ $t("orderDetailPage_date") }}</span>
            </div>
            <div class="passenger-information-row-inner" v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
               <span class="text-bold"> {{ $t("orderDetailPage_eticket") }}</span>
            </div>

            <div class="passenger-information-row-inner">
               <span class="text-bold"> {{ $t("orderDetailPage_baggage") }}</span>
            </div>
            <div class="passenger-information-row-inner" v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
               <span class="text-bold text-right"> {{ $t("orderDetailPage_fare") }}</span>
            </div>
            <div class="passenger-information-row-inner" v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
               <span class="text-bold text-right"> {{ $t("orderDetailPage_tax") }}</span>
            </div>
            <div class="passenger-information-row-inner" v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
               <span class="text-bold text-right"> {{ $t("orderDetailPage_serviceFee") }}</span>
            </div>
            <div class="passenger-information-row-inner" v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
               <span class="text-bold text-right"> {{ $t("orderDetailPage_grandTotal") }}</span>
            </div>
         </div>
         <!-- PASSENGER ACTION  -->
         <div class="passenger-information-column"></div>
      </div>
      <PassengerCard
         :currency="grandTotal.currency"
         v-for="(passenger, index) in data"
         :key="index"
         :passenger="passenger"
         :card-index="index"
         :packages="packages" />

      <PriceWidget
         :totalPriceInfo="grandTotal"
         :checkoutMode="grandTotal.pnr.status == 2 ? true : false"
         label="flightOrderDetail"
         :packages="packages" />
   </div>
</template>

<script>
import PriceWidget from "@/components/unitary/PriceWidget.vue";
import PassengerCard from "./PassengerCard.vue";

export default {
   name: "PassengerInformations",
   components: {
      PassengerCard,
      PriceWidget,
   },
   props: {
      packages: Object,
      data: Array,
      grandTotal: Object,
   },
   mounted() {
      if (this.$options.filters.EOdrType(this.packages?.type) !== this.$options.filters.EOdrType(5)) {
         this.grandTotal.grandTotal = this.packages?.total;
         this.grandTotal.currency = this.packages?.currency;
      }
   },
};
</script>

<style lang="scss" scoped>
#passenger-information-wrapper {
   ::v-deep {
      .passenger-information-row,
      .passenger-information-row-header {
         display: flex;
         flex-direction: row;
         width: 100%;
         padding: 1rem 0.5rem;
         .passenger-information-column {
            width: 100%;
            display: grid;
            // grid-template-columns: 1.2fr repeat(2, 1.4fr) 2fr repeat(2, 0.75fr) 1.3fr 1fr;

            grid-template-columns: 0.75fr 0.75fr 1fr 1fr repeat(4, 0.75fr);
            grid-column-gap: 1rem;
            padding: 1rem;
            padding-left: 0;
            padding-right: 0;

            &:first-child {
               width: 22% !important;
               display: flex;
               padding: 0 !important;
            }
            &:last-child {
               width: 70px !important;
               display: flex;
               padding: 0 !important;
               flex-shrink: 0;
            }

            .passenger-information-row-inner {
               text-align: left;
               // background-color: #eee;
               // border: 1px solid #ccc;
               display: flex;
               align-content: center;
               justify-content: center;
               width: 100%;

               span {
                  width: 100%;
                  display: flex;
                  font-weight: 400;
                  text-align: left;
                  margin-top: auto;
                  margin-bottom: auto;
                  &.text-center {
                     text-align: center;
                     justify-content: center;
                  }
                  &.text-right {
                     text-align: right;
                     justify-content: flex-end;
                  }
                  &.text-bold {
                     font-weight: 500;
                  }
                  &.text-black {
                     font-weight: 600;
                  }
               }
            }

            &.action {
               display: flex;
               align-items: center;
               justify-content: center;
            }
            &.package-grid {
               grid-template-columns: 5.2fr 5.5fr 5fr repeat(2, 2fr);
            }
         }
         .passenger-information-column-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .passenger-information-column {
               width: 100% !important;
               padding: 0 !important;
               margin: 0.25rem 0;
               &:first-child,
               &:last-child {
                  display: grid;
               }

               &:hover {
                  background-color: #fff;
               }
            }
            .reissue-column {
               border-bottom: 1px dashed #ccc;
            }
         }
      }

      .passenger-information-row-header {
         padding: 0 0.5rem;
         padding-bottom: 0.75rem;
         .passenger-information-column {
            padding: 0rem;
         }
      }
   }
}
</style>
