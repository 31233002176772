<template>
   <div id="checkout-notes-container" class="gts-box with-border">
      <div class="checkout-note-container">
         <div class="checkout-section-title">
            <span class="section-title">{{ $t("transferCheckout_lblTransferDescriptionsTitle") }} </span>
            <ul class="important-notes-list">
               <li>{{ $t("TransferCheckout_lblImportantNote1") }}</li>
               <li>{{ $t("TransferCheckout_lblImportantNote2") }}</li>
               <li v-if="providerContact.implementationType == 202">{{ lblTransferPhoneNumberPaxDrive }}</li>
               <li v-else>{{ lblTransferPhoneNumber }}</li>
               <li>{{ $t("TransferCheckout_lblImportantNote4") }}</li>
            </ul>
         </div>
         <UIAlert status="info_orange_2">
            <div class="description-alert-icon">
               <i class="gts-icons icon-inline-alert"></i>
            </div>
            <div class="description-alert-content">
               <p>{{ LastVoidNote }}</p>
            </div>
         </UIAlert>
      </div>
      <div class="agency-note-container">
         <UITextarea :labelText="$t('common_agencyNote')" id="textarea_checkoutAgencyNote" v-model="agencyNote" />
      </div>
   </div>
</template>

<script>
import UITextarea from "@/components/unitary/form/Textarea.vue";
import UIAlert from "@/components/unitary/Alert.vue";

import moment from "moment";

export default {
   name: "CheckoutNotes",
   components: {
      UITextarea,
      UIAlert,
   },
   props: {
      providerContact: Object,
      lastVoidDateTime: Date,
   },
   data() {
      return {
         agencyNote: "",
      };
   },
   computed: {
      lblTransferPhoneNumberPaxDrive() {
         return this.$i18n
            .t("TransferCheckout_lblImportantNote5")
            .replace("#VALUE#", this.providerContact?.providerPhone)
            .replace("#VALUE1#", this.providerContact?.providerPhone2);
      },
      lblTransferPhoneNumber() {
         return this.$i18n.t("TransferCheckout_lblImportantNote3").replace("#VALUE#", this.providerContact?.providerPhone);
      },
      LastVoidNote() {
         var date = moment(this.lastVoidDateTime);
         return (
            this.$i18n.t("TransferCheckout_lblLastVoidDate") +
            " " +
            date.format("DD ") +
            this.$i18n.t("common_" + date.format("MMMM").toLowerCase()) +
            date.format(" YYYY - HH:mm")
         );
      },
   },
   watch: {
      agencyNote: {
         handler: function (newValue) {
            this.$emit("update-to-agency-note", newValue);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.checkout-section-title {
   margin-bottom: 1.5rem;
   & > h4 {
      margin-bottom: 10px;
   }
   & > p {
      font-size: 14px;
      color: #1e2023;
   }
}
.important-notes-list {
   font-size: 14px;
   color: #1e2023;
   list-style: disc;
   margin-left: 1rem;
}
#checkout-notes-container {
   .checkout-note-container {
      margin-bottom: 1rem;
   }
}
</style>
