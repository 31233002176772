<template>
   <div id="flight-divide-ancillary-container">
      <div id="divider-wrapper">
         <!-- CURRENT PNR -->
         <div class="divider-box">
            <h3>{{ $t("ancillariesServicesModal_divideCurrentPNR") }}</h3>
            <div class="current-wrapper" :class="divideStatus ? 'animate' : ''">
               <!-- Divider Item Lines Wrapper -->
               <ul class="divider-item-lines-wrapper">
                  <li v-for="(pax, key, index) in divider.current" :key="index" :class="pax.name == null ? 'ghost' : ''">
                     <span class="pax-index">{{ pax.index }}</span>
                     <div class="pax-informations-wrapper">
                        <div class="pax-informations-paxes-wrapper" :key="index">
                           <div class="pax-name-surname-wrapper">
                              <span class="pax-name-surname"> {{ pax.name }} {{ pax.surname }} </span>

                              <!-- IF HAS INFANT -->
                              <template v-if="pax.infant && pax.infant.name != null">
                                 <span class="pax-infant"> ({{ pax.infant.name }} {{ pax.infant.surname }}) </span>
                              </template>
                           </div>

                           <!-- IF HAS INFANT SET TYPE-->
                           <template v-if="pax.infant && pax.infant.name != null">
                              <span class="pax-type with-infant"> {{ handlerPaxType(99) }} </span>
                           </template>
                           <template v-else>
                              <span class="pax-type"> {{ handlerPaxType(pax.type) }} </span>
                           </template>
                        </div>
                     </div>
                  </li>
               </ul>
            </div>
         </div>

         <div class="divider-box">
            <h3 class="cta-hide">CTA</h3>
            <div class="switcher-wrapper">
               <!-- Divider Item Lines Wrapper -->
               <ul class="divider-item-lines-wrapper">
                  <li v-for="(switcher, key, index) in divider.switcher" :key="index">
                     <button @click="switcher.allow ? handlerSwitch(key) : undefined" :class="switcher.allow ? '' : 'swticher-disabled'">
                        <!-- {{ switcher.target }} -->
                        <i class="gts-icon icon-swap icon-sm gts-animation" v-if="!divideStatus"></i>
                        <!-- <span class="g-radial-loader loader-accent-gray loader-normal" v-else></span> -->
                     </button>
                  </li>
               </ul>
            </div>
         </div>

         <!-- TARGET PNR -->
         <div class="divider-box">
            <h3>{{ $t("ancillariesServicesModal_divideAfterDividePNR") }}</h3>
            <div :class="divideStatus ? 'current-wrapper animate' : 'target-wrapper'">
               <!-- Divider Item Lines Wrapper -->
               <ul class="divider-item-lines-wrapper">
                  <li v-for="(pax, key, index) in divider.target" :key="index" :class="pax.name == null ? 'ghost' : ''">
                     <span class="pax-index">{{ pax.index }}</span>
                     <div class="pax-informations-wrapper">
                        <div class="pax-informations-paxes-wrapper" :key="index">
                           <div class="pax-name-surname-wrapper">
                              <span class="pax-name-surname"> {{ pax.name }} {{ pax.surname }} </span>

                              <!-- IF HAS INFANT -->
                              <template v-if="pax.infant && pax.infant.name != null">
                                 <span class="pax-infant"> ({{ pax.infant.name }} {{ pax.infant.surname }}) </span>
                              </template>
                           </div>

                           <!-- IF HAS INFANT SET TYPE-->
                           <template v-if="pax.infant && pax.infant.name != null">
                              <span class="pax-type with-infant"> {{ handlerPaxType(99) }}</span>
                           </template>
                           <template v-else>
                              <span class="pax-type"> {{ handlerPaxType(pax.type) }}</span>
                           </template>
                        </div>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
      </div>

      <!-- <Section id="divider-footer">
            <div id="divider-footer-wrapper">
               <span class="reset" @click="handlerResetDivider">Resetle</span>
               <UIButton className="default" text="Divide" @click.native="handlerDivide" :isSpinnerActive="divideStatus" />
            </div>
         </Section> -->
   </div>
</template>

<script>
// import UIButton from "@/components/unitary/Button.vue";
import { generateUuid } from "@/utils.js";
import { flightService } from "@/service/index.js";
import AncillaryHelpers from "@/components/structural/flight/order-detail/ancillaries/helpers/ancillaries-helpers.js";
export default {
   name: "AncillayFlightDivide",
   props: {
      passengers: Array,
      pnrId: [String, Number],
   },
   components: {
      // UIButton,
   },
   data() {
      return {
         divider: {
            current: {},
            target: {},
            switcher: {},
         },
         divideStatus: false,
      };
   },
   created() {
      //       this.divider.current = this.passengers;
      // this.$parent.sections.footer = false;
      this.handlerGenerateDivider(this.passengers);
   },
   computed: {
      Controls() {
         const self = this;
         return {
            current: {
               adult: {
                  count: AncillaryHelpers.paxCountForType("current", 1, self),
               },
               child: {
                  count: AncillaryHelpers.paxCountForType("current", 2, self),
               },
               infant: {
                  count: AncillaryHelpers.paxCountForType("current", 3, self),
               },
               get total() {
                  return this.adult.count + this.child.count + this.infant.count;
               },
            },

            target: {
               adult: {
                  count: AncillaryHelpers.paxCountForType("target", 1, self),
               },
               child: {
                  count: AncillaryHelpers.paxCountForType("target", 2, self),
               },
               infant: {
                  count: AncillaryHelpers.paxCountForType("target", 3, self),
               },
               get total() {
                  return this.adult.count + this.child.count + this.infant.count;
               },
            },
         };
      },
   },
   methods: {
      handlerDivide() {
         const self = this;
         self.divideStatus = true;
         self.$parent.dividerResponseWaiting = true;

         Object.keys(this.divider.current).forEach((item) => {
            if (this.divider.current[item].name == null) {
               delete this.divider.current[item];
            }
         });
         Object.keys(this.divider.target).forEach((item) => {
            if (this.divider.target[item].name == null) {
               delete this.divider.target[item];
            }
         });
         Object.keys(this.divider.switcher).forEach((item, index) => {
            if (index > 1) {
               delete this.divider.switcher[item];
            }
         });

         Object.keys(this.divider.current).forEach((item, index) => {
            this.divider.current[item].index = index + 1;
         });
         Object.keys(this.divider.target).forEach((item, index) => {
            this.divider.target[item].index = index + 1;
         });

         flightService
            .ancillaryDivide({
               uuid: generateUuid(),
               pnrId: this.pnrId,
               paxIds: AncillaryHelpers.generatePaxIds(self),
            })
            .then((res) => {
               self.divideStatus = false;
               self.$parent.activeStep = 1;
               if (res.result.success) {
                  self.$parent.result = {
                     allowed: true,
                     success: true,
                     type: 3,
                     title: "ancillariesServicesModal_divideSuccessTitle",
                     // message: "ancillariesServicesModal_divideSuccessMessage",
                     message: self.$t("ancillariesServicesModal_divideSuccessMessage").replace("{PNR}", `<b>${res.newPnr}</b>`),
                  };
               } else {
                  self.$parent.result = {
                     allowed: true,
                     success: false,
                     type: 3,
                     title: "ancillariesServicesModal_divideFailedTitle",
                     // message: "ancillariesServicesModal_divideSuccessMessage",
                     message: self.$t("ancillariesServicesModal_divideFailedMessage"),
                  };
               }
            });
      },

      handlerSwitch(id) {
         const current = this.divider.current[id].name == null;
         const target = this.divider.target[id].name == null;

         if (target) {
            // console.log("hedef target");
            this.divider.target[id].name = this.divider.current[id].name;
            this.divider.target[id].surname = this.divider.current[id].surname;
            this.divider.target[id].id = this.divider.current[id].id;
            this.divider.target[id].index = this.divider.current[id].index;
            this.divider.target[id].type = this.divider.current[id].type;
            this.divider.target[id].infant = this.divider.current[id].infant;
            this.divider.switcher[id].target = "current";
            this.divider.current[id].name = null;
            this.divider.current[id].surname = null;
            this.divider.current[id].id = null;
            this.divider.current[id].index = null;
            this.divider.current[id].type = null;
            this.divider.current[id].infant = {
               name: null,
               surname: null,
               id: null,
               type: null,
            };
         }
         if (current) {
            // console.log("hedef current");
            this.divider.current[id].name = this.divider.target[id].name;
            this.divider.current[id].surname = this.divider.target[id].surname;
            this.divider.current[id].id = this.divider.target[id].id;
            this.divider.current[id].index = this.divider.target[id].index;
            this.divider.current[id].type = this.divider.target[id].type;
            this.divider.current[id].infant = this.divider.target[id].infant;
            this.divider.switcher[id].target = "target";
            this.divider.target[id].name = null;
            this.divider.target[id].surname = null;
            this.divider.target[id].id = null;
            this.divider.target[id].index = null;
            this.divider.target[id].type = null;
            this.divider.target[id].infant = {
               name: null,
               surname: null,
               id: null,
               type: null,
            };
         }
      },
      handlerGenerateDivider(obj) {
         obj.forEach((pax) => {
            if (pax.parentIndex == 0) {
               this.$set(this.divider.current, pax.paxIndex, {
                  name: pax.name,
                  surname: pax.surname,
                  id: pax.id,
                  index: pax.paxIndex,
                  type: pax.type,
                  identifier: "current",
                  infant: null,
               });
               this.$set(this.divider.switcher, pax.paxIndex, {
                  allow: true,
                  type: pax.type,
                  target: "target",
               });
               this.$set(this.divider.target, pax.paxIndex, {
                  name: null,
                  surname: null,
                  id: null,
                  index: null,
                  type: null,
                  identifier: "target",
                  infant: null,
               });
            } else {
               this.divider.current[pax.parentIndex].infant = {
                  name: pax.name,
                  surname: pax.surname,
                  id: pax.id,
                  type: pax.type,
               };

               this.divider.target[pax.parentIndex].infant = {
                  name: null,
                  surname: null,
                  id: pax.id,
                  type: null,
               };
               // this.divider.current[pax.parentIndex].paxes.push(paxModel(pax));
               // this.divider.target[pax.parentIndex].paxes.push(paxModel(pax));
            }
         });

         // obj.forEach((item) => {
         //    this.$set(this.divider.current, item.id, {
         //       name: item.name,
         //       surname: item.surname,
         //       id: item.id,
         //       index: item.paxIndex,
         //       type: item.type,
         //       identifier: "current",
         //       paxIndex: item.paxIndex,
         //       parentIndex: item.parentIndex,
         //    });
         //    this.$set(this.divider.target, item.id, {
         //       name: null,
         //       surname: null,
         //       id: item.id,
         //       index: null,
         //       type: null,
         //       identifier: "target",
         //    });
         //    this.$set(this.divider.switcher, item.id, {
         //       allow: true,
         //       type: item.type,
         //       target: "target",
         //    });
         // });
      },
      handlerPaxType(type) {
         return type == null ? null : this.$options.filters.EFltPaxType(type);
      },
      handlerAllowanceChildInfant(target, type, allow) {
         type.forEach((t) => {
            Object.keys(this.divider.switcher).forEach((item) => {
               if (this.divider.switcher[item].type == t && this.divider.switcher[item].target == target) {
                  this.divider.switcher[item].allow = allow;
               }
            });
         });
      },

      handlerAllowElement(divider) {
         let identifier;
         Object.keys(this.divider[divider]).forEach((item) => {
            if (this.divider[divider][item].name != null) {
               identifier = item;
            }
         });
         return identifier;
      },

      handlerResetDivider() {
         Object.keys(this.divider.target).forEach((item) => {
            if (this.divider.switcher[item].target == "current") {
               this.handlerSwitch(item);
            }
         });
      },

      // 18 NISAN 2023

      handlerGetIdOfElement(divider, paxType) {
         let identifier;
         Object.keys(this.divider[divider]).forEach((item) => {
            if (this.divider[divider][item].name != null && this.divider[divider][item].type == paxType) {
               identifier = this.divider[divider][item].index;
            }
         });
         return identifier;
      },
   },
   watch: {
      Controls: {
         handler: function (controls) {
            /*
               1) Current Box her seferinde en az bir adult a sahip olmak zorunda
               2) Target Box içerisinde bir adult olmadan child/infant alamaz
            */

            if (controls.current.adult.count == 1) {
               //Current Yetişkin Sayısı 1 ise
               let id = this.handlerGetIdOfElement("current", 1);
               this.divider.switcher[id].allow = false;
            } else if (controls.current.adult.count > 1) {
               //Current Yetişkin Sayısı 1'den büyükse
               Object.keys(this.divider.current).forEach((item) => {
                  if (this.divider.current[item].name != null) {
                     this.divider.switcher[item].allow = true;
                  }
               });

               // console.log("CURRENT HAS At Least Two ADULT!");
            }

            if (controls.target.adult.count == 0) {
               this.handlerAllowanceChildInfant("target", [2, 3], false);
            } else {
               this.handlerAllowanceChildInfant("target", [2, 3], true);
            }

            controls;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#flight-divide-ancillary-container {
   #divider-wrapper {
      display: grid;
      grid-template-columns: 5fr 1fr 5fr;

      .divider-box {
         // background-color: red;
         display: flex;
         flex-direction: column;
         h3 {
            margin-bottom: 1rem;
            color: #5e5e5e;
            font-weight: 500;
            font-size: var(--small);

            &.cta-hide {
               opacity: 0;
            }
         }

         .current-wrapper {
            border-radius: 16px;
            padding: 32px 40px;
            background-color: #f2f4f7;
            height: 100%;
            @media only screen and (max-width: 768px) {
               padding: 1rem 0.5rem;
            }

            @keyframes placeholder {
               0% {
                  background-color: #f2f4f7;
               }
               100% {
                  background-color: #e2e7f1;
               }
            }

            &.animate {
               animation: placeholder 0.75s infinite;
            }

            ul {
               li {
                  background-color: #fff;
               }
            }
         }
         .target-wrapper {
            padding: 32px 40px;
            border-radius: 16px;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23E1E3E6FF' stroke-width='8' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
            border-radius: 16px;
            height: 100%;
         }
      }

      .switcher-wrapper {
         display: flex;
         align-items: center;
         padding: 32px 0;

         ul {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
               box-shadow: none !important;
               background-color: transparent !important;
               button {
                  background-color: transparent !important;

                  &.swticher-disabled {
                     opacity: 0.5;
                  }
               }
            }
         }
      }

      .divider-item-lines-wrapper {
         // border: 1px solid #eee;

         li {
            height: 52px;
            display: flex;
            align-items: center;
            background: #eee;
            margin-bottom: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
            border-radius: 12px;
            @media only screen and (max-width: 768px) {
               padding: 0;
            }

            &:last-child {
               margin-bottom: 0;
            }

            &.ghost {
               box-shadow: none !important;
               background: none !important;
            }

            span.pax-index {
               font-family: "Poppins";
               padding-right: 0.75rem;
               margin-right: 0.75rem;
               border-right: 1px solid #c9c9c9;
               font-size: 1.7rem;

               @media only screen and (max-width: 768px) {
                  display: none;
                  font-size: 1rem;
               }
            }
            .pax-informations-wrapper {
               display: flex;
               flex-direction: column;
               .pax-informations-paxes-wrapper {
                  display: flex;
                  flex-direction: column;

                  div.pax-name-surname-wrapper {
                     display: flex;
                     align-items: center;
                     span.pax-name-surname {
                        font-weight: 500;
                        font-size: 1.125rem;
                        white-space: nowrap;
                        @media only screen and (max-width: 768px) {
                           font-size: 1rem;
                        }
                     }
                     span.pax-infant {
                        margin-left: 0.5rem;
                        font-size: 0.85rem;
                        white-space: nowrap;
                        @media only screen and (max-width: 768px) {
                           font-size: 0.75rem;
                        }
                        // color: var(--secondary);
                        // color: #ff4c4c;
                     }
                  }
                  span.pax-type {
                     font-size: 0.95rem;
                     font-weight: 500;

                     &.with-infant {
                        color: var(--secondary);
                        // color: #ff4c4c;
                     }
                  }
               }
            }
         }
      }
   }
   #divider-footer {
      border-top: 1px solid #ccc;
      margin-top: 2.5rem;
      padding-top: 1.5rem;
      display: flex;
      justify-content: flex-end;

      #divider-footer-wrapper {
         display: flex;
         align-items: center;
         span.reset {
            margin-right: 1.5rem;
            font-size: var(--large);
            color: var(--primary);
            text-decoration: underline;
            font-weight: 500;
         }
      }
   }
}
</style>
