import Vue from "vue";
import response from "@/placeholders/checkOut.js";
import { generateUuid } from "@/utils.js";

const state = {
   ancillariesPopUp: false,
   search: {
      request: {
         agencyCommissionExtra: 0,
         legs: {},
         cabinTypes: [],
         paxes: {},
         airlines: [],
         transfers: [],
      },
   },
   filters: {},
   basket: {},
   ///Burası Eskiden kalan
   checkout: {
      agencyCommissionExtra: 0,
      pricedifference: {
         status: false,
         value: null,
      },
      request: {},
      response: response, // default init for placeholder from @/placeholders/checkOut.js
   },
   cookieData: {
      passengers: null,
   },
   orderDetail: {
      focusedTask: null,
      showAncillary: true,
      showReissues: false,
   },
   // seatMap: {
   //    seats: null,
   //    selectedSeats: {},
   //    selectedPax: {},
   //    list: {},
   // },

   seatmap: {
      segments: {},
   },
};

const mutations = {
   seatMap_SetSegments(state, data) {
      Vue.set(state.seatmap.segments, data.id, data);
   },
   seatMap_updatePaxSelection(state, data) {
      Vue.set(state.seatmap.segments, data.id, data);
   },
   /*
#
#
#
*/
   setSeatMapPaxes(state, data) {
      Vue.set(state.seatMap, "list", data);
   },
   selectSeat(state, { paxId, seatName, price, type }) {
      Vue.set(state.seatMap.list[paxId], "seat", seatName);
      Vue.set(state.seatMap.list[paxId], "price", price);
      Vue.set(state.seatMap.list[paxId], "cabinType", type);
   },
   updateSelectedPax(state, { paxId, paxName, paxSeat }) {
      state.seatMap.selectedPax = {
         paxId,
         paxName,
         paxSeat,
      };
   },
   cancelSeat(state, data) {
      Vue.delete(state.seatMap.selectedSeats, data.seat);
      Vue.set(state.seatMap.list[data.paxId], "seat", null);
      Vue.set(state.seatMap.list[data.paxId], "price", null);
      Vue.set(state.seatMap.list[data.paxId], "cabinType", null);
   },
   setSelectedSeats(state, seatSelection) {
      state.seatMap.selectedSeats = seatSelection;
   },
   updateAgencyCommissionExtra(state, data) {
      state.search.request.agencyCommissionExtra = data;
   },
   updateSearchRequest(state, data) {
      state.search.request = data;
   },
   updateCabinTypes(state, data) {
      state.search.request.cabinTypes = data;
   },
   updateSegments(state, data) {
      state.search.request.transfers = data;
   },
   updateAirlines(state, data) {
      state.search.request.airlines = data;
   },
   updatePaxes(state, data) {
      state.search.request.paxes = data;
   },
   //Basket
   updateBasket(state, data) {
      Vue.set(state.basket, data.index, data.identifier);
   },
   deleteItemFromBasket(state, data) {
      Vue.delete(state.basket, data);
   },
   emptyBasket(state) {
      Vue.set(state, "basket", {});
   },
   //Filters

   updateFilters(state, data) {
      Vue.set(state.filters, data.index, data.filter);
   },
   emptyFilters(state) {
      Vue.set(state, "filters", {});
   },

   /*
   #######################
   ## Buradan altı silinecek
   #######################
   */
   updateFocusedTask(state, data) {
      state.orderDetail.focusedTask = data;
   },
   updateAgencyCommission() {
      this.dispatch("flight/generateUuid");
   },
   updateCheckoutUuid(state, data) {
      state.checkout.request.checkoutUuid = data;
   },
   updateCheckoutRequest(state, data) {
      state.checkout.request = data;
   },
   updateCheckoutResponse(state, data) {
      state.checkout.response = data;
   },
   updateCheckoutBrands(state, data) {
      Vue.set(state.checkout.request.flightsBrands, data.identifier, data.flightIdentifier);
   },
   updateCheckoutSsr(state, data) {
      const flightSsrs = state.checkout.request.flightsExtraSsrs[data.flightIdentifier];

      if (flightSsrs && flightSsrs.includes(data.identifier)) {
         if (data.status) {
            const index = flightSsrs.indexOf(data.identifier);
            if (index !== -1) {
               flightSsrs.splice(index, 1);
            }
         }
      } else {
         if (!data.status) {
            if (!flightSsrs) {
               Vue.set(state.checkout.request.flightsExtraSsrs, data.flightIdentifier, [data.identifier]);
            } else {
               flightSsrs.push(data.identifier);
            }
         }
      }
   },

   updateCheckoutBrandsPackage(state, data) {
      state.checkout.request.flightsBrands = data;
   },

   setCheckoutPassenger(state, data) {
      this.dispatch("flight/generateUuid");
      const control = data.paxIndex - 1 == 0;
      if (control) {
         state.checkout.request.contact.name = data.name;
         state.checkout.request.contact.surname = data.surname;
         state.checkout.request.contact.paxIndex = data.paxIndex;
         state.checkout.request.contact.gender = data.gender;
      }

      const dataObjController = data;
      dataObjController.name = dataObjController.name?.clearSpaces();
      dataObjController.surname = dataObjController.surname?.clearSpaces();
      dataObjController.passport.number = dataObjController.passport.number?.onlyAlphaNumerics().clearAllSpaces();

      state.checkout.request.passengers[data.paxIndex - 1] = dataObjController;
   },
   setPaxCookieData(state, data) {
      state.cookieData.passengers = data;
   },
   setCheckoutPassengersFromCookie(state, data) {
      this.dispatch("flight/generateUuid");
      state.checkout.request.passengers = data;
   },
   updateCheckoutPayment(state, data) {
      this.dispatch("flight/generateUuid");
      state.checkout.request.payment.paymentType = data.paymenttype;
      state.checkout.request.payment.vposId = data.vposid;
      state.checkout.request.payment.callbackUrl = `${window.location.origin}/finance/paymentCallback`;
      state.checkout.request.payment.creditcard = {
         cvv: data.cvv,
         holder: data.holder,
         month: data.month,
         pan: data.pan,
         year: data.year,
      };
   },
   /*
   #######################
   ## Buradan üstü silinecek
   #######################
   */
};

const actions = {
   updateSeatmapSegments({ commit }, seatmap) {
      commit("seatMap_SetSegments", seatmap);
   },
   updateSeatMapPaxSelection({ commit }, seatmap) {
      commit("seatMap_SetSegments", seatmap);
   },

   /// Buradan yukarısı 5 Haziran
   updateSeatList(context) {
      console.log("updateSeatList");
      function getInitials(name) {
         const words = name.split(" ");

         const initials = words.map((word) => word.charAt(0).toUpperCase()).join("");

         return initials;
      }

      const seatSelection = {};
      for (const key in context.state.seatMap.list) {
         const passenger = context.state.seatMap.list[key];
         const seat = passenger.seat;

         if (seat !== null) {
            seatSelection[seat] = { selected: true, paxName: getInitials(passenger.paxName) };
         }
      }

      context.commit("setSelectedSeats", seatSelection);
   },
   arrangeSeats(context) {
      const seatData = context.state.seatMap.seats;
      const seatLetters = ["A", "B", "C", "D", "E", "F"];

      const paxList = Object.values(context.state.seatMap.list);

      if (paxList.length === 0) {
         console.log("No passengers to arrange.");
         return;
      }

      const firstPax = paxList[0];

      if (!firstPax.seat) {
         console.log("First passenger does not have a seat assigned.");
         return;
      }

      const firstSeat = firstPax.seat;
      const rowNumber = parseInt(firstSeat.match(/\d+/)[0]);
      const startSeatLetter = firstSeat.match(/[A-Z]/)[0];

      const startSeatIndex = seatLetters.indexOf(startSeatLetter);

      for (let i = 1; i < paxList.length; i++) {
         const newSeatLetter = seatLetters[startSeatIndex + i];
         if (newSeatLetter) {
            const newSeat = newSeatLetter + rowNumber;

            console.log(seatData);

            const seatInfo = seatData.find((seat) => seat.rowNumber === rowNumber && seat.seatGroups.some((group) => group.seats.some((s) => s.name === newSeat)));
            if (seatInfo) {
               const seatStatus = seatInfo.seatGroups.flatMap((group) => group.seats).find((s) => s.name === newSeat).status;
               if (seatStatus === "available") {
                  paxList[i].seat = newSeat;

                  const seatPrice = seatInfo.seatGroups.flatMap((group) => group.seats).find((s) => s.name === newSeat).price;
                  paxList[i].price = seatPrice;
               }
            }
         }
      }

      context.dispatch("updateSeatList");
   },
   seatSelection(context, data) {
      const seatName = data.name;
      const selectedPaxID = context.state.seatMap.selectedPax.paxId;

      context.commit("selectSeat", { seatName, paxId: selectedPaxID, ...data });

      context.dispatch("updateSeatList");

      const currentPaxIndex = Object.keys(context.state.seatMap.list).indexOf(selectedPaxID.toString());

      const nextPaxIndex = (currentPaxIndex + 1) % Object.keys(context.state.seatMap.list).length;
      const nextPaxId = Object.keys(context.state.seatMap.list)[nextPaxIndex];
      const nextPax = context.state.seatMap.list[nextPaxId];

      context.commit("updateSelectedPax", {
         paxId: nextPax.paxId,
         paxName: nextPax.paxName,
         paxSeat: nextPax.seat,
      });
   },
   /*
   #######################
   ## Buradan altı silinecek
   #######################
   */
   generateUuid: function (state) {
      state.state.checkout.request.uuid = generateUuid();
   },
   /*
   #######################
   ## Buradan üstü silinecek
   #######################
   */

   selectAirline(context, selectedAirline) {
      Object.keys(context.state.filters).forEach((key) => {
         const airlines = Object.keys(context.state.filters[key].filters.airlines);
         airlines.forEach((airline) => {
            if (selectedAirline == airline) {
               context.state.filters[key].filters.airlines[airline].checked = !context.state.filters[key].filters.airlines[airline].checked;
            }
         });
      });
   },
   superiorAirline(context, superiorAirline) {
      Object.keys(context.state.filters).forEach((key) => {
         const airlines = Object.keys(context.state.filters[key].filters.airlines);
         airlines.forEach((airline) => {
            if (superiorAirline != airline) {
               context.state.filters[key].filters.airlines[airline].checked = false;
            } else {
               context.state.filters[key].filters.airlines[airline].checked = true;
            }
         });
      });
   },
   superiorAllAirline(context) {
      Object.keys(context.state.filters).forEach((key) => {
         const airlines = Object.keys(context.state.filters[key].filters.airlines);
         airlines.forEach((airline) => {
            context.state.filters[key].filters.airlines[airline].checked = true;
         });
      });
   },
};

export const flight = {
   namespaced: true,
   state,
   mutations,
   actions,
};
