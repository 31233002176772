import { PRIVATE_HTTP } from "./index";

const GET_AMEX_COMMISSION = "api/payment/getpaymentcommission";

export var amexCommission = (request) => {
   const promise = PRIVATE_HTTP().post(GET_AMEX_COMMISSION, request);
   return promise.then((response) => {
      return response.data;
   });
};
