<template>
   <section class="order-detail-tab-wrapper">
      <div class="order-detail-tab-inner">
         <ul class="nav nav-tabs" id="tab_orderDetail" role="tablist">
            <template v-if="voucherList.length != 0">
               <li
                  v-for="(voucherListItem, index) in voucherList"
                  :key="`v-${index}`"
                  class="nav-item voucher-nav-item"
                  role="presentation"
                  :data-voucher-tabIndex="voucherListItem.tabIndex"
                  :data-voucher-voucher="voucherListItem.voucher.voucher"
                  :data-entityId="voucherListItem.voucher.id">
                  <a
                     class="nav-link"
                     :class="(shouldPrioritizeVoucherList || pnrList.length == 0) && index == 0 ? 'active' : ''"
                     :id="`${voucherListItem.tabIndex}-tab`"
                     :href="`#${voucherListItem.tabIndex}-tabPane`"
                     :aria-controls="`#${voucherListItem.tabIndex}-tabPane`"
                     data-bs-toggle="tab"
                     role="tab"
                     @click="() => handlerSetActiveTabIndex(voucherListItem.voucher.id)"
                     aria-selected="true">
                     <span class="pnr-text"> {{ index + 1 }}.VOUCHER </span>
                     <span class="pnr-number"> ({{ voucherListItem.voucher.voucher }})</span>
                     <button class="pnr-badge gts-badge masked voucher-badge-tab" :class="'EHtlVoucherStatus-' + voucherListItem.voucher.status">
                        {{ voucherListItem.voucher.status | EHtlVoucherStatus }}
                     </button>
                  </a>
               </li>
            </template>
            <template v-if="pnrList.length != 0">
               <li
                  v-for="(pnrListItem, index) in pnrList"
                  :key="`p-${index}`"
                  class="nav-item pnr-nav-item"
                  role="presentation"
                  :data-pnr-tabIndex="pnrListItem.tabIndex"
                  :data-pnr-pnr="pnrListItem.pnr.pnr"
                  :data-entityId="pnrListItem.pnr.id"
                  :data-provider="pnrListItem.pnr.providerName"
                  :data-status="pnrListItem.pnr.status">
                  <a
                     class="nav-link"
                     :class="
                        queryString ? (queryString == pnrListItem.pnr.id ? 'active' : '') : !shouldPrioritizeVoucherList && index == 0 ? 'active' : ''
                     "
                     :id="`${pnrListItem.tabIndex}-tab`"
                     :href="`#${pnrListItem.tabIndex}-tabPane`"
                     :aria-controls="`#${pnrListItem.tabIndex}-tabPane`"
                     data-bs-toggle="tab"
                     role="tab"
                     aria-selected="true"
                     @click="() => handlerSetActiveTabIndex(pnrListItem.pnr.id)">
                     <span class="pnr-text"> {{ index + 1 }}.PNR </span>
                     <span class="pnr-number"> ({{ pnrListItem.pnr.pnr }})</span>
                     <button class="pnr-badge gts-badge masked pnr-badge-tab" :class="'EFltPnrStatus-' + pnrListItem.pnr.status">
                        <template v-if="pnrListItem.pnr.status"> {{ pnrListItem.pnr.status | EFltPnrStatus }} </template>
                     </button>
                     <button class="pnr-badge gts-badge-as-dot masked is-hidden-tablet" :class="'EFltPnrStatus-' + pnrListItem.pnr.status"></button>
                  </a>
               </li>
               <li></li>
            </template>
            <template v-if="tnrList.length != 0">
               <li
                  v-for="(tnrListItem, index) in tnrList"
                  :key="`t-${index}`"
                  class="nav-item transfer-nav-item"
                  role="presentation"
                  :data-transfer-tabIndex="tnrListItem.tabIndex"
                  :data-transfer-tnr="tnrListItem.tnr.tnr"
                  :data-entityId="tnrListItem.tnr.tnrId">
                  <a
                     class="nav-link"
                     :class="index == 0 && pnrList.length == 0 && voucherList.length == 0 ? 'active' : ''"
                     :id="`${tnrListItem.tabIndex}-tab`"
                     :href="`#${tnrListItem.tabIndex}-tabPane`"
                     :aria-controls="`#${tnrListItem.tabIndex}-tabPane`"
                     data-bs-toggle="tab"
                     role="tab"
                     aria-selected="true">
                     <span class="pnr-text"> {{ index + 1 }}.TNR </span>
                     <span class="pnr-number"> ({{ tnrListItem.tnr.tnr }})</span>
                     <button class="pnr-badge gts-badge masked tnr-badge-tab" :class="'ETrfTnrStatus-' + tnrListItem.tnr.status">
                        {{ tnrListItem.tnr.status | ETrfTnrStatus }}
                     </button>
                  </a>
               </li>
            </template>
            <template v-if="rnrList.length != 0">
               <li
                  v-for="(rnrListItem, index) in rnrList"
                  :key="`r-${index}`"
                  class="nav-item rent-a-car-nav-item"
                  role="presentation"
                  :data-rent-tabIndex="rnrListItem.tabIndex"
                  :data-rent-rnr="rnrListItem.rnr.rnr"
                  :data-entityId="rnrListItem.rnr.id">
                  <a
                     class="nav-link"
                     :class="index == 0 && pnrList.length == 0 && voucherList.length == 0 && tnrList.length == 0 ? 'active' : ''"
                     :id="`${rnrListItem.tabIndex}-tab`"
                     :href="`#${rnrListItem.tabIndex}-tabPane`"
                     :aria-controls="`#${rnrListItem.tabIndex}-tabPane`"
                     data-bs-toggle="tab"
                     role="tab"
                     aria-selected="true">
                     <span class="pnr-text"> {{ index + 1 }}.RNR </span>
                     <span class="pnr-number"> ({{ rnrListItem.rnr.rnr }})</span>
                     <button class="pnr-badge gts-badge masked rnr-badge-tab" :class="'ERacRnrStatus-' + rnrListItem.rnr.status">
                        {{ rnrListItem.rnr.status | ERacRnrStatus }}
                     </button>
                  </a>
               </li>
            </template>
         </ul>
         <div class="tab-content" id="tabPane_orderDetail">
            <template v-if="voucherList.length != 0">
               <div
                  v-for="(voucherListItem, index) in voucherList"
                  :key="`v-${index}`"
                  :data-voucher-tabPaneIndex="voucherListItem.tabIndex"
                  class="tab-pane fade"
                  :class="(shouldPrioritizeVoucherList || pnrList.length == 0) && index == 0 ? 'show active' : ''"
                  :id="`${voucherListItem.tabIndex}-tabPane`"
                  role="tabpanel"
                  :aria-labelledby="`${voucherListItem.tabIndex}-tab`">
                  <div class="tab-pane-inner-wrapper">
                     <OrderDetailHotelView :voucherDetail="voucherListItem" :packages="packages" />
                  </div>
               </div>
            </template>
            <template v-if="pnrList.length != 0">
               <div
                  v-for="(pnrListItem, index) in pnrList"
                  :key="`p-${index}`"
                  :data-pnr-tabPaneIndex="pnrListItem.tabIndex"
                  class="tab-pane fade"
                  :class="
                     queryString
                        ? queryString == pnrListItem.pnr.id
                           ? 'show active'
                           : ''
                        : !shouldPrioritizeVoucherList && index == 0
                        ? 'show active'
                        : ''
                  "
                  :id="`${pnrListItem.tabIndex}-tabPane`"
                  role="tabpanel"
                  :aria-labelledby="`${pnrListItem.tabIndex}-tab`">
                  <div class="tab-pane-inner-wrapper">
                     <OrderDetailFlightView :pnrProps="pnrListItem" :packages="packages" />
                  </div>
               </div>
            </template>
            <template v-if="tnrList.length != 0">
               <div
                  v-for="(tnrListItem, index) in tnrList"
                  :key="`t-${index}`"
                  :data-transfer-tabPaneIndex="tnrListItem.tabIndex"
                  class="tab-pane fade"
                  :class="index == 0 && pnrList.length == 0 && voucherList.length == 0 ? 'show active' : ''"
                  :id="`${tnrListItem.tabIndex}-tabPane`"
                  role="tabpanel"
                  :aria-labelledby="`${tnrListItem.tabIndex}-tab`">
                  <div class="tab-pane-inner-wrapper">
                     <OrderDetailTransferView :pageLoad="pageLoad" :orderId="orderId" :tnrDetail="tnrListItem" />
                  </div>
               </div>
            </template>
            <template v-if="rnrList.length != 0">
               <div
                  v-for="(rnrListItem, index) in rnrList"
                  :key="`r-${index}`"
                  :data-rent-tabPaneIndex="rnrListItem.tabIndex"
                  class="tab-pane fade"
                  :class="index == 0 && pnrList.length == 0 && voucherList.length == 0 && tnrList.length == 0 ? 'show active' : ''"
                  :id="`${rnrListItem.tabIndex}-tabPane`"
                  role="tabpanel"
                  :aria-labelledby="`${rnrListItem.tabIndex}-tab`">
                  <div class="tab-pane-inner-wrapper">
                     <OrderDetailRentACarView :pageLoad="pageLoad" :orderId="orderId" :rnrDetail="rnrListItem" />
                  </div>
               </div>
            </template>
         </div>
      </div>
   </section>
</template>

<script>
import OrderDetailHotelView from "@/components/structural/order/views/OrderDetailHotelView.vue";
import OrderDetailFlightView from "@/components/structural/order/views/OrderDetailFlightView.vue";
import OrderDetailTransferView from "@/components/structural/order/views/OrderDetailTransferView.vue";
import OrderDetailRentACarView from "@/components/structural/order/views/OrderDetailRentACarView.vue";

export default {
   name: "OrderDetailTab",
   components: {
      OrderDetailFlightView,
      OrderDetailHotelView,
      OrderDetailTransferView,
      OrderDetailRentACarView,
   },
   props: {
      packages: { type: Object },
      pnrs: { type: Array },
      vouchers: { type: Array },
      tnrs: { type: Array },
      rnrs: { type: Array },
      orderId: { type: Number },
      pageLoad: { type: Boolean },
   },
   data() {
      return {
         pnrList: [],
         voucherList: [],
         tnrList: [],
         rnrList: [],
         queryString: null,
      };
   },
   computed: {
      shouldPrioritizeVoucherList() {
         return this.$options.filters.EOdrType(this.packages.type) == this.$options.filters.EOdrType(5);
      },
   },
   created() {
      const self = this;
      self.pnrList = this.pnrs;
      self.voucherList = this.vouchers;
      self.tnrList = this.tnrs;
      self.rnrList = this.rnrs;
   },
   watch: {
      pnrs: {
         handler: function (newPnrs) {
            const self = this;
            self.pnrList = newPnrs;
         },
         deep: true,
      },
      vouchers: {
         handler: function (newVouchers) {
            const self = this;
            self.voucherList = newVouchers;
         },
         deep: true,
      },
      tnrs: {
         handler: function (newTnrs) {
            const self = this;
            self.tnrList = newTnrs;
         },
         deep: true,
      },
      rnrs: {
         handler: function (newRnrs) {
            const self = this;
            self.rnrList = newRnrs;
         },
         deep: true,
      },
      pnrList: {
         handler: function (newValue) {
            if (newValue.length) {
               if (this.$options.filters.EOdrType(this.packages.type) != this.$options.filters.EOdrType(5)) {
                  this.$store.commit("app/setActiveTabIndex", newValue[0].pnr.id);
               }
            }
         },
      },
      voucherList: {
         handler: function (newValue) {
            if (newValue.length != 0) {
               if (this.$options.filters.EOdrType(this.packages.type) == this.$options.filters.EOdrType(5)) {
                  this.$store.commit("app/setActiveTabIndex", newValue[0].voucher.id);
               }
            }
         },
      },
      pageLoad: {
         handler: function (value) {
            if (value && this.$router.history.current.query.pnrId) {
               this.queryString = this.$router.history.current.query.pnrId;
            }
         },
      },
   },
   methods: {
      handlerSetActiveTabIndex(index) {
         this.$store.commit("app/setActiveTabIndex", index);
      },
   },
};
</script>

<style lang="scss" scoped>
.order-detail-tab-wrapper {
   .order-detail-tab-inner {
      margin: 2rem 0;

      #tab_orderDetail {
         border: none !important;
         flex-wrap: nowrap;
         li {
            border-radius: 0.7rem 0.75rem 0 0;
            // border: 1px solid #ccc;
            overflow: hidden;
            border-bottom: none !important;
            margin: 0 0.25rem;
            a {
               background: #eff3f7;
               display: flex;
               align-items: center;
               span {
                  color: var(--txt-black) !important;
                  &.pnr-text {
                     font-size: var(--large);
                     font-weight: 600;
                     margin-right: 0.25rem;
                  }
                  &.pnr-number {
                     font-size: var(--small);
                     font-weight: 500;
                     margin-right: 0.75rem;
                  }
               }
               &.nav-link.active {
                  background: #fff;
               }

               .pnr-badge-tab {
                  display: none;
                  @media (min-width: 768px) {
                     display: flex;
                  }
               }

               .voucher-badge-tab {
                  &.EHtlVoucherStatus-15 {
                     padding: 1rem;
                  }
               }
            }

            &:first-child {
               margin-left: 0;
            }
         }
      }
      .tab-pane-inner-wrapper {
         padding: 18px 16px;
         background: #fff;
         border-radius: 0.5rem;
         border-top-left-radius: 0;
         @media (min-width: 992px) {
            padding: 3rem 2rem;
         }
      }
   }
}
</style>
