<template>
   <div class="transfer-search-result-wrapper">
      <div v-if="searchResponse && searchResponse.result && searchResponse.result.success" class="transfer-routes-wrapper row">
         <!-- Departure  -->
         <div class="transfer-route-wrapper column" :class="[IsRoundTrip ? 'is-6' : 'col-12', 'transfer-route-1']" data-has-selection="false">
            <!-- Transfer Search Result Header Top -->
            <div class="transfer-search-result-header-container">
               <!-- Counts & Sorting  -->
               <div class="tsrh-row">
                  <div class="counts-wrapper">
                     <span class="total-count">{{ TotalDeparture + " " + $t("TransferSearch_lblCarFound") }}</span>
                     <span class="trs-route" v-html="trsRoute"></span>
                  </div>

                  <div class="sorting-wrapper">
                     <UISelectBox
                        id="filterReturnTransfer"
                        :options="options"
                        v-model="selectedDefault.departure"
                        @input="handlerSortDepartureTransfers"
                        light-theme />
                  </div>
               </div>
            </div>
            <div class="transfer-search-result-content">
               <div class="transfer-search-result-content-inner">
                  <div
                     class="transfer-search-result-list-item"
                     v-for="(transferItem, transferIndex) in filteredTransfers.departure"
                     :key="transferIndex">
                     <TransferCard
                        v-if="transferItem.isShowActive"
                        v-show="groupCodeReturn == '' || transferItem.groupCode == groupCodeReturn ? true : false"
                        :transferItem="transferItem"
                        :index="1"
                        @updateGroupCodeDeparture="updateGroupCodeDeparture"
                        @updateGroupCodeReturn="updateGroupCodeReturn" />
                  </div>
               </div>
            </div>
         </div>

         <!-- Return  -->
         <div v-if="IsRoundTrip" class="transfer-route-wrapper column is-6 transfer-route-2">
            <!-- Transfer Search Result Heaer Top -->
            <div class="transfer-search-result-header-container">
               <div class="tsrh-row">
                  <div class="counts-wrapper">
                     <span class="total-count">{{ TotalReturn + " " + $t("TransferSearch_lblCarFound") }}</span>
                     <span class="trs-route" v-html="trsRouteReturn"></span>
                  </div>

                  <div class="sorting-wrapper">
                     <UISelectBox
                        id="filterReturnTransfer"
                        :options="options"
                        v-model="selectedDefault.return"
                        @input="handlerSortReturnTransfers"
                        light-theme />
                  </div>
               </div>
            </div>

            <div class="transfer-search-result-content">
               <div class="transfer-search-result-content-inner">
                  <div
                     class="transfer-search-result-list-item"
                     v-for="(transferItem, transferIndex) in filteredTransfers.return"
                     :key="transferIndex">
                     <TransferCard
                        v-if="transferItem.isShowActive"
                        v-show="groupCodeDeparture == '' || transferItem.groupCode == groupCodeDeparture ? true : false"
                        :transferItem="transferItem"
                        :index="2"
                        @updateGroupCodeDeparture="updateGroupCodeDeparture"
                        @updateGroupCodeReturn="updateGroupCodeReturn" />
                  </div>
               </div>
            </div>
         </div>
      </div>
      <UIAlert status="default__light" v-else>
         <p class="w-100 text-center">{{ $t("transferSearch_lblEmptyTransferList") }}</p>
      </UIAlert>
   </div>
</template>

<script>
import TransferCard from "@/components/structural/transfer/search/TransferCard.vue";
import UIAlert from "@/components/unitary/Alert.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";

export default {
   name: "TransferSearchResult",
   components: {
      TransferCard,
      UIAlert,
      UISelectBox,
   },
   props: {
      searchResponse: Object,
      filteredTransfers: Object,
      isBasketActive: Boolean,
   },
   data() {
      return {
         groupCodeDeparture: "",
         groupCodeReturn: "",
         selectedDefault: {
            departure: "priceincrease",
            return: "priceincrease",
         },
         options: [
            { id: 1, name: this.$i18n.t(`common_price_increase`), value: "priceincrease" },
            { id: 2, name: this.$i18n.t(`common_price_decrease`), value: "pricedecrease" },
            { id: 3, name: this.$i18n.t(`common_capacity_increase`), value: "capacityincrease" },
            { id: 4, name: this.$i18n.t(`common_capacity_decrease`), value: "capacitydecrease" },
         ],
      };
   },
   methods: {
      updateGroupCodeDeparture(value) {
         this.groupCodeDeparture = value;
      },
      updateGroupCodeReturn(value) {
         this.groupCodeReturn = value;
      },
      handlerSortDepartureTransfers(selection) {
         switch (selection) {
            case "priceincrease":
               this.filteredTransfers.departure.sort((a, b) => (a.price.totalFare > b.price.totalFare ? 1 : -1));
               break;
            case "pricedecrease":
               this.filteredTransfers.departure.sort((a, b) => (a.price.totalFare > b.price.totalFare ? -1 : 1));
               break;
            case "capacityincrease":
               this.filteredTransfers.departure.sort((a, b) => (a.vehicle.capacity > b.vehicle.capacity ? 1 : -1));
               break;
            case "capacitydecrease":
               this.filteredTransfers.departure.sort((a, b) => (a.vehicle.capacity > b.vehicle.capacity ? -1 : 1));
               break;
         }
      },
      handlerSortReturnTransfers(selection) {
         switch (selection) {
            case "priceincrease":
               this.filteredTransfers.return.sort((a, b) => (a.price.totalFare > b.price.totalFare ? 1 : -1));
               break;
            case "pricedecrease":
               this.filteredTransfers.return.sort((a, b) => (a.price.totalFare > b.price.totalFare ? -1 : 1));
               break;
            case "capacityincrease":
               this.filteredTransfers.return.sort((a, b) => (a.vehicle.capacity > b.vehicle.capacity ? 1 : -1));
               break;
            case "capacitydecrease":
               this.filteredTransfers.return.sort((a, b) => (a.vehicle.capacity > b.vehicle.capacity ? -1 : 1));
               break;
         }
      },
   },
   computed: {
      trsRouteReturn() {
         var toRoute = "<span style='font-weight: 600;   font-size: var(--medium);'>" + this.TitleB + "," + this.SubTitleB + "</span>";
         var fromRoute = "<span style='font-weight: 600;   font-size: var(--medium);'>" + this.TitleA + "," + this.SubTitleA + "</span>";

         return this.$t("TransferSearch_lblTransferRoute").replace("#VALUE#", toRoute).replace("#VALUE1#", fromRoute);
      },
      trsRoute() {
         var toRoute = "<span style='font-weight: 600;   font-size: var(--medium);'>" + this.TitleB + "," + this.SubTitleB + "</span>";
         var fromRoute = "<span style='font-weight: 600;   font-size: var(--medium);'>" + this.TitleA + "," + this.SubTitleA + "</span>";

         return this.$t("TransferSearch_lblTransferRoute").replace("#VALUE#", fromRoute).replace("#VALUE1#", toRoute);
      },
      IsRoundTrip() {
         return this.filteredTransfers.return && this.filteredTransfers.return.length > 0;
      },
      TotalDeparture() {
         if (this.groupCodeReturn == "") {
            const activeTransfers = this.filteredTransfers.departure.filter((transfer) => transfer.isShowActive === true);
            return activeTransfers.length;
         } else {
            const activeTransfers = this.filteredTransfers.departure.filter(
               (transfer) => transfer.isShowActive === true && transfer.groupCode == this.groupCodeReturn
            );
            return activeTransfers.length;
         }
      },
      TotalReturn() {
         if (this.groupCodeDeparture == "") {
            const activeTransfers = this.filteredTransfers.return.filter((transfer) => transfer.isShowActive === true);
            return activeTransfers.length;
         } else {
            const activeTransfers = this.filteredTransfers.return.filter(
               (transfer) => transfer.isShowActive === true && transfer.groupCode == this.groupCodeDeparture
            );
            return activeTransfers.length;
         }
      },
      TitleA() {
         return this.searchResponse != null && this.searchResponse.transfers["1"][0].from != null
            ? this.searchResponse.transfers["1"][0].from.destinationName.split(",")[0]
            : "";
      },
      SubTitleA() {
         return this.searchResponse != null && this.searchResponse.transfers["1"][0].from != null
            ? this.searchResponse.transfers["1"][0].from.destinationName.split(",")[1]
            : "";
      },
      TitleB() {
         return this.searchResponse != null && this.searchResponse.transfers["1"][0].to != null
            ? this.searchResponse.transfers["1"][0].to.destinationName.split(",")[0]
            : "";
      },
      SubTitleB() {
         return this.searchResponse != null && this.searchResponse.transfers["1"][0].to != null
            ? this.searchResponse.transfers["1"][0].to.destinationName.split(",")[1]
            : "";
      },
   },
   watch: {
      "$store.state.transfer.basket": {
         handler: function (status) {
            if (status.departureOfferId == 0 && status.returnOfferId == 0) {
               this.groupCodeDeparture = "";
               this.groupCodeReturn = "";
            }
         },
         immediate: true,
      },
      "$store.state.transfer.isSearching": {
         handler: function (status) {
            if (status) {
               this.groupCodeDeparture = "";
               this.groupCodeReturn = "";
            }
         },
         immediate: true,
      },
      // filteredTransfers: {
      //    handler() {
      //       this.TotalDeparture();
      //       this.TotalReturn();
      //    },
      //    deep: true,
      // },
      "$i18n.locale"() {
         this.options[0].name = this.$i18n.t(`common_price_increase`);
         this.options[1].name = this.$i18n.t(`common_price_decrease`);
         this.options[2].name = this.$i18n.t(`common_capacity_increase`);
         this.options[3].name = this.$i18n.t(`common_capacity_decrease`);
      },
      immediate: true,
      deep: true,
   },
};
</script>

<style lang="scss" scoped>
.transfer-search-result-wrapper {
   width: 100%;
   max-width: 100%;
   transition: 0.3s ease-in-out;

   .transfer-search-result-inner,
   .transfer-routes-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .transfer-search-result-header-container {
         background: #ffffff;
         padding: 1rem 2rem 1rem 1rem;
         border-radius: 1rem;

         .tsrh-row {
            display: flex;
            justify-content: space-between;

            &.infographic-container {
               border-bottom: 2px dashed #fff;
               padding-bottom: 1rem;
               margin-bottom: 1rem;
            }
         }

         .tsrh-location-container {
            display: flex;

            .tsrh-location-icon-wrapper {
               $iconSize: 52px;
               width: $iconSize;
               height: $iconSize;
               position: relative;

               span {
                  position: absolute;
                  color: #fff;
                  font-size: var(--xlarge);
                  font-weight: 500;
                  top: -3px;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  margin: auto;
                  width: fit-content;
                  height: fit-content;
               }

               &:first-child {
                  margin-right: 1rem;
               }
               &:last-child {
                  margin-left: 1rem;
               }
            }

            .tsrh-location-informations {
               display: flex;
               flex-direction: column;
               justify-content: center;
               line-height: 1.4rem;

               span {
                  color: var(--txt-negative);
                  &.from-location-name-title,
                  &.to-location-name-title {
                     font-size: var(--large);
                     font-weight: 600;
                  }
                  &.from-location-name-title {
                  }
               }
            }

            &.location-b {
               .tsrh-location-informations {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
               }
            }
         }
      }
      .transfer-search-filter-wrapper {
         width: 25%;
         height: 100%;
         background-color: var(--c-primary);
         box-shadow: 0px 2px 6px #0000001a;
         border-radius: 12px;
         color: var(--txt-black);
      }
      .transfer-search-route-content {
         transition: 0.3s ease-in-out;
         background: var(--primary);
         color: white;
         padding: 2rem;
         border-radius: 8px;
         box-shadow: 0px 2px 6px #0000001a;
         margin-bottom: 0;
         border-bottom-left-radius: 0;
         border-bottom-right-radius: 0;

         .transfer-search-result-list-item {
            margin-bottom: 24px;

            &:last-child {
               margin-bottom: 0;
            }
         }

         .transfer-search-route-inner {
            .t-body-route-container {
               width: 100%;
               display: flex;
               flex-direction: column;

               i {
                  flex-shrink: 0;
               }

               .t-body-route {
                  display: flex;
                  position: relative;

                  &:before {
                     content: "";
                     position: absolute;
                     height: 2px;
                     left: -5px;
                     right: -5px;
                     bottom: 0;
                     top: 0;
                     margin: auto;
                     background-color: var(--secondary);
                  }
                  .from-location,
                  .to-location {
                     width: 100%;
                     &:before,
                     &:after {
                        content: "";
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        background-color: var(--secondary-lighten);
                        border-radius: 50%;
                     }
                     &:after {
                        width: 8px;
                        height: 8px;
                        background-color: var(--secondary);
                     }
                     .from-location-name,
                     .to-location-name {
                        white-space: break-spaces;
                     }
                  }
                  .from-location {
                     &:before {
                        left: -20px;
                     }
                     &:after {
                        left: -16px;
                     }
                  }
                  .to-location {
                     align-items: flex-end;
                     &:before {
                        right: -20px;
                     }
                     &:after {
                        right: -16px;
                     }
                  }

                  // should not be child
                  .route-name {
                     font-weight: 500;
                     color: var(--txt-black);
                     font-size: 1rem;
                     &.marked {
                        color: var(--accent-primary);
                     }
                  }
               }

               .transfer-icon-wrapper {
                  position: relative;
                  width: 100%;

                  i {
                     position: absolute;
                     top: 0;
                     left: 0;
                     right: 0;
                     bottom: 0;
                     margin: auto;
                     box-sizing: content-box;
                  }
               }
            }
         }
      }
      .transfer-search-result-content {
         width: 100%;
         transition: 0.3s ease-in-out;
         margin-top: 1rem;
         background: white;
         padding: 1rem;
         box-shadow: 0px 2px 6px #0000001a;
         border-radius: 0.75rem;
         .transfer-search-result-content-inner {
            .transfer-search-result-list {
               transition: 0.3s ease-in-out;
               background: white;
               padding: 2rem;
               border-radius: 8px;
               border-color: var(--pink);
               box-shadow: 0px 2px 6px #0000001a;
               margin-bottom: 24px;

               &:last-child {
                  margin-bottom: 0;
               }
               .transfer-search-result-list-item {
                  margin-bottom: 24px;

                  &:last-child {
                     margin-bottom: 0;
                  }
               }
            }

            ::v-deep .gts-alert {
               p {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .gts-icons {
                     display: inline-block;
                     width: 40px;
                     min-width: 40px;
                     max-width: 40px;
                     height: 35px;
                     max-height: 35px;
                     background-size: contain;
                     background-repeat: no-repeat;
                     margin-right: 8px;
                  }
               }
            }
            .transfer-search-result-error {
               background-color: var(--c-primary);
               box-shadow: 0px 2px 6px #0000001a;
               border-radius: 12px;
               color: var(--txt-black);
            }
         }
      }
      .counts-wrapper {
         display: flex;
         flex-direction: column;
         span {
            color: #fff;
            &.total-count {
               font-size: var(--large);
               font-weight: 600;
               margin-right: 0.25rem;
               color: #363636;
            }
            &.trs-route {
               color: #363636;
               padding-top: 0.25rem;
               font-size: var(--medium);
            }
         }
      }
      .sorting-wrapper {
         ::v-deep {
            padding-top: 0.25rem;
            .g-select-box {
               min-height: 40px;
            }
         }
      }
   }
}
</style>
