<template>
   <div class="transfer-card-container" :data-vehicle-id="transferItem.offerId">
      <div class="transfer-card-body-wrapper">
         <!-- Vehicle Image -->
         <div class="transfer-card-image-wrapper" :class="this.$parent.IsRoundTrip ? 'notVisible' : ''">
            <img
               class="vehicle-image"
               :src="$constants.cdn + '/transfer/vehicle_type/' + transferItem.vehicle.vehicleTypeId + '.png'"
               :srcset="`${$constants.cdn + '/transfer/vehicle_type/' + transferItem.vehicle.vehicleTypeId + '.png'} 300w, 
                        ${$constants.cdn + '/transfer/vehicle_type/' + transferItem.vehicle.vehicleTypeId + '.png'} 600w, 
                        ${$constants.cdn + '/transfer/vehicle_type/' + transferItem.vehicle.vehicleTypeId + '.png'} 900w`"
               sizes="
                        (max-width: 300px) 300px,
                        (max-width: 600px) 600px,
                        (max-width: 900px) 900px,"
               loading="lazy"
               alt="Vehicle Image" />
            <div class="vehicle-representative">
               <span class="representative-text">{{ $t("transfer_RepresentativeVehicle") }}</span>
            </div>
         </div>

         <div class="transfer-card-informations-actions" :class="this.$parent.IsRoundTrip ? 'IsRoundTrip' : ' '">
            <!-- Transfer Informations -->
            <div class="transfer-card-informations-wrapper">
               <div class="vehicle-informations-wrapper">
                  <span class="vehicle-type">{{ transferItem.vehicle.type | ETrfVehicleType }}</span>
                  <span class="vehicle-info">{{ transferItem.vehicle.info }} {{ $t("transfer_phOrSimilar") }}</span>
               </div>
               <!-- Facilities Wrapper -->
               <div class="vehicle-facilities-wrapper">
                  <div class="icon-wrapper">
                     <i class="gts-icon icon-transfer-seat"></i>
                     <span>{{ $t("TransferCard_lblMax") }} {{ transferItem.vehicle.capacity }}</span>
                  </div>

                  <div class="icon-wrapper">
                     <i class="gts-icon icon-transfer-baggage"></i>
                     <span>{{ transferItem.vehicle.baggage }} {{ $t("TransferCard_lblBaggage") }}</span>
                  </div>
               </div>
            </div>

            <div
               class="extra-features"
               :class="this.$parent.IsRoundTrip ? 'isRoundTrip' : ' '"
               @mouseenter="handlerShowExtras(true)"
               @mouseleave="handlerShowExtras(false)">
               <button :class="showExtras ? 'active' : ''">{{ $t("transfer_lblExtraFeature") }}</button>

               <ul class="card-extra-features-box" v-for="(item, index) in transferItem.facilities" :key="index" v-show="showExtras">
                  {{
                     item | ETrfFacilityType
                  }}
               </ul>
            </div>

            <!-- Action Button -->
            <div class="transfer-card-action-wrapper">
               <div class="transfer-card-action-inner-wrapper">
                  <div class="provider-badge">
                     <span>
                        {{ $t("transfer_lblProvider") }}
                     </span>
                  </div>
                  <div class="provider-logo">
                     <img :src="`/assets/logo_provider/${transferItem.channel.implementationType}.png`" alt="Provider Logo" />
                  </div>
                  <div class="price-wrapper">
                     <span class="total-fare"> {{ transferItem.price.totalFare | price(transferItem.price.currency) }}</span>
                  </div>

                  <div class="select-vehicle-button-wrapper">
                     <UIButton
                        v-if="!isSelectedVehicle"
                        className="transfer-dark"
                        :notRounded="true"
                        :text="$t('TransferCard_btnSelectCar')"
                        @click.native="handleSelectTransfer(transferItem.offerId, transferItem.groupCode)" />
                     <UIButton
                        v-else
                        className="transfer-dark"
                        :notRounded="true"
                        :text="$t('TransferCard_btnRemoveCar')"
                        @click.native="handleDeleteTransfer(transferItem.offerId, transferItem.groupCode)" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";
export default {
   name: "TransferCardComponent",
   props: {
      index: Number,
      transferItem: Object,
   },
   components: {
      UIButton,
   },
   data() {
      return {
         showExtras: false,
         isSelectedVehicle: false,
         showImageOnMobile: false,
      };
   },

   methods: {
      handlerShowExtras(status) {
         this.showExtras = status;
      },
      handleSelectTransfer(offerId, groupCode) {
         const self = this;
         if (this.index == 1) {
            self.$store.commit("transfer/updateBasket", {
               departureOfferId: offerId,
               returnOfferId: self.$store.state.transfer.basket.returnOfferId,
            });
            self.isSelectedVehicle = true;
            this.$emit("updateGroupCodeDeparture", groupCode);
         } else if (this.index == 2) {
            self.$store.commit("transfer/updateBasket", {
               departureOfferId: self.$store.state.transfer.basket.departureOfferId,
               returnOfferId: offerId,
            });
            self.isSelectedVehicle = true;
            this.$emit("updateGroupCodeReturn", groupCode);
         }
         document
            .querySelector(this.index == 1 ? ".transfer-route-1" : ".transfer-route-2")
            .querySelectorAll(".transfer-card-container")
            .forEach((card) => {
               card.classList.add("d-none");
            });
         document
            .querySelector(this.index == 1 ? ".transfer-route-1" : ".transfer-route-2")
            .querySelectorAll(`[data-vehicle-id='${offerId}']`)
            .forEach((card) => {
               card.classList.remove("d-none");
            });
      },
      handleDeleteTransfer() {
         const self = this;
         if (this.index == 1) {
            self.$store.commit("transfer/updateBasket", {
               departureOfferId: 0,
               returnOfferId: self.$store.state.transfer.basket.returnOfferId,
            });
            self.isSelectedVehicle = false;
            this.$emit("updateGroupCodeDeparture", "");
         } else if (this.index == 2) {
            self.$store.commit("transfer/updateBasket", {
               departureOfferId: self.$store.state.transfer.basket.departureOfferId,
               returnOfferId: 0,
            });
            self.isSelectedVehicle = false;
            this.$emit("updateGroupCodeReturn", "");
         }
         document
            .querySelector(this.index == 1 ? ".transfer-route-1" : ".transfer-route-2")
            .querySelectorAll(".transfer-card-container")
            .forEach((card) => {
               card.classList.remove("d-none");
            });
      },
   },
   created() {
      if (!this.$parent.IsRoundTrip) {
         this.showImageOnMobile = true;
      }
   },
   watch: {
      "$store.state.transfer.basket": {
         handler: function (res) {
            res.departureOfferId == 0 && res.returnOfferId == 0 ? (this.isSelectedVehicle = false) : undefined;
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.transfer-card-container {
   position: relative;
   margin-bottom: 1rem;
   border-radius: 1rem;
   border: 1px solid #e6e6e6;
   background: #f7f8fa;
   .transfer-card-body-wrapper {
      height: 14rem;
      display: flex;
      .transfer-card-image-wrapper {
         position: relative;
         top: 15px;
         left: 0px;
         margin: 0;
         height: 180px;
         .vehicle-image {
            max-height: 190px;
            position: absolute;
         }
         @media (max-width: 768px) {
            display: none;
         }
         &.notVisible {
            display: none;
         }
      }
      .transfer-card-informations-actions {
         display: flex;
         width: 80%;
         justify-content: space-between;
         &.IsRoundTrip {
            width: 100%;
         }
         @media (max-width: 992px) {
            width: 100%;
         }
         .transfer-card-informations-wrapper {
            padding-left: 2rem;
            display: flex;
            justify-content: space-evenly;
            flex-direction: column;
            @media (max-width: 768px) {
               margin-top: 2rem;
            }
            .vehicle-informations-wrapper {
               display: flex;
               flex-direction: column;
               line-height: 1.5rem;
               margin-top: -1.5rem;
               span.vehicle-type {
                  font-size: var(--xlarge);
                  font-weight: 600;
               }
               span.vehicle-info {
                  font-size: var(--small);
                  font-weight: 400;
               }
            }
            .vehicle-facilities-wrapper {
               display: flex;
               flex-wrap: wrap;
               $iconSize: 32px;

               .icon-wrapper {
                  display: flex;
                  align-items: center;
                  margin-right: 1rem;

                  i {
                     width: $iconSize;
                     height: $iconSize;
                  }

                  span {
                     font-size: var(--small);
                     font-weight: 500;
                     margin-left: 0.25rem;
                  }
               }

               @media (max-width: 768px) {
                  justify-content: space-between;
                  .icon-wrapper {
                     flex: 1 1 35%;
                     margin-bottom: 1rem;
                  }
               }
            }
         }
         .extra-features {
            position: absolute;
            right: 0;
            transform: translateX(-20rem);
            top: 2rem;
            border-radius: 1.5rem;
            @media (max-width: 1920px) {
               transform: translateX(-17rem);
            }
            button {
               background-color: #e6e6e6;
               color: #363636;
               border-radius: 24rem;
               padding: 0rem 1rem;
               font-weight: 600;
               font-size: 0.85rem;

               &:hover,
               &.active {
                  background-color: #363636;
                  color: #fff;
               }
            }
            &.isRoundTrip {
               margin-top: 0.25rem;
               top: 0;
               transform: translateX(-16rem);
               @media (max-width: 768px) {
                  transform: translateX(-12rem);
                  top: 0;
               }
               @media (max-width: 992px) {
                  display: none;
                  width: 100%;
               }
            }
            @media (max-width: 768px) {
               transform: translateX(-12rem);
               top: 0;
            }
            span {
               font-size: var(--small);
               font-weight: 500;
            }
            .card-extra-features-box {
               position: absolute;
               background: #fff;
               border: 1px solid #ccc;
               box-shadow: rgba(0, 0, 0, 0.03) 0px 4px 6px -1px, rgba(0, 0, 0, 0.03) 0px 2px 4px -1px;
               padding: 1rem 1.5rem;
               top: 100%;
               left: -500px;
               right: -500px;
               margin: auto;
               width: fit-content;
               z-index: 10;
               border-radius: 0.75rem;

               li {
                  white-space: nowrap;
                  padding: 0.25rem 0;
                  font-weight: 500;
               }
            }
         }
         .transfer-card-action-wrapper {
            border-left: 2px solid #e6e6e6;
            padding: 0 3rem;
            display: flex;
            &.isRoundTrip {
               padding: 0 0;
            }
            @media (max-width: 768px) {
               padding: 0 1rem;
            }
            .transfer-card-action-inner-wrapper {
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: center;

               .provider-badge {
                  background: #d3fbff;
                  padding: 0.1rem;
                  width: 70%;
                  text-align: center;
                  span {
                     color: #2e9099;
                     font-weight: 600;
                  }
               }

               .provider-logo {
                  width: 60%;
               }

               .price-wrapper {
                  display: flex;
                  justify-content: center;
                  margin-bottom: 0.25rem;
                  .total-fare {
                     font-size: var(--xxlarge);
                     font-weight: 800;
                  }
                  .currency {
                     font-size: var(--xxlarge);
                     font-weight: 600;
                  }
               }

               .select-vehicle-button-wrapper {
                  ::v-deep {
                     button {
                        font-size: var(--normal);
                        font-weight: 600;
                        height: auto !important;
                     }
                  }
               }
            }
         }
      }
   }
}
</style>
