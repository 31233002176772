<template>
   <div class="seat" :class="DynamicClasses" @click="handlerSelectSeat">
      <template v-if="IsAvailable">
         <template v-if="IsSelected?.selected">
            <span class="seat-name selected" @mouseover="toggleTooltip" @mouseout="toggleTooltip">
               {{ IsSelected.paxName }}
            </span>
         </template>

         <template v-else>
            <span class="seat-name" @mouseover="toggleTooltip" @mouseout="toggleTooltip"> {{ seat.name }}</span>
         </template>
      </template>
      <template v-else>
         <span class="seat-unavailable"> </span>
      </template>
      <Transition name="fade">
         <div class="seat-tooltip-wrapper" :class="{ 'exit-seat': seat.exitSeat, [seat.tooltipLocation]: seat.tooltipLocation, hasOwner: IsSelected?.selected }" v-if="showTooltip">
            <span class="passenger-name">
               <template v-if="IsSelected?.selected">
                  {{ OwnerPax.paxNameFull }}
               </template>
               <template v-else>
                  {{ SelectedPassenger.name }}
               </template>
            </span>
            <!-- <span class="seat-price">{{ ExtraLeg }} </span> -->
            <span class="seat-price">{{ seat.price | price(currency) }} </span>

            <span class="seat-name">{{ $t("ancillarySeatMap_lblSeat") }}: {{ seat.name }} </span>

            <ul class="seat-infos">
               <li v-for="(seatType, seatTypeIndex) in seat.type" :key="seatTypeIndex">
                  {{ seatType | EFltSeatTypeEnum }}
               </li>
            </ul>
            <!-- <ul class="seat-features" v-if="true">
               <li v-for="(feature, featureIndex) in seat.features" :key="featureIndex">
                  {{ feature }}
               </li>
            </ul> -->
         </div>
      </Transition>
   </div>
</template>

<script>
export default {
   name: "Seat",
   props: {
      seat: Object,
      currency: String,
   },
   data() {
      return {
         showTooltip: false,
      };
   },
   created() {
      console.log(this.seat);
   },
   computed: {
      selectSeatQ() {
         return this.$store.state.seatmap.selectSeatQ;
      },
      IsSelected() {
         return this.$store.state.seatmap.segments[this.seat.segmentId].selectedSeats[this.seat.name];
      },
      SelectedPassenger() {
         return this.$store.state.seatmap.selectedPax;
      },
      OwnerPax() {
         return this.$store.state.seatmap.segments[this.seat.segmentId].selectedSeats[this.seat.name];
      },
      DynamicClasses() {
         return {
            available: this.seat.status === "available",
            occupied: this.seat.status === "occupied",
            unavailable: this.seat.status === "unavailable",
            "exit-seast": this.seat.exitSeat,
            "extra-space": this.seat.type.includes(6),
            hideSeat: this.seat.name == "EMPTY",
         };
      },
      IsAvailable() {
         return this.seat.status === "available" || this.IsSelected;
      },
   },
   methods: {
      handlerSelectSeat() {
         if (this.IsSelected == undefined && this.IsAvailable) {
            this.$store.dispatch("seatmap/selectSeat", this.seat);
         }
      },
      toggleTooltip() {
         this.showTooltip = !this.showTooltip;
      },
   },
};
</script>

<style lang="scss" scoped>
$tooltipArrowSize: 6px;
$seatSize: 42px;
.seat {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #d4fcd7;
   width: $seatSize;
   height: $seatSize;
   margin: 4px;
   border-radius: 0.75rem;
   color: #22ae2f;

   &.available {
   }
   &.occupied {
   }
   &.unavailable {
   }
   &.extra-space {
      // border-top: 5px solid #37c785;
      // height: 40px;
      margin-top: 20px;

      &::before {
         content: "XL";
         position: absolute;
         top: -19px;
         background: #e0e8f9;
         color: #9facba;
         padding: 0 0.5rem;
         border-radius: 0.25rem;
         font-size: 0.8rem;
         font-weight: 500;
      }
   }
   &.exit-seat {
      margin-top: 36px !important;
   }
   &.exit-badge {
      &::before {
         content: "";
         position: absolute;
         top: -30px;
         background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NiIgaGVpZ2h0PSIxOSIgdmlld0JveD0iMCAwIDU2IDE5Ij4KICA8cGF0aCBpZD0iUG9seWdvbl8xIiBkYXRhLW5hbWU9IlBvbHlnb24gMSIgZD0iTTQsMCw4LDhIMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMTQpIHJvdGF0ZSgtOTApIiBmaWxsPSIjMzM1NzhjIi8+CiAgPHBhdGggaWQ9IlBvbHlnb25fMyIgZGF0YS1uYW1lPSJQb2x5Z29uIDMiIGQ9Ik00LDAsOCw4SDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1NiA2KSByb3RhdGUoOTApIiBmaWxsPSIjMzM1NzhjIi8+CiAgPHRleHQgaWQ9IkVYSVQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzIDE1KSIgZmlsbD0iIzMzNTc4YyIgZm9udC1zaXplPSIxNCIgZm9udC1mYW1pbHk9IlJvYm90by1NZWRpdW0sIFJvYm90byIgZm9udC13ZWlnaHQ9IjUwMCIgbGV0dGVyLXNwYWNpbmc9IjAuMDQyZW0iPjx0c3BhbiB4PSIwIiB5PSIwIj5FWElUPC90c3Bhbj48L3RleHQ+Cjwvc3ZnPgo=");
         background-repeat: no-repeat;
         width: 56px;
         height: 19px;
         left: 130px;
         right: 0;
      }
   }

   &.hideSeat {
      visibility: hidden;
   }

   .seat-name {
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      justify-content: center;
      align-items: center;
      font-size: var(--small);
      font-weight: 500;
      cursor: pointer;
      &.selected {
         background-color: var(--primary);
         color: #fff;
         outline: 2.5px solid #3384ff63;
      }
      @keyframes blink {
         0% {
            transform: scale(1);
         }

         25% {
            transform: scale(2);
         }
      }

      &.IsThisSeatSelected {
         animation: blink 0.25s ease-in;
         z-index: 99;
         cursor: not-allowed;
      }
      &:hover {
         background-color: var(--accent-primary);
         color: #fff;
      }
   }
   .seat-unavailable {
      display: flex;
      width: 100%;
      height: 100%;
      background-color: #ebebeb;
      border-radius: 0.75rem;
      cursor: not-allowed;
      &::before,
      &::after {
         opacity: 1;
         content: "";
         width: 1.5px;
         height: 22px;
         display: block;
         border-radius: 0.5rem;
         background-color: #cecece;
         position: absolute;
         left: 0;
         right: 0;
         bottom: 0;
         top: 0;
         margin: auto;
      }

      &::before {
         transform: rotate(45deg);
      }
      &::after {
         transform: rotate(-45deg);
      }
   }
   .seat-tooltip-wrapper {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: #363636;
      z-index: 999;
      width: 152px;
      padding: 10px;
      border: 1px solid #fff;
      border-radius: 0.5rem;
      color: #fff;

      &.hasOwner {
         background-color: var(--accent-primary);
      }

      &.down {
         top: 100%;
         &::before {
            content: "";
            position: absolute;
            top: calc(-#{$tooltipArrowSize} * 2);
            left: 50%;
            transform: translateX(-50%) rotate(180deg);
            border: $tooltipArrowSize solid transparent;
            border-top-color: #363636;
         }
      }
      &.up {
         bottom: 100%;
         &::before {
            content: "";
            position: absolute;
            bottom: calc(-#{$tooltipArrowSize} * 2);
            left: 50%;
            transform: translateX(-50%) rotate(180deg);
            border: $tooltipArrowSize solid transparent;
            border-bottom-color: #363636;
         }
      }

      &.exit-seat {
         background-color: #fea775;
         color: #363636;
      }

      span {
         display: block;
         text-align: center;
         &.passenger-name {
         }
         &.seat-price {
            width: 100%;
            font-size: 1.25rem;
            font-weight: 600;
         }
         &.seat-name {
            font-size: 1rem;
         }
      }
      .seat-infos {
         padding-top: 1rem;
         margin-top: 1rem;
         border-top: 1px dashed #ffffff50;
         li {
            // padding-left: 24px;
            padding-bottom: 0.25rem;
            text-align: center;
            width: 100%;
            // font-size: var(--small);
         }
      }
      .seat-features {
         padding-top: 1rem;
         margin-top: 1rem;
         border-top: 1px dashed #ffffff50;
         li {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi41IiBoZWlnaHQ9IjIyLjUiIHZpZXdCb3g9IjAgMCAyMi41IDIyLjUiPgogIDxnIGlkPSJHcm91cF8xMjAyMSIgZGF0YS1uYW1lPSJHcm91cCAxMjAyMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM0My4yNSAtMTAxOS45OCkiPgogICAgPGNpcmNsZSBpZD0iRWxsaXBzZV8xMzc3IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTM3NyIgY3g9IjEwLjUiIGN5PSIxMC41IiByPSIxMC41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNjUgMTAyMC43Mykgcm90YXRlKDkwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgogICAgPGxpbmUgaWQ9IkxpbmVfMTEzMCIgZGF0YS1uYW1lPSJMaW5lIDExMzAiIHgxPSIyLjk2IiB5MT0iMS45MjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM1MC41NjYgMTAzMi42MTUpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KICAgIDxsaW5lIGlkPSJMaW5lXzEyNjgiIGRhdGEtbmFtZT0iTGluZSAxMjY4IiB4MT0iNC4yOTIiIHkyPSI2LjM1NiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzUzLjUyNiAxMDI4LjE4OCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIvPgogIDwvZz4KPC9zdmc+Cg==");
            background-size: 18px;
            min-height: 24px;
            padding-left: 24px;
            padding-bottom: 0.25rem;
            background-repeat: no-repeat;
            width: 100%;
            font-size: var(--small);
         }
      }
   }
}
</style>
