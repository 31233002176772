<template>
   <div class="seat-map-modal-container" :class="isLoaded ? '' : 'invisible'">
      <div class="seat-map-modal-wrapper">
         <SeatMapModalHeader :step="{ activeStep: step, totalStep: StepList.length }" :stepList="StepList" @gotoStep="handlerGotoStep" />

         <SeatMapModalBody :currency="currency" :step="{ activeStep: step, totalStep: StepList.length }" :legs="legs" :paymentChoiceDetails="paymentChoiceDetails" :result="result" />

         <SeatMapModalFooter :footerControls="footerControls" @skip="handlerNextStep" @previousStep="handlerPrevStep" v-if="step != 1" :step="{ activeStep: step, lastStep, firstStep }" />
      </div>
   </div>
</template>

<script>
import { flightService } from "@/service/index.js";
import SeatMapModalHeader from "./SeatMapModalHeader.vue";
import SeatMapModalBody from "./SeatMapModalBody.vue";
import SeatMapModalFooter from "./SeatMapModalFooter.vue";

import { paxMapper, paxIdsMapper, segmentIdForPaxes, segments, seatMapGenerator } from "./seatmapHelpers.js";

import { generateUuid } from "@/utils.js";
export default {
   name: "SeatMapModal",
   components: {
      SeatMapModalHeader,
      SeatMapModalBody,
      SeatMapModalFooter,
   },
   props: {
      currency: String,
      pnrId: Number,
      legIds: Array,
   },
   data() {
      return {
         isLoaded: false,
         step: Number,
         lastStep: Number,
         firstStep: Number,
         result: {},
         legs: [],
         paxes: {},
         segments: {},
         initSegments: [],
         searchSeatQuery: {
            uuid: null,
            pnrId: null,
            legIds: [],
            legId: null,
            paxIds: [],
         },
         paymentChoiceDetails: {},
      };
   },
   created() {
      this.handlerInitSeat();
   },

   computed: {
      StepList() {
         return this.$store.state.seatmap.stepList;
      },

      nameAbbrForMouse() {
         const nameAbbr = this.$store.state.seatmap.selectedPax.nameAbbr;
         const showAbbr = nameAbbr.length != 0 ? true : false;
         return {
            nameAbbr,
            showAbbr,
         };
      },
      footerControls() {
         return {
            skip: this.step != this.StepList.length - 1,
         };
      },
   },
   methods: {
      // handlerCloseBtn() {
      //    this.$parent.SeatMapModalStatus = false;
      //    console.log("handlerCloseBtn");
      // },
      handlerGotoStep(step) {
         this.step = step;
      },
      handlerNextStep() {
         if (this.lastStep == this.step) {
            this.handlerGetPaymentDetails();
         } else {
            this.step++;
         }
      },
      handlerPrevStep() {
         if (this.step == 0) {
            this.step = this.lastStep;
            return;
         }
         if (this.firstStep != this.step) {
            this.step--;
         }
      },
      handlerGetPaymentDetails() {
         const self = this;
         this.$store.commit("app/setIsProgressGifActive", true);
         let amount = 0;
         const query = this.$store.state.seatmap.selectSeatQ;
         flightService
            .ancillarySelectSeats(query)
            .then((res) => {
               amount = res.grandTotal;
            })
            .finally(() => {
               flightService
                  .getPaymentChoiceDetail({
                     amount,
                     currency: "EUR",
                  })
                  .then((res) => {
                     self.paymentChoiceDetails = res.paymentChoiceDetail;
                     this.$store.commit("seatmap/setResult", res.result);
                  })
                  .finally(() => {
                     this.$store.commit("app/setIsProgressGifActive", false);
                     this.step = 0;
                  });
            });
      },
      handlerInitSeat() {
         const query = {
            pnrId: this.pnrId,
         };

         flightService
            .ancillaryInitSeat(query)
            .then((res) => {
               this.paxes = paxMapper(res.paxes, this.$options.filters.EFltPaxType);
               this.searchSeatQuery.uuid = generateUuid();
               this.searchSeatQuery.pnrId = this.pnrId;
               this.searchSeatQuery.paxIds = paxIdsMapper(res.paxes);
               this.searchSeatQuery.legIds = res.segments.map((segment) => segment.flightId);
               this.searchSeatQuery.legId = this.searchSeatQuery.legIds[0];
               this.initSegments = res.segments;

               this.legs = res.legs.map((leg) => {
                  return {
                     ...leg,
                     enabled: false,
                     paxCount: res.paxes.length,
                  };
               });

               this.$store.commit("seatmap/setResult", res.result);
            })
            .then(() => {
               this.handlerSearchSeat();
            });
      },
      handlerSearchSeat() {
         this.segments = {};
         this.$store.commit("seatmap/resetSteps", true);

         let msg = this.$i18n.t(`${this.$options.name}_progressGif`);
         this.$store.commit("app/showProgressGifMsg", msg);
         this.stepList = [];

         this.$set(this.paymentChoiceDetails, {});

         flightService
            .ancillarySearchSeat(this.searchSeatQuery)
            .then((res) => {
               Object.keys(res.flights).forEach((flightKEy) => {
                  const flight = res.flights[flightKEy];

                  Object.keys(flight.segments).forEach((segKey) => {
                     const segment = flight.segments[segKey];
                     const rowObj = {
                        index: segment.index,
                        id: segment.id,
                        flightKey: flightKEy,
                        rows: seatMapGenerator(segment.rows, segment.id, this.$options.filters.EFltSeatLayout(segment.seatLayout)),
                        flightNumber: segment.flightNumber,
                        departureAirport: segment.departureAirport,
                        arrivalAirport: segment.arrivalAirport,
                        paxes: segmentIdForPaxes(this.paxes, segment.id),
                        selectedSeats: {},
                     };
                     this.$store.dispatch("seatmap/updateSegments", rowObj);
                  });
               });

               this.$store.commit("seatmap/selectSeatQSearchUui", res.searchUuid);
               this.handlerStepGenerator(segments(res.flights));

               this.initSegments.forEach((segment) => {
                  Object.keys(segment.paxesSeats).forEach((paxSeat) => {
                     console.log("paxesSeats");
                     this.$store.commit("seatmap/setPaxSeat", {
                        paxId: paxSeat,
                        seat: segment.paxesSeats[paxSeat],
                        segmentId: segment.segmentId,
                     });
                  });
               });

               this.$store.commit("seatmap/setResult", res.result);
            })
            .finally(() => {
               this.$store.commit("app/setIsProgressGifActive", false);
               this.isLoaded = true;
            });
      },

      handlerStepGenerator(segments) {
         const self = this;
         Object.keys(segments).forEach((key, index) => {
            const segment = segments[key];
            this.$store.commit("seatmap/pushStep", segment);
            this.lastStep = segment.index;
            if (index == 0) {
               self.firstStep = segment.index;
            }
         });

         // this.$store.commit("seatmap/pushStep", { name: "Payment", index: this.StepList[this.StepList.length - 1].index + 1, status: false });
         this.$store.commit("seatmap/pushStep", { name: this.$i18n.t("ancillarySeatMap_lblPayment"), index: 0, status: false });
         this.$store.commit("seatmap/pushStep", { name: this.$i18n.t("ancillarySeatMap_lblResult"), index: 1, status: false });
      },

      handlerSeatControls() {
         function areAllSeatsAssigned(passengers) {
            for (let key in passengers) {
               if (passengers[key].seat === null && passengers[key].paxTypeEnum != 3) {
                  return false;
               }
            }
            return true;
         }

         const segments = this.$store.state.seatmap.segments;
         Object.keys(segments).forEach((segment) => {
            const paxes = segments[segment].paxes;
            if (areAllSeatsAssigned(paxes)) {
               this.$store.commit("seatmap/setStep", { index: Number(segment), status: true });
            } else {
               this.$store.commit("seatmap/setStep", { index: Number(segment), status: false });
            }
         });

         const data = this.$store.state.seatmap.stepList;
         const firstStepWithFalseStatus = data.find((item) => item.status === false)?.index;
         console.log("firstStepWithFalseStatus", firstStepWithFalseStatus);
         this.step = firstStepWithFalseStatus;
      },
   },
   watch: {
      "$store.state.seatmap.isModalInitialized"() {
         this.handlerSeatControls();
      },
      firstStep: {
         handler: function (val) {
            this.step = val;
         },
      },
      "$store.state.seatmap.result"(result) {
         this.result = result;
         if (!result.success) {
            this.step = 1;
         }
         if (this.step == 0 && result.success && result.subCode == "OK") {
            this.step = 1;
         }
      },
      deep: true,
   },
};
</script>

<style lang="scss" scoped>
#circle {
   width: 36px;
   height: 36px;
   border-radius: 50%;
   background-color: var(--accent-primary);
   color: #fff; /* White text */
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   pointer-events: none; /* Makes the circle ignore mouse events */
   font-size: 16px;
   z-index: 999;
}
.seat-map-modal-container {
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 100;
   background-color: #0000006a;
   @media only screen and (max-width: 768px) {
      background-color: #ffffff;
   }
   overflow: auto;

   .seat-map-modal-wrapper {
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
      border-radius: 1rem;
      width: 1100px;
      // max-height: 90vh;
      height: fit-content;
      min-height: 850px;
      height: 850px;
      padding: 1.5rem 2.5rem;
      // opacity: 0;
      // transform: translate(0px, 100px);
      position: relative;
      // overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media only screen and (max-width: 768px) {
         min-height: 100vh;
         height: auto;
         border-radius: 0;
         padding: 1.5rem 0.5rem;
      }

      ::v-deep {
         .seat-map-modal-header,
         .seat-map-modal-body,
         .seat-map-modal-footer {
            margin: 0.25rem 0;
            // padding: 1rem;
            // border: 4px solid #efefef;
            // border-radius: 6px;
         }

         .seat-map-modal-header {
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 3px solid #efefef;
         }
         .seat-map-modal-footer {
            margin: 1rem 0;
            padding-top: 1rem;
            border-top: 3px solid #efefef;
         }
      }
   }
}
</style>
