import { PRIVATE_HTTP } from "./index";

const LIST_INVOICE = "api/finance/listinvoices";
const SET_INVOICE_NOTE = "api/finance/setinvoicenote";
const UPDATE_BALANCE = "api/finance/updatebalance";
const LIST_ACCOUNT_TRANSACTIONS = "api/report/accounttrxreport";

export var listInvoices = (request) => {
   const promise = PRIVATE_HTTP().post(LIST_INVOICE, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var setInvoiceNote = (request) => {
   const promise = PRIVATE_HTTP().post(SET_INVOICE_NOTE, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var listTransactions = (request) => {
   const promise = PRIVATE_HTTP().post(LIST_ACCOUNT_TRANSACTIONS, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var updateBalance = (request) => {
   const promise = PRIVATE_HTTP().post(UPDATE_BALANCE, request);
   return promise.then((response) => {
      return response.data;
   });
};
