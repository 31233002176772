<template>
   <section class="hotel-order-detail-section voucher-information-wrapper">
      <div class="voucher-information-inner">
         <GtsInfoWidget
            v-if="voucherInformation.status == 15 && voucherInformation.isPartialPaid"
            icon="gts-icon icon-partial-payment-shadow"
            :title="$t('orderDetail_partialPaymentRemainingTitle')"
            :htmlContent="replacePartialPaymentRemainingText">
            <template #event-content>
               <button class="hotel-partial-payment-route" @click="scrollToBottom">{{ $t("orderDetail_partialPaymentRemainingPayment") }}</button>
            </template>
         </GtsInfoWidget>

         <div class="voucher-information-header-wrapper">
            <span class="section-title">{{ $t("orderDetail_lblAccommodationInfoTitle") }}</span>

            <div class="pdf-action-buttons-wrapper">
               <template v-if="voucherInformation.status == 3">
                  <button
                     id="document-voucher"
                     :class="voucherLoading ? 'loading' : ''"
                     @click="handlerDocumentViewer(5)"
                     v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
                     <span>{{ $t("orderDetail_btnVoucher") }}</span>
                     <span class="g-radial-loader" v-if="voucherLoading"></span>
                     <i class="gts-icon icon-eticket" v-else></i>
                  </button>
               </template>
               <template v-if="voucherInformation.status == 15">
                  <button
                     id="document-voucher"
                     :class="voucherLoading ? 'loading' : ''"
                     @click="handlerDocumentViewer(5)"
                     v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
                     <span>{{ $t("orderDetail_btnVoucher") }}</span>
                     <span class="g-radial-loader" v-if="voucherLoading"></span>
                     <i class="gts-icon icon-eticket" v-else></i>
                  </button>
               </template>

               <template v-if="voucherInformation.status == 2">
                  <button
                     id="document-voucher"
                     :class="voucherLoading ? 'loading' : ''"
                     @click="handlerDocumentViewer(5)"
                     v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
                     <span>{{ $t("orderDetail_btnVoucher") }}</span>
                     <span class="g-radial-loader" v-if="voucherLoading"></span>
                     <i class="gts-icon icon-eticket" v-else></i>
                  </button>
               </template>
            </div>
         </div>
         <div class="hotel-order-detail-section-content">
            <VoucherAndRoomInformationSummary
               :voucherSummary="vouchers"
               :partialPaymentSummeryData="partialPaymentPrices"
               :cancellationPolicies="cancellationPoliciesInformation"
               :packages="packages" />
            <GtsInfoWidgetWBorder
               v-if="voucherInformation.status == 2 && voucherInformation.isPartialPaid"
               class="WBorder-Widget"
               icon="gts-icon icon-partial-payment-shadow"
               :title="$t('orderDetail_PartialPaymentDeadLineTitle')"
               :text="formattedFirstPaymentDate"
               :ishtmlContent="true"
               :contentBody="replacePartialPaymentDeadLineBody">
            </GtsInfoWidgetWBorder>
            <GtsInfoWidgetWBorder
               v-if="(voucherInformation.status == 2 || voucherInformation.status == 15) && voucherInformation.isPartialPaid"
               class="WBorder-Widget-2nd"
               icon="gts-icon icon-partial-payment-shadow"
               :title="$t('orderDetail_PartialCancellationPolicy')"
               :ishtmlContent="true"
               :contentBody="replaceCancellationPolicies">
            </GtsInfoWidgetWBorder>
            <CancellationPolicies
               v-if="(voucherInformation.status == 2 || voucherInformation.status == 15) && voucherInformation.isPartialPaid ? false : true"
               className="hotel-order-detail-inner-section"
               :cancellationDate="vouchers.lastVoidDateTime"
               :policies="cancellationPolicies"
               :packages="packages" />
            <VoucherAndRoomInformationRoomType :roomTypeSummary="rooms" :packages="packages" />
         </div>
      </div>
   </section>
</template>

<script>
import VoucherAndRoomInformationSummary from "@/components/structural/hotel/order-detail/VoucherAndRoomInformationSummary.vue";
import CancellationPolicies from "@/components/structural/hotel/common/CancellationPolicies.vue";
import VoucherAndRoomInformationRoomType from "@/components/structural/hotel/order-detail/VoucherAndRoomInformationRoomType.vue";
import GtsInfoWidget from "@/components/utils/GtsInfoWidget.vue";
import GtsInfoWidgetWBorder from "@/components/utils/GtsInfoWidgetWBorder.vue";
import moment from "moment";
export default {
   name: "VoucherAndRoomInformation",
   components: {
      GtsInfoWidget,
      VoucherAndRoomInformationSummary,
      CancellationPolicies,
      VoucherAndRoomInformationRoomType,
      GtsInfoWidgetWBorder,
   },
   props: {
      packages: Object,
      voucherInformation: { type: Object },
      cancellationPoliciesInformation: { type: Array },
      roomsInformation: { type: Array },
      partialPaymentPrices: {},
   },
   data() {
      return {
         voucherLoading: false,
         vouchers: {},
         cancellationPolicies: [],
         rooms: [],
      };
   },
   computed: {
      replacePartialPaymentDeadLineBody() {
         var firstDepositPriceText = this.$options.filters.price(this.partialPaymentPrices[0].amount, this.partialPaymentPrices[1].currency);
         var firstDatePriceTextBold = "<span style='font-weight: 600;'>" + firstDepositPriceText + "</span>";
         return this.$t("orderDetail_PartialPaymentDeadLineBody").replace("#VALUE#", firstDatePriceTextBold);
      },
      formattedFirstPaymentDate() {
         return moment(this.voucherInformation.lastBookingDateTime).format("DD.MM.YYYY HH:mm");
      },
      formattedSecondPaymentDate() {
         return moment(this.partialPaymentPrices[1].dueDate).format("DD.MM.YYYY HH:mm");
      },
      replacePartialPaymentRemainingText() {
         var priceText =
            "<span style='font-weight: 600;'>" +
            this.$options.filters.price(this.partialPaymentPrices[1].amount, this.partialPaymentPrices[1].currency) +
            "</span>";
         var dateText = "<span style='font-weight: 600;'>" + moment(this.partialPaymentPrices[1].dueDate).format("DD.MM.YYYY HH:mm") + "</span>";
         return this.$t("orderDetail_partialPaymentRemainingText").replace("#VALUE#", priceText).replace("#VALUE1#", dateText);
      },
      replaceCancellationPolicies() {
         var secondLine = `<li style="margin-bottom: 8px;">${this.$t("orderDetail_PartialCancellationContentText2").replace(
            "#VALUE#",
            `<span style='font-weight: 600;'> ${this.formattedFirstPaymentDate}</span>`
         )}</li>`;

         // var fourthLine = `<li style="margin-bottom: 8px;">${this.$t("orderDetail_PartialCancellationContentText4")
         //    .replace(
         //       "#VALUE#",
         //       `<span style='font-weight: 600;'> ${this.$options.filters.price(
         //          this.partialPaymentPrices[1].amount,
         //          this.partialPaymentPrices[1].currency
         //       )}</span>`
         //    )
         //    .replace("#VALUE1#", `<span style='font-weight: 600;'> ${this.formattedSecondPaymentDate} </span>`)}</li>`;

         var mainText = `<div style='display:flex; flex-direction: column;'> <ul>${secondLine}</ul></div>`;

         return mainText;
      },
   },
   created() {
      const self = this;

      self.vouchers = this.voucherInformation;
      self.cancellationPolicies = this.cancellationPoliciesInformation;
      self.rooms = this.roomsInformation;
   },
   methods: {
      scrollToBottom() {
         let targetElement = document.querySelector(".order-detail-payment-inner");
         let elementTop = targetElement.getBoundingClientRect().top + window.scrollY;

         let viewportHeight = window.innerHeight;
         let elementHeight = targetElement.offsetHeight;
         let targetPosition = elementTop - viewportHeight / 2 + elementHeight / 2;

         window.scrollTo(0, targetPosition);
      },
      handlerDocumentViewer(type) {
         const self = this;
         self.voucherLoading = true;
         self.$store.state.app.documentViewer.request.showPrice = false;
         self.$store.state.app.documentViewer.hasPrice = true;
         self.$store.state.app.documentViewer.request.documentType = type;
         self.$store.state.app.documentViewer.request.fileType = 2;
         self.$store.state.app.documentViewer.activeProductStatus = self.voucherInformation.status;
         self.$store.commit("app/setDocumentViewer", true);
      },
   },
   watch: {
      "$store.state.app.documentViewer.isLoaded": {
         handler: function (status) {
            if (status) {
               this.voucherLoading = false;
            }
         },
      },
      voucherInformation: {
         handler: function (newVoucherInformation) {
            const self = this;

            self.vouchers = newVoucherInformation;
         },
         deep: true,
      },
      cancellationPoliciesInformation: {
         handler: function (newCancellationPoliciesInformation) {
            const self = this;

            self.cancellationPolicies = newCancellationPoliciesInformation;
         },
         deep: true,
      },
      roomsInformation: {
         handler: function (newRoomsInformation) {
            const self = this;

            self.rooms = newRoomsInformation;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.voucher-information-wrapper {
   .voucher-information-inner {
      .hotel-order-detail-section-title {
         margin-bottom: 24px;
         & > h4 {
            font-weight: 700;
         }
      }
      .hotel-order-detail-section-content {
         .WBorder-Widget {
            margin-bottom: 1.5rem;
         }
         .WBorder-Widget-2nd {
            margin-bottom: 3rem;
         }
      }
      .hotel-partial-payment-route {
         background-color: #0acc8b;
         border: none;
         border-radius: 50px;
         color: white;
         padding: 10px 20px;
         font-size: 16px;
         cursor: pointer;
         box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
         transition: background-color 0.3s ease;
         margin: auto 1rem;
         height: fit-content;
      }

      .hotel-partial-payment-route:hover {
         background-color: #2ddba1;
      }

      .hotel-partial-payment-route:active {
         background-color: #0acc8b;
      }
   }
}
.voucher-information-header-wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 1.5rem;

   .section-title {
      margin-bottom: 0 !important;
   }

   .pdf-action-buttons-wrapper {
      display: flex;

      button {
         margin: 0 0.5rem;
         height: 44px;
         display: flex;
         align-items: center;
         border-radius: 24rem;
         padding-left: 1.75rem;
         padding-right: 1.125rem;
         font-size: var(--large);
         font-weight: 600;
         transform: scale(1);
         transition-property: transform;
         transition-timing-function: ease-in;
         transition-duration: 0.05s;

         i {
            margin-left: 1rem;
         }

         &:last-child {
            margin-right: 0 !important;
         }

         &#document-voucher {
            background-color: #fff;
            border: 2px solid var(--primary);
            color: var(--primary);

            &:hover {
               background-color: var(--primary);
               border: 2px solid var(--primary);
               color: #fff;

               i {
                  filter: brightness(10);
               }
            }
         }

         &#document-booking-confirmation {
            background-color: var(--success);
            color: #2d4c77;
         }

         &:hover {
            transform: scale(1.1);
         }

         &.loading {
            background-color: var(--accent-primary) !important;
            border: 2px solid var(--accent-primary) !important;
            transform: scale(1.1);
            color: #fff !important;

            .g-radial-loader {
               margin-left: 1rem;
            }
         }
      }
   }
}
</style>
