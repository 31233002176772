<template>
   <div class="cc-phone-wrapper" :class="isFlightPage ? 'flight-page' : ''">
      <!-- CALL CENTER PHONE -->
      <div class="cc-phone-inner">
         <template v-if="$constants.brand.callcenter">
            <span>
               <i class="gts-icon icon-aside-tasklist"></i>
               <a :href="'tel:' + $options.filters.phone($constants.brand.callcenter)"> {{ $constants.brand.callcenter | phone }}</a>
            </span>
         </template>
      </div>

      <!-- WHATSAPP -->
      <div class="cc-whatsapp-wrapper" @click="handlerWhatsappMessage" v-if="$constants.brand.cssCode != 'mefly' && false">
         <i class="gts-icon icon-whatsapp"></i>
      </div>
   </div>
</template>

<script>
export default {
   name: "PhoneInformations",
   data() {
      return {
         isFlightPage: this.$route.path == "/flight",
      };
   },

   methods: {
      handlerWhatsappMessage() {
         if (this.$store.state.app.clientInformations.isMobile) {
            window.open(`https://wa.me/+494087969988`);
         } else {
            window.open(`https://web.whatsapp.com/send/?phone=%2B494087969988&text&type=phone_number&app_absent=0`);
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.cc-phone-wrapper {
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   border-right: 1px solid #ccc;
   display: flex;
   margin: 0 1rem;
   padding: 0 1rem;

   &.flight-page {
      @media (max-width: 576px) {
         display: none;
      }
   }

   .cc-phone-inner {
      display: none;
      align-items: center;
      padding: 8px 16px;
      background: var(--c-gray-on-white);
      border-radius: 12rem;

      @media (min-width: 992px) {
         display: flex;
      }

      span {
         display: flex;
         align-items: center;
         i {
            width: 26px !important;
            height: 26px !important;
            margin-right: 8px;
         }
         a {
            font-size: 18px;
            font-weight: 500;
            color: var(--c-gray-on-white-txt);
            text-decoration: none;
         }
      }
   }
   .cc-whatsapp-wrapper {
      display: flex;
      cursor: pointer;
      margin-left: 1.5rem;

      i {
         $iconSize: 32px !important;
         width: $iconSize;
         height: $iconSize;

         @media (min-width: 776px) {
            $iconSize: 32px !important;
            width: $iconSize;
            height: $iconSize;

            display: flex;
         }
      }
   }
}
</style>
