<template>
   <div class="gts-grid-container">
      <div class="g-table-header">
         <div class="g-table-header-left">
            <UISelectBox id="report-type" :options="pagination.perPageOptions" v-model="pagination.perPage" />
            <button class="gts-animation" @click="triggerExportPrint">
               <i class="gts-icon icon-print"></i>
            </button>
            <button class="gts-animation" @click="triggerExportExcel">
               <i class="gts-icon icon-excel"></i>
            </button>
         </div>
         <div class="g-table-header-right">
            <UIInput v-model="search" :placeholder="$t('common_search')" @input="replaceComma" allow-clear />
         </div>
      </div>

      <div class="gts-table-container">
         <table class="g-table" ref="print">
            <thead>
               <tr>
                  <th v-for="(column, index) in tableOptions.columns" :key="index">
                     <span @click="handlerSort(column.field)"> {{ column.label }}</span>
                  </th>
               </tr>
            </thead>
            <tbody>
               <tr v-for="(row, index) in Rows" :key="index">
                  <template v-for="(rowCol, rowColKey, rowColIndex) in row">
                     <td :key="rowColIndex" v-if="rowColKey == 'tags' ? false : true">
                        <template v-if="typeof rowCol == 'object' && rowCol != null">
                           <span
                              v-for="(objIteration, objIterationIndex) in rowCol.iteration"
                              :key="objIterationIndex"
                              class="g-table-iteration-item"
                              :class="rowCol.className == 'badge' ? `gts-badge ${rowCol.filter}-${objIteration}` : rowCol.className"
                              @click="rowCol.router ? handlerRouter(rowCol.router) : ''">
                              <!-- gts-badge EFltPnrStatus-3 -->
                              <template v-if="typeof rowCol.filter === 'string'">
                                 {{ $options.filters[rowCol.filter](objIteration) }}
                              </template>
                              <template v-else>
                                 <template v-if="rowCol.details">
                                    {{ objIteration }}
                                    <div class="g-table-item-details">
                                       {{ rowCol.details[objIterationIndex] }}
                                    </div>
                                 </template>
                                 <template v-else-if="rowCol.isInput">
                                    <UIInput v-model="rowCol.iteration[0]" :placeholder="$t('common_writeANote')" />
                                    <span v-show="false">{{ rowCol.iteration[0] }}</span>
                                    <UIButton
                                       style="width: 2rem; margin-left: 0.25rem"
                                       className="default"
                                       :text="$t('common_save')"
                                       @click.native="setAgencyNote(rowCol)" />
                                 </template>
                                 <template v-else-if="rowCol.icon">
                                    <div class="icon-with-action gts-animation-scale">
                                       <i class="gts-icon" :class="'icon-' + rowCol.icon" @click="rowCol.method()"></i>
                                    </div>
                                 </template>
                                 <template v-else> {{ objIteration }}</template>
                                 <!-- -->
                              </template>
                           </span>
                        </template>
                        <template v-else>
                           <span v-if="rowColKey == 'tags' ? false : true"> {{ rowCol }}</span>
                        </template>
                     </td>
                  </template>
               </tr>
            </tbody>
         </table>
      </div>

      <div class="g-table-footer">
         <div class="g-table-page-info-wrapper">
            <span> {{ RowCount + " " + $t("gtsGrid_totalItem") }}</span>
         </div>
         <div class="g-table-pagination-wrapper" v-show="search.length == 0">
            <button @click="handlerPrev">{{ $t("gtsGrid_btnPrevPage") }}</button>
            <ul class="g-table-pages">
               <li
                  class="g-table-page"
                  v-for="(page, index) in pagination.pages"
                  :key="index"
                  @click="handlePagination(index)"
                  :class="pagination.activepage == index + 1 ? 'active' : ''">
                  {{ page }}
               </li>
            </ul>
            <button @click="handlerNext">{{ $t("gtsGrid_btnNextPage") }}</button>
         </div>
      </div>
   </div>
</template>

<script>
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import gtsGridPdfStyle from "@/assets/css/gtsGridPdf.js";
import Template from "@/assets/css/gtsDataTablePrintTemplates.js";
import moment from "moment";
import Popup from "@/gts-popup.js";
import UIButton from "@/components/unitary/Button.vue";

function cleanTableElements(parentItem) {
   if (!parentItem) return parentItem;

   const elements = parentItem.querySelectorAll("td i,a,button,span");

   if (!elements || elements.length == 0) return parentItem;

   elements.forEach((element) => {
      element = cleanTableElements(element);

      if (element.parentElement) element.parentElement.innerHTML = element.innerHTML;
   });

   return parentItem;
}

export default {
   name: "GtsGrid",
   props: {
      tableOptions: Object,
      itemPerPage: {
         type: Number,
      },
      title: String,
      exportItemDetailsToExcel: Boolean,
   },
   components: {
      UIButton,
      UISelectBox,
      UIInput,
   },
   data() {
      return {
         search: "",
         pagination: {
            activepage: 1,
            enabled: true,
            perPage: 50,
            perPageOptions: [
               { name: `20 ${this.$i18n.t(`gtsGrid_item`)}`, value: 20 },
               { name: `40 ${this.$i18n.t(`gtsGrid_item`)}`, value: 40 },
               { name: `60 ${this.$i18n.t(`gtsGrid_item`)}`, value: 60 },
               { name: `80 ${this.$i18n.t(`gtsGrid_item`)}`, value: 80 },
               { name: `${this.$i18n.t(`gtsGrid_showAll`)}`, value: 0 },
            ],
            pages: [],
         },
      };
   },
   created() {},
   computed: {
      Search() {
         const self = this;
         return self.tableOptions.rows.filter((row) => JSON.stringify(row).toLowerCase().includes(self.search.toLowerCase()));
      },
      Rows() {
         const self = this;
         self.Pagination;
         let sliceFrom = self.pagination.activepage - 1;
         let sliceTo = self.pagination.activepage;
         let perPage = self.pagination.perPage;
         return self.Search.slice(perPage * sliceFrom, perPage * sliceTo);
      },

      Pagination() {
         const self = this;
         const count = self.Search.length;
         const totalPage = Math.ceil(count / self.pagination.perPage);
         if (totalPage == 0) {
            self.pagination.enabled = false;
         } else {
            self.pagination.pages.length = 0;
            for (let i = 1; i <= totalPage; i++) {
               self.pagination.pages.push(i);
            }
         }
         return self.pagination;
      },
      RowCount() {
         return this.Search.length;
      },
   },
   methods: {
      setAgencyNote(rowObj) {
         let note = rowObj.iteration[0]?.toUpperCase();
         if (note.length > 100) {
            note = note.slice(0, 100);
            Popup.warning(this.$i18n.t("Popup_lblMaxCharLength"), this.$i18n.t("Popup_txtMaxCharLength"), undefined, 5000);
            return;
         }
         const invoiceObj = {
            id: rowObj.invoiceId,
            note: rowObj.iteration[0]?.toUpperCase(),
         };
         this.$emit("setInvoiceNote", invoiceObj);
      },
      handlerRouter(routerId) {
         window.open(routerId);
      },
      triggerExportPrint() {
         const divToPrint = this.$refs.print;
         const newPrintWindow = window.open("", "Print");
         newPrintWindow.document.write(`<html><head><style>${gtsGridPdfStyle}</style></head><body>${divToPrint.outerHTML}</body></html>`);
         newPrintWindow.print();
         newPrintWindow.close();
      },
      triggerExportExcel() {
         const self = this;
         const divToPrint = this.$refs.print;
         const tableType = divToPrint.dataset.tabletype;
         const tableTitle = self.title;
         const exportItemDetailsToExcel = self.exportItemDetailsToExcel;

         let styleContent = "";

         switch (tableType) {
            case "INVOICE":
               styleContent = Template.getInvoiceExcelTemplate();
               break;

            default:
               styleContent = "";
               break;
         }

         //const vgtTableWrapper = divToPrint.querySelector("table#vgt-table");
         const vgtTableWrapper = divToPrint;
         let cloneTable = vgtTableWrapper.cloneNode(true);

         if (!exportItemDetailsToExcel) {
            const gTableItemDetails = cloneTable.querySelectorAll("td .g-table-item-details");

            gTableItemDetails.forEach((detailDiv) => {
               detailDiv.remove();
            });
         }

         cloneTable = cleanTableElements(cloneTable);

         let excelContent = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">`;
         excelContent += `<head>`;
         excelContent += `<!--[if gte mso 9]>`;
         excelContent += `<xml>`;
         excelContent += `<x:ExcelWorkbook>`;
         excelContent += `<x:ExcelWorksheets>`;
         excelContent += `<x:ExcelWorksheet>`;
         excelContent += `<x:Name>${tableTitle}</x:Name>`;
         excelContent += `<x:WorksheetOptions>`;
         excelContent += `<x:DisplayGridlines/>`;
         excelContent += `</x:WorksheetOptions>`;
         excelContent += `</x:ExcelWorksheet>`;
         excelContent += `</x:ExcelWorksheets>`;
         excelContent += `</x:ExcelWorkbook>`;
         excelContent += `</xml>`;
         excelContent += `<![endif]-->`;
         excelContent += `<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>`;
         excelContent += `<style>${styleContent}</style>`;
         excelContent += `</head>`;
         excelContent += `<body>`;
         excelContent += cloneTable.outerHTML;
         excelContent += `</body>`;
         excelContent += `</html>`;

         let csvContent = "data:application/vnd.ms-excel," + excelContent;

         var encodedUri = encodeURI(csvContent);
         var link = document.createElement("a");
         link.setAttribute("href", encodedUri);
         link.setAttribute("download", `${tableTitle} - ${moment().format("DD.MM.YYYY")}.xls`);
         document.body.appendChild(link);
         link.click();
      },
      handlerPrev() {
         const self = this;
         if (self.pagination.activepage != 1) {
            self.pagination.activepage--;
         }
      },
      handlerNext() {
         const self = this;
         if (self.pagination.activepage != self.pagination.pages.length) {
            self.pagination.activepage++;
         }
      },
      handlePagination(page) {
         const self = this;
         self.pagination.activepage = page + 1;
      },
      handlerSort(filterBy) {
         this.tableOptions.rows.sort((a, b) => {
            if (a[filterBy] > b[filterBy]) {
               return -1;
            } else {
               return 0;
            }
         });
      },
      replaceComma: function () {
         this.search = this.search.replace(/,/g, ".");
      },
   },
   watch: {
      itemPerPage: {
         handler: function (val) {
            val;
            // if (val == -1) {
            //     this.pagination.perPage = this.tableOptions.rows.length;
            // }
         },
         immediate: true,
      },
      "tableOptions.rows": {
         handler: function (rows) {
            if (rows.length > 0) {
               this.pagination.perPageOptions[this.pagination.perPageOptions.length - 1].value = rows.length;
               if (this.itemPerPage == -1) {
                  this.pagination.perPage = this.pagination.perPageOptions[this.pagination.perPageOptions.length - 1].value;
               }
            }
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.gts-grid-container {
   width: 100%;

   .gts-grid-title {
      font-size: var(--xlarge);
      font-weight: 600;
      color: var(--primary);
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;
      display: block;
      border-bottom: 1px solid #eee;
   }
   .g-table-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .g-table-header-left,
      .g-table-header-right {
         display: flex;

         .form-item {
            margin-right: 1rem;
         }
      }

      button {
         background: transparent !important;
      }
   }
   .gts-table-container {
      display: block;
      overflow-x: auto;

      .g-table {
         width: 100%;

         thead {
            background-color: #f2f2f2;
            border-bottom: 2px solid #ccc;
            th {
               padding: 1.25rem 0.5rem;
               color: var(--txt-black);
               font-weight: 600;
               font-size: var(--normal);
               vertical-align: middle;
               white-space: nowrap;
               color: var(--primary);
               border: 1px solid #ccc;
               text-align: center;
            }
         }
         tbody {
            tr {
               border-bottom: 1px solid #eee;
               td {
                  vertical-align: middle;
                  padding: 0.5rem 1rem;
                  font-size: 0.95rem;
                  border: 1px solid #eee;
                  color: #000;
                  span {
                     position: relative;
                     display: flex;
                     &.g-table-iteration-item {
                        margin: 0.5rem 0.25rem;

                        .g-table-item-details {
                           display: none;
                           position: absolute;
                           left: 0;
                           right: 0;
                           margin: auto;
                           width: 280px;
                           background: #dddddd;
                           border: 1px solid #eee;
                           z-index: 1;
                           border-radius: 0.5rem;
                           padding: 1rem 0.75rem;
                           box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                        }

                        &.gts-badge {
                           white-space: nowrap;
                        }

                        ::v-deep {
                           .g-input-container {
                              width: 100%;
                           }
                        }
                     }
                     &.is-price {
                        text-align: right;
                        font-weight: 600;
                     }
                     &.order-router {
                        background: #fff;
                        border: 1px solid #ccc;
                        padding: 0.25rem 0.5rem;
                        border-radius: 0.5rem;
                        text-align: center;
                        color: var(--primary) !important;
                        font-weight: 600;
                        justify-content: center;
                        cursor: pointer;

                        &:hover {
                           background: var(--primary);
                           color: #fff !important;

                           a {
                              color: #fff !important;
                           }
                        }

                        a {
                           color: inherit !important;
                        }
                     }

                     &:hover {
                        .g-table-item-details {
                           display: block;
                        }
                     }
                  }
                  .icon-with-action {
                     cursor: pointer;
                     display: inline-block;
                  }
               }

               &:nth-child(even) {
                  background-color: #fafafa;
               }
            }
         }
      }
   }
   .g-table-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      .g-table-page-info-wrapper {
         font-size: var(--large);
         font-weight: 500;
      }

      .g-table-pagination-wrapper {
         display: flex;
         .g-table-pages {
            display: flex;
            margin: 0 0.5rem;

            .g-table-page {
               border: 1px solid #ccc;
               cursor: pointer;
               border-radius: 0.35rem;
               color: var(--txt-ghost);
               min-width: 32px;
               text-align: center;
               display: flex;
               justify-content: center;
               align-items: center;
               font-weight: 500;
               margin: 0 0.125rem;

               &.active {
                  background: #7f7f7f;
                  color: #fff;
                  transform: scale(1.15);
               }
            }
         }

         button {
            background-color: transparent !important;
            border: 1px solid #ccc;
            padding: 0.125rem 1rem;
            border-radius: 0.35rem;
            color: var(--txt-ghost);
            font-weight: 500;
         }
      }
   }
}
</style>
