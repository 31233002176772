export const EOdrOrderStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NEW",
   2: "RESERVED",
   3: "ISSUED",
   4: "CANCELED",
   5: "VOIDED",
   6: "REFUNDED",
   7: "REISSUED",
   8: "ERROR",
   9: "OPEN",
};

export const EOdrEntityType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "PAYMENT",
   2: "PNR",
   3: "HOTEL",
   4: "TRANSFER",
   5: "INSURANCE",
   6: "INVOICE",
};

export const EOdrTrxType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "CHECKOUT",
   2: "RESERVE",
   3: "TICKET",
   4: "REVERSE",
   5: "LEAVE",
   6: "INQUIRY",
};
export const EOdrType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "FLIGHT",
   2: "HOTEL",
   3: "TRANSFER",
   4: "RENTACAR",
   5: "PACKAGE",
};
