<template>
   <div id="ancillaries-modal-container" class="reveal-fade" :class="settings.type == 7 ? 'wheelchair' : ''">
      <div class="ancillaries-modal-wrapper" :class="AncillariesModalClass" ref="ancillaryModal">
         <!-- Ancillaries Modal Header -->
         <section id="ancillaries-modal-header" class="reveal-bottom" v-if="sections.header">
            <div id="close-ancillaries-modal-wrapper" :class="hideCloseBtn ? 'invisible' : ''" @click="handlerAncillariesModalClose('refresh')">
               <div id="close-ancillaries-modal"></div>
            </div>
            <!-- Breadcrumb -->
            <div id="ancillaries-breadcrumb-wrapper">
               <ul>
                  <li v-for="(step, index) in settings.steps" :key="index">
                     <span :class="{ activeStep: index === activeStep }">{{ $t(step.name) }}</span>
                  </li>
               </ul>
            </div>
         </section>

         <!-- Ancillaries Modal Body -->
         <section id="ancillaries-modal-body" v-if="sections.body" :class="{ 'centered-body': activeStep === 1 }">
            <template v-if="settings.type === 1">
               <!-- Baggage -->
               <template v-if="activeStep === 0">
                  <AncillaryFlightBaggage :currency="currency" :paxes="settings.paxes" v-if="settings.type === 1" ref="ancillaryFlightBaggage" />
               </template>
               <template v-if="activeStep === 1">
                  <PaymentPurchaseWidget entityType="flight_baggage" :paymentChoiceDetail="paymentChoiceDetail" no-title headless />
               </template>
               <AncillaryResult v-if="isResultAllowed" :result="result" />
            </template>

            <template v-if="settings.type === 5">
               <template v-if="activeStep === 0">
                  <AncillaryReissueSelection :flightsObj="settings.flights" :availableType="settings.availableType" ref="reissueReqModel" @allow-search="handlerAllowReissueSearch" />
               </template>
               <template v-if="activeStep === 1">
                  <AncillaryReissueSearch
                     :flights="settings.flights"
                     :legs="reissue.legs"
                     :airports="reissue.airports"
                     @select-flight="handlerFlightSelection"
                     @allow-payment-step="handlerReissuePaymentStep"
                     :search-uuid="reissue.searchUuid" />
               </template>
               <template v-if="activeStep === 2">
                  <PaymentPurchaseWidget entityType="flight_reissue" :paymentChoiceDetail="paymentChoiceDetail" no-title headless />
               </template>
               <AncillaryResult v-if="isResultAllowed" :result="result" />
            </template>

            <template v-if="settings.type === 3">
               <template v-if="activeStep === 0">
                  <AncillaryFlightDivide :passengers="settings.paxes" :pnrId="pnrId" ref="divide" />
               </template>
               <AncillaryResult v-if="isResultAllowed" :result="result" />
            </template>

            <template v-if="settings.type === 6">
               <div class="seats">
                  <AncillarySeatMap :seat="settings.seats" :passenger-list="settings.seats" />
               </div>
            </template>

            <template v-if="settings.type === 7">
               <template v-if="activeStep === 0">
                  <AncillarySSRs :paxes="settings.wheelchair" :searchUuid="settings.searchUuid" ssr-type="wheelchair" />
               </template>
               <AncillaryResult v-if="isResultAllowed" :result="result" />
            </template>

            <template v-if="settings.type === 8">
               <template v-if="activeStep === 0">
                  <AncillarySSRs :paxes="settings.meals" :searchUuid="settings.searchUuid" ssr-type="meals" :total="ssrTotal" />
               </template>
               <template v-if="activeStep === 1">
                  <PaymentPurchaseWidget entityType="flight_meals" :paymentChoiceDetail="paymentChoiceDetail" no-title headless />
               </template>
               <AncillaryResult v-if="isResultAllowed" :result="result" />
            </template>
         </section>

         <!-- Ancillaries Modal Footer -->
         <section id="ancillaries-modal-footer" class="reveal-bottom-" v-if="sections.footer">
            <template v-if="settings.type === 1">
               <p class="footer-informations">{{ $t("ancillariesServicesModal_infos") }}</p>
               <div id="ancillaries-modal-cta-wrapper"></div>

               <UIButton
                  className="default"
                  :text="$t('ancillariesServicesModal_paymentStep')"
                  @click.native="handlerGetPaymentChoiceDetail"
                  :isSpinnerActive="paymentChoiceResponseWaiting"
                  :isDisabled="total === 0 || total === agencyExtraCommission" />
            </template>

            <template v-if="settings.type === 3">
               <p class="footer-informations"></p>
               <div id="ancillaries-modal-cta-wrapper">
                  <span class="divide-reset" @click="handlerResetDivide">{{ $t("ancillariesServicesModal_resetDivide") }}</span>
                  <UIButton className="default" text="Divide" @click.native="handlerDivide" :isSpinnerActive="dividerResponseWaiting" />
               </div>
            </template>
            <template v-if="settings.type === 7">
               <p class="footer-informations">{{ $t("ancillariesServices_lblWheelChairFooterInfo") }}</p>

               <UIButton
                  className="default"
                  :text="$t('ancillariesServicesModal_btnConfirm')"
                  @click.native="handlerTicketWheelChair"
                  :isDisabled="wheelChairCanConfirmed"
                  :isSpinnerActive="wheelChairResponseWaiting" />
            </template>
            <template v-if="settings.type === 8">
               <p class="footer-informations invisible">{{ $t("ancillariesServices_lblMealsFooterInfo") }}</p>

               <UIButton className="default" :text="$t('ancillariesServicesModal_lblPayment')" @click.native="handlerTicketMeals" :is-disabled="ssrDisabled" />
            </template>

            <template v-if="settings.type === 5">
               <template v-if="activeStep === 0">
                  <p class="footer-informations"></p>
                  <div id="ancillaries-modal-cta-wrapper">
                     <UIButton className="default" :text="$t('ancillariesServicesModal_lblSearch')" @click.native="handlerSearchReissue" :isSpinnerActive="reissueResponseWaiting" />
                  </div>
               </template>
               <template v-if="activeStep === 1">
                  <p class="footer-informations">* {{ $t("ancillariesServicesModal_infoTextReissueStep2") }}</p>
                  <div id="ancillaries-modal-cta-wrapper">
                     <span class="divide-reset" @click="handlerReissueReturnToSearch">{{ $t("ancillarReissue_lblPreviousStep") }}</span>
                     <UIButton
                        className="default"
                        :text="$t('ancillariesServicesModal_lblPayment')"
                        @click.native="handlerReissueJumpToPayment"
                        :is-disabled="reissue.isPaymentDeactive"
                        :isSpinnerActive="dividerResponseWaiting" />
                  </div>
               </template>
            </template>
         </section>
      </div>
   </div>
</template>

<script>
import { flightService } from "@/service/index.js";
import Popup from "@/gts-popup.js";
import UIButton from "@/components/unitary/Button.vue";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { generateUuid } from "@/utils.js";
import AncillaryFlightBaggage from "@/components/structural/flight/order-detail/ancillaries/AncillaryFlightBaggage.vue";
import AncillaryFlightDivide from "@/components/structural/flight/order-detail/ancillaries/AncillaryFlightDivide.vue";
import AncillarySSRs from "@/components/structural/flight/order-detail/ancillaries/AncillarySSRs.vue";
import PaymentPurchaseWidget from "@/components/structural/payment/PaymentPurchaseWidget.vue";
import AncillaryResult from "@/components/structural/flight/order-detail/ancillaries/base/AncillaryResult.vue";
import AncillaryReissueSelection from "@/components/structural/flight/order-detail/ancillaries/AncillaryReissueSelection.vue";
import AncillaryReissueSearch from "@/components/structural/flight/order-detail/ancillaries/AncillaryReissueSearch.vue";

gsap.registerPlugin(ScrollToPlugin);

export default {
   name: "AncillariesModal",
   props: {
      settings: Object,
      orderId: [String, Number],
      pnrId: [String, Number],
      pnrSearchUuid: String,
      currency: String,
   },
   components: {
      UIButton,
      AncillaryFlightBaggage,
      AncillaryFlightDivide,
      AncillaryResult,
      PaymentPurchaseWidget,
      AncillaryReissueSelection,
      AncillaryReissueSearch,
      AncillarySSRs,
   },
   data() {
      return {
         reissue: {
            legs: {},
            airports: {},
            searchUuid: null,
            isPaymentDeactive: true,
            isAllowSearchDeactive: true,
         },
         baggageRequestModel: null,
         agencyExtraCommission: 0,
         paymentChoiceDetail: {},
         activeStep: 0,
         sections: {
            header: true,
            body: true,
            footer: true,
         },
         result: {
            allowed: false,
            success: false,
            type: null,
            title: "",
            message: "",
         },
         total: 0,
         ssrTotal: {
            total: 0,
            currency: "",
         },
         closeBtnStatus: true,
         paymentChoiceResponseWaiting: false,
         dividerResponseWaiting: false,
         reissueResponseWaiting: false,
         wheelChairResponseWaiting: false,
         wheelChairCanConfirmed: true,
         wheelChairTicketRequestModel: {},
         ssrTicketRequestModel: {},
         ssrDisabled: true,
         t: gsap.timeline(),
      };
   },
   mounted() {
      this.handlerAncillariesModalOpen();
   },
   computed: {
      isResultAllowed() {
         return this.settings.steps.length - 1 === this.activeStep && this.result.allowed;
      },
      hideCloseBtn() {
         return this.settings.type === 5 && this.activeStep === 2;
      },
      AncillariesModalClass() {
         return {
            "reissue-modal-wrapper": this.settings.type === 5,
            "seat-map": this.settings.type === 6,
         };
      },
   },
   methods: {
      handlerAllowReissueSearch(val) {
         this.reissue.isAllowSearchDeactive = !val;
      },
      handlerReissueReturnToSearch() {
         this.activeStep = 0;
      },
      handlerReissueJumpToPayment() {
         this.activeStep = 2;
      },
      handlerReissuePaymentStep(val) {
         this.reissue.isPaymentDeactive = !val;
      },
      handlerFlightSelection(obj) {
         const identifier = this.reissue.legs;
         identifier[obj.legIndex].isSelected = !identifier[obj.legIndex].isSelected;
         identifier[obj.legIndex].flights[obj.identifier].selected = !identifier[obj.legIndex].flights[obj.identifier].selected;
      },
      handlerGenerateFlights(legs) {
         Object.keys(legs).forEach((leg) => {
            this.$set(this.reissue.legs, legs[leg].index, legs[leg]);
            Object.keys(legs[leg].flights).forEach((flight) => {
               this.$set(legs[leg].flights[flight], "selected", false);
               this.$set(legs[leg], "isSelected", false);
               this.$set(legs[leg].flights[flight], "identifier", flight);
            });
         });
      },
      handlerSearchReissue() {
         this.reissueResponseWaiting = true;
         const query = {
            uuid: generateUuid(),
            pnrId: this.pnrId,
            ...this.$refs.reissueReqModel.requestModel,
         };
         flightService.ancillaryReissueSearch(query).then((res) => {
            this.reissueResponseWaiting = false;
            if (res.result.success) {
               this.$set(this.reissue, "legs", {});
               const isSomeFlightsExist = Object.keys(res.legs).some((leg) => Object.keys(res.legs[leg].flights).length !== 0);
               if (isSomeFlightsExist) {
                  this.handlerGenerateFlights(res.legs);
                  this.reissue.searchUuid = res.searchUuid;
                  this.activeStep = 1;
                  this.reissue.airports = res.airports;
               } else {
                  this.showErrorResult(5, "ancillariesServicesModal_reissueErrorNoSearchResultTitle", "ancillariesServicesModal_reissueErrorNoSearchResultText");
               }
            } else {
               this.showErrorResult(5, "ancillariesServicesModal_reissueErrorTitle", "ancillariesServicesModal_reissueErrorMessage");
            }
         });
      },
      handlerDivide() {
         Popup.confirm(this.$i18n.t("common_confirmationTitle"), this.$i18n.t("popup_ancillaryDivideContent"));
         this.$nextTick(() => {
            document.body.querySelector("#popup-ok").addEventListener("click", () => {
               this.$refs.divide.handlerDivide();
            });
         });
      },
      handlerTicketWheelChair() {
         this.$store.commit("app/setIsProgressGifActive", true);
         this.wheelChairResponseWaiting = true;
         const query = {
            uuid: generateUuid(),
            searchUuid: this.settings.searchUuid,
            wheelChairs: this.wheelChairTicketRequestModel,
         };

         flightService.ancillaryTicketWheelChair(query).then((res) => {
            this.wheelChairResponseWaiting = false;

            if (res.result.success) {
               this.$store.commit("app/setIsProgressGifActive", false);
               this.result = {
                  allowed: true,
                  success: true,
                  type: 7,
                  title: "ancillariesServicesModal_wheelchairSuccessTitle",
                  message: this.$i18n.t("ancillariesServicesModal_wheelchairSuccessMessage"),
               };
               this.activeStep = 1;
            } else {
               console.log("error");
               this.result = {
                  allowed: true,
                  success: false,
                  type: 7,
                  title: "ancillariesServicesModal_wheelchairErrorTitle",
                  message: this.$i18n.t("ancillariesServicesModal_wheelchairErrorMessage"),
               };
               this.activeStep = 1;
               this.$store.commit("app/setIsProgressGifActive", false);
            }
         });
         /*
         this.$store.commit("app/setIsProgressGifActive", true);
        
         // const query = { 1: { "1_1": { type: 5, identifier: "SEG1SVD64000" } } };
         const query = {
            uuid: generateUuid(),
            searchUuid: this.settings.searchUuid,
            wheelChairs: this.wheelChairTicketRequestModel,
         };
         flightService.ancillaryTicketWheelChair(query).then((res) => {
            res;
            this.wheelChairResponseWaiting = false;
            this.$store.commit("app/setIsProgressGifActive", false);

            this.handlerAncillariesModalClose("refresh");
         });
         */
      },
      handlerSelectMeals() {
         this.$store.commit("app/setIsProgressGifActive", true);
         const query = {
            uuid: generateUuid(),
            searchUuid: this.settings.searchUuid,
            meals: {
               ...this.ssrTicketRequestModel,
            },
         };
         flightService
            .ancillarySelecMeal(query)
            .then((response) => {
               if (response.result.success) {
                  this.ssrTotal.total = response.totalAmount;
                  this.total = response.totalAmount;
                  this.ssrTotal.currency = response.currency;
                  this.ssrDisabled = false;
               } else {
                  this.activeStep = 2;
                  this.ssrTotal.total = 0;
                  this.ssrTotal.currency = "";
                  this.result = {
                     allowed: true,
                     success: false,
                     type: 8,
                     title: "ancillariesServicesModal_wheelchairErrorTitle",
                     message: this.$i18n.t("ancillariesServicesModal_wheelchairErrorMessage"),
                  };
               }
            })
            .finally(() => {
               this.$store.commit("app/setIsProgressGifActive", false);
            });
      },
      handlerTicketMeals() {
         this.paymentChoiceResponseWaiting = true;
         const query = {
            amount: this.ssrTotal.total,
            currency: this.ssrTotal.currency,
         };
         flightService.getPaymentChoiceDetail(query).then((res) => {
            this.paymentChoiceDetail = res.paymentChoiceDetail;
            this.paymentChoiceResponseWaiting = false;
            this.activeStep = 1;
         });
      },
      handlerResetDivide() {
         this.$refs.divide.handlerResetDivider();
      },
      handlerAncillariesModalOpen() {
         this.animateModalOpen();
         this.animateComponents();
         this.animatePaxesWrapperScroll();
      },
      handlerAncillariesModalClose(forceToRefresh) {
         this.t.timeScale(3).reverse();
         if (forceToRefresh === "refresh") {
            window.location.reload();
         }
      },
      handlerGetPaymentChoiceDetail() {
         this.paymentChoiceResponseWaiting = true;
         const query = {
            amount: this.total,
            currency: "EUR",
         };
         flightService.getPaymentChoiceDetail(query).then((res) => {
            this.paymentChoiceDetail = res.paymentChoiceDetail;
            this.paymentChoiceResponseWaiting = false;
            this.baggageRequestModel = this.$refs.ancillaryFlightBaggage.requestModel;
            this.activeStep = 1;
         });
      },
      animateModalOpen() {
         this.t.to(this.$refs.ancillaryModal, {
            y: 0,
            opacity: 1,
            duration: 1.25,
            ease: "expo.inOut",
            onComplete: this.onModalOpenComplete,
            onReverseComplete: this.onModalCloseComplete,
         });
      },
      animateComponents() {
         this.t.to(".reveal-bottom, .reveal-top, .reveal-fade", {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: "expo.out",
         });
         this.t.fromTo(
            ".baggage-ancillary-pax",
            { opacity: 0, x: 100 },
            {
               opacity: 1,
               x: 0,
               duration: 0.75,
               ease: "expo.out",
               stagger: { each: 0.125, ease: "power.Out" },
               onComplete: () => gsap.set(this.targets(), { clearProps: "transform" }),
            },
            "-=0.5"
         );
         this.t.fromTo("#flight-baggage-ancillary-total-wrapper", { opacity: 0, x: 50 }, { opacity: 1, x: 0, duration: 0.75, ease: "expo.inOut" }, "-=1");
      },
      animatePaxesWrapperScroll() {
         this.t.to("#paxes-wrapper", {
            scrollTo: this.settings.type === 1 ? 133 * this.settings.paxes.length : 100,
            ease: "expo.easeOut",
            onStart: () => document.querySelector("#virual-scroller-down")?.classList.add("ui-highlighter"),
            onComplete: () => {
               document.querySelector("#virual-scroller-down")?.classList.remove("ui-highlighter");
               document.querySelector("#paxes-wrapper")?.scroll({ top: 0, behavior: "smooth" });
            },
            duration: 1,
         });
      },
      onModalOpenComplete() {
         this.$parent.ancillaries.baggage.isLoading = false;
         this.$parent.ancillaries.divide.isLoading = false;
      },
      onModalCloseComplete() {
         this.$parent.ancillariesModalStatus = false;
      },
      showErrorResult(type, title, message) {
         this.result = {
            allowed: true,
            success: false,
            type,
            title,
            message,
         };
         this.activeStep = 3;
      },
   },
   watch: {
      activeStep: {
         handler(step) {
            this.sections.footer = step !== 1 || this.settings.type === 5;

            if (this.settings.type == 8) {
               if (step == 0) {
                  this.handlerSelectMeals();
               } else if (step == 1) {
                  this.ssrTotal.total = 0;
                  this.ssrTotal.currency = "";
                  this.ssrDisabled = true;
               }
            }
         },
      },
      ssrTicketRequestModel: {
         handler(val) {
            if (Object.keys(val).length > 0) {
               this.ssrDisabled = false;
            }
            this.handlerSelectMeals();
         },

         deep: true,
      },
   },
};
</script>

<style lang="scss">
.reveal-fade,
.reveal-bottom,
.reveal-top {
   opacity: 0;
}

.reveal-bottom {
   transform: translate(0px, 50px);
}

.reveal-top {
   transform: translate(0px, -50px);
}
</style>

<style lang="scss" scoped>
@mixin transition() {
   transition-timing-function: ease-in-out;
   transition-duration: 0.125s;
   transition-property: all;
}

#ancillaries-modal-container {
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 100;
   background-color: #0000004a;

   &.wheelchair {
      .ancillaries-modal-wrapper {
         overflow: visible !important;

         #ancillaries-modal-body {
            overflow-y: auto;
         }
      }
   }

   .ancillaries-modal-wrapper {
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
      border-radius: 1rem;
      width: 944px;
      max-height: 90vh;
      height: fit-content;
      padding: 1.5rem 2.5rem;
      opacity: 0;
      transform: translate(0px, 100px);
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media only screen and (max-width: 768px) {
         height: 100vh !important;
         max-height: max-content;
         border-radius: 0;
         padding: 1.5rem 0.75rem;
      }

      &.reissue-modal-wrapper {
         height: 90%;
      }

      &.seat-map {
         width: 1400px;
         // height: 100%;
         z-index: 99900;
      }

      section {
         &#ancillaries-modal-header {
            padding-bottom: 1.5rem;
            border-bottom: 1px solid #ccc;

            #close-ancillaries-modal-wrapper {
               display: flex;
               justify-content: flex-end;

               #close-ancillaries-modal {
                  $size: 36px;
                  width: $size;
                  height: $size;
                  background-color: #eaeaea;
                  border-radius: 50%;
                  position: relative;
                  display: block;
                  transform: scale(1);
                  cursor: pointer;

                  &:hover {
                     transform: scale(1.125);
                     background: #ccc;
                     @include transition();
                  }

                  &::before,
                  &::after {
                     content: "";
                     width: 18px;
                     height: 2px;
                     background: #363636;
                     display: block;
                     border-radius: 1rem;
                     position: absolute;
                     inset: 0;
                     margin: auto;
                  }

                  &::before {
                     transform: rotate(45deg);
                  }

                  &::after {
                     transform: rotate(-45deg);
                  }
               }
            }

            #ancillaries-breadcrumb-wrapper {
               ul {
                  display: flex;

                  li {
                     position: relative;
                     display: flex;
                     align-items: center;
                     padding: 0 1.75rem;
                     @media only screen and (max-width: 768px) {
                        padding: 0;
                        margin-right: 1rem;
                     }

                     span {
                        font-size: var(--large);
                        color: #868686;
                        cursor: not-allowed;

                        &.activeStep {
                           font-size: 1.5rem;
                           color: var(--secondary);
                           color: #363636;
                           font-weight: 500;
                           @media only screen and (max-width: 768px) {
                              font-size: 1.125rem;
                           }
                        }
                     }

                     &:first-child {
                        padding-left: 0 !important;
                     }

                     &:last-child {
                        padding-right: 0 !important;

                        &::after {
                           display: none !important;
                        }
                     }

                     &::after {
                        content: "";
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy43OTUiIGhlaWdodD0iMjEuNTU5IiB2aWV3Qm94PSIwIDAgMTcuNzk1IDIxLjU1OSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC41IDAuNSkiIG9wYWNpdHk9IjAuNSI+PHBhdGggZD0iTTE2OTI0LDE1OTA1LjhhLjUuNSwwLDAsMS0uMzg3LS44MTZsOC4xMzctOS45NjMtOC4xMzctOS45NjNhLjUuNSwwLDEsMSwuNzczLS42MzNsOC40LDEwLjI3OWEuNS41LDAsMCwxLDAsLjYzM2wtOC40LDEwLjI3OUEuNS41LDAsMCwxLDE2OTI0LDE1OTA1LjhaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTY5MjQgLTE1ODg0Ljc0KSIgZmlsbD0iIzEwMTAxMCIvPjxwYXRoIGQ9Ik0xNjkyNCwxNTkwNS44YS41LjUsMCwwLDEtLjM4Ny0uODE2bDguMTM3LTkuOTYzLTguMTM3LTkuOTYzYS41LjUsMCwxLDEsLjc3My0uNjMzbDguNCwxMC4yNzlhLjUuNSwwLDAsMSwwLC42MzNsLTguNCwxMC4yNzlBLjUuNSwwLDAsMSwxNjkyNCwxNTkwNS44WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE2OTE1LjYwMiAtMTU4ODQuNzQpIiBmaWxsPSIjMTAxMDEwIi8+PC9nPjwvc3ZnPg==");
                        background-size: contain;
                        width: 16px;
                        height: 20px;
                        position: absolute;
                        right: -8px;

                        @media only screen and (max-width: 768px) {
                           display: none;
                        }
                     }
                  }
               }
            }
         }

         &#ancillaries-modal-body {
            padding: 2rem 0;
            height: 100%;
            // overflow-y: auto;

            &.centered-body {
               justify-content: center;
               display: flex;
            }
         }

         &#ancillaries-modal-footer {
            padding: 1rem 0 1.5rem 0;
            border-top: 1px solid #ccc;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
               font-size: var(--xsmall);

               b {
                  font-weight: 600;
               }
            }

            #ancillaries-modal-cta-wrapper {
               display: flex;
               align-items: center;

               span {
                  color: #1467be;
                  font-size: var(--normal);
                  text-decoration: underline;
                  font-weight: 500;
                  margin-right: 2rem;
                  cursor: pointer;
               }
            }
         }
      }
   }
}
</style>
