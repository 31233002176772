<template>
   <div id="checkout-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <!-- FlightCheckout Grid -->
         <div class="container-lg px-md-2 px-lg-0">
            <div class="col px-md-2 px-lg-0">
               <div class="row gx-4" :class="isPageLoad ? '' : 'data-mask'">
                  <div class="col-12 col-lg-10">
                     <!--Info Badge -->

                     <Badge2 :msg="$t('flightCheckout_BrandPriceUnavailable')" warning v-if="brandPriceUnavailable" />
                     <Badge2 :msg="$t('flightCheckout_SELECTED_BRANDS_NOT_VALID_NOW')" warning v-if="handlerPaymentTypes" />
                     <!-- <Badge2 :msg="$t('flightCheckout_SELECTED_BRANDS_NOT_VALID_NOW')" warning v-if="handlerPaymentTypes" /> -->
                     <Badge2 :msg="$t('flightInformationsComponent_timeChanged')" error v-if="isChanged" />
                     <!-- Mobile Pnr Information -->
                     <CheckoutPnrInfoBadge />

                     <!-- Flight Informations -->
                     <FlightInformations />

                     <!-- Price Informations -->
                     <PriceInformations />

                     <!-- Passenger Informations -->
                     <PassengerInformations />

                     <!-- Contact Informations -->
                     <CommonCheckoutContact :isflight="true" :isShowAutofill="true" :isBorderavailable="true" :isShowMail="true" />

                     <!-- Checkout Payment -->

                     <PaymentPurchaseWidget entityType="flight" :checkoutRequest="checkoutRequestModel" :paymentChoiceDetail="paymentChoiceDetail" />

                     <!-- Checkout Notes -->
                     <CheckoutNotes />
                  </div>
                  <div class="col d-none d-lg-block col-lg-2">
                     <!-- Flight Checkout -->
                     <CheckoutBasket />
                  </div>
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
// import router from "@/router/index.js";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

import CheckoutPnrInfoBadge from "@/components/structural/flight/checkout/CheckoutPnrInfoBadge.vue";
import FlightInformations from "@/components/structural/flight/checkout/FlightInformations.vue";
import PriceInformations from "@/components/structural/flight/checkout/PriceInformations.vue";
import PassengerInformations from "@/components/structural/flight/checkout/PassengerInformations.vue";
import PaymentPurchaseWidget from "@/components/structural/payment/PaymentPurchaseWidget.vue";
import CheckoutNotes from "@/components/structural/flight/checkout/CheckoutNotes.vue";
import CheckoutBasket from "@/components/structural/flight/checkout/CheckoutBasket.vue";
import Badge2 from "@/components/unitary/Badge2.vue";
import { flightService } from "@/service/index.js";
import { generateUuid, getCookie, setCookie } from "@/utils.js";
import CommonCheckoutContact from "@/components/unitary/CommonCheckoutContact.vue";

// import response from "@/placeholders/orderDetail.js";

export default {
   name: "FlightCheckout",
   components: {
      Aside,
      Navbar,
      Main,
      CheckoutPnrInfoBadge,
      Badge2,
      FlightInformations,
      PriceInformations,
      PassengerInformations,
      CommonCheckoutContact,
      PaymentPurchaseWidget,

      CheckoutNotes,
      CheckoutBasket,
   },
   data() {
      return {
         isPageLoad: false,
         isChanged: false,
         brandPriceUnavailable: false,
         checkoutRequestModel: {},
         paymentChoiceDetail: {},
      };
   },
   created() {
      const self = this;

      // realData Query
      let query = {
         searchUuid: self.$route.params.uuid,
         trxType: 1,
         uuid: generateUuid(),
         checkoutUuid: self.$route.query.refresh ? self.$route.query.refresh : null,
         pnrId: self.$route.query.pnr ? self.$route.query.pnr : 0,
      };

      flightService.checkout(query).then((response) => {
         self.$store.commit("flight/updateCheckoutResponse", response.checkoutResponse);
         self.$store.commit("flight/updateCheckoutRequest", response.checkoutRequest);

         self.handlerCookieData(response.checkoutRequest.passengers);

         self.checkoutRequestModel = self.$store.state.flight.checkout.request;
         self.paymentChoiceDetail = self.$store.state.flight.checkout.response.paymentChoiceDetail;
         if (!this.$route.query.refresh) {
            this.$router.push({ query: { refresh: response.checkoutUuid, pnr: query.pnrId } });
            self.$store.commit("flight/updateCheckoutUuid", response.checkoutUuid);
         } else if (response.checkoutUuid) {
            self.$store.commit("flight/updateCheckoutUuid", response.checkoutUuid);
         }
         if (response.result.success) {
            self.isPageLoad = true;
         } else {
            // console.warn("FlightCheckout:: Error");
         }
      });
   },
   computed: {
      handlerPaymentTypes() {
         return this.$store.state.flight.checkout.response?.invalidPackage;
      },
      HasWarning() {
         return this.$store.state.flight.checkout.response?.warnings[-1];
      },
      handlerIsChange() {
         this.$store.state.flight.checkout.response.legs;
         return false;
      },
   },
   methods: {
      funcValidateForm() {
         return true;
      },
      handlerCookieData(passengers) {
         const self = this;
         const currentPaxCount = {};

         // Iterate over the passengers array and count the paxTypes
         for (const passenger of passengers) {
            const paxType = passenger.paxType;
            currentPaxCount[paxType] = (currentPaxCount[paxType] || 0) + 1;
         }
         if (getCookie("paxData")) {
            const paxData = JSON.parse(getCookie("paxData"));
            let paxDataCount = {};
            for (const passenger of paxData) {
               const paxType = passenger.paxType;
               paxDataCount[paxType] = (paxDataCount[paxType] || 0) + 1;
            }

            if (JSON.stringify(currentPaxCount) == JSON.stringify(paxDataCount) && paxData) {
               self.$store.commit("flight/setPaxCookieData", paxData);
            }
         }
         setCookie("paxCounts", JSON.stringify(currentPaxCount), 0, 1);
      },
   },
   watch: {
      "$store.state.flight.checkout": {
         handler: function (checkout) {
            this.checkoutRequestModel = checkout.request;
            this.paymentChoiceDetail = checkout.response.paymentChoiceDetail;

            const legs = checkout.response.legs;
            const warnings = checkout.response.warnings;
            this.brandPriceUnavailable = Object.keys(warnings).some((warning) => {
               return warnings[warning].includes("PRICE_UNAVAILABLE");
            });

            const booleanArr = [];
            Object.keys(legs).forEach((leg) => {
               legs[leg].segments.forEach((segment) => {
                  booleanArr.push(segment.arrivalDateTimeChanged, segment.cabinTypeChanged, segment.departureDateTimeChanged, segment.flightClassChanged);
               });
            });
            this.isChanged = booleanArr.some((el) => {
               return el == true;
            });
         },
         deep: true,
      },
      brandPriceUnavailable: {
         handler: function (val) {
            if (val) {
               window.scrollTo({ top: 0, behavior: "smooth" });
            }
         },
      },
   },
};
</script>
