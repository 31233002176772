const airlineList = {
    airlines: [
        {
            iataCode: "GB",
            iataLabel: "GB",
            icaoCode: "ABX",
            icaoLabel: "ABX",
            nameTr: "ABX Air",
            nameEn: "ABX Air",
            nameDe: "ABX Air"
        },
        {
            iataCode: "A3",
            iataLabel: "A3",
            icaoCode: "AEE",
            icaoLabel: "AEE",
            nameTr: "Aegean Airlines",
            nameEn: "Aegean Airlines",
            nameDe: "Aegean Airlines"
        },
        {
            iataCode: "EI",
            iataLabel: "EI",
            icaoCode: "EIN",
            icaoLabel: "EIN",
            nameTr: "Aer Lingus",
            nameEn: "Aer Lingus",
            nameDe: "Aer Lingus"
        },
        {
            iataCode: "P5",
            iataLabel: "P5",
            icaoCode: "RPB",
            icaoLabel: "RPB",
            nameTr: "Aero Republica",
            nameEn: "Aero Republica",
            nameDe: "Aero Republica"
        },
        {
            iataCode: "SU",
            iataLabel: "SU",
            icaoCode: "AFL",
            icaoLabel: "AFL",
            nameTr: "AEROFLOT",
            nameEn: "AEROFLOT",
            nameDe: "AEROFLOT"
        },
        {
            iataCode: "AR",
            iataLabel: "AR",
            icaoCode: "ARG",
            icaoLabel: "ARG",
            nameTr: "Aerolineas Argentinas",
            nameEn: "Aerolineas Argentinas",
            nameDe: "Aerolineas Argentinas"
        },
        {
            iataCode: "VW",
            iataLabel: "VW",
            icaoCode: "TAO",
            icaoLabel: "TAO",
            nameTr: "Aeromar",
            nameEn: "Aeromar",
            nameDe: "Aeromar"
        },
        {
            iataCode: "AM",
            iataLabel: "AM",
            icaoCode: "AMX",
            icaoLabel: "AMX",
            nameTr: "Aeromexico",
            nameEn: "Aeromexico",
            nameDe: "Aeromexico"
        },
        {
            iataCode: "AW",
            iataLabel: "AW",
            icaoCode: "AFW",
            icaoLabel: "AFW",
            nameTr: "Africa World Airlines",
            nameEn: "Africa World Airlines",
            nameDe: "Africa World Airlines"
        },
        {
            iataCode: "AH",
            iataLabel: "AH",
            icaoCode: "DAH",
            icaoLabel: "DAH",
            nameTr: "Air Algérie",
            nameEn: "Air Algérie",
            nameDe: "Air Algérie"
        },
        {
            iataCode: "G9",
            iataLabel: "G9",
            icaoCode: "ABY",
            icaoLabel: "ABY",
            nameTr: "Air Arabia",
            nameEn: "Air Arabia",
            nameDe: "Air Arabia"
        },
        {
            iataCode: "KC",
            iataLabel: "KC",
            icaoCode: "KZR",
            icaoLabel: "KZR",
            nameTr: "Air Astana",
            nameEn: "Air Astana",
            nameDe: "Air Astana"
        },
        {
            iataCode: "UU",
            iataLabel: "UU",
            icaoCode: "REU",
            icaoLabel: "REU",
            nameTr: "Air Austral",
            nameEn: "Air Austral",
            nameDe: "Air Austral"
        },
        {
            iataCode: "BT",
            iataLabel: "BT",
            icaoCode: "BTI",
            icaoLabel: "BTI",
            nameTr: "Air Baltic",
            nameEn: "Air Baltic",
            nameDe: "Air Baltic"
        },
        {
            iataCode: "BP",
            iataLabel: "BP",
            icaoCode: "BOT",
            icaoLabel: "BOT",
            nameTr: "Air Botswana",
            nameEn: "Air Botswana",
            nameDe: "Air Botswana"
        },
        {
            iataCode: "2J",
            iataLabel: "2J",
            icaoCode: "VBW",
            icaoLabel: "VBW",
            nameTr: "Air Burkina",
            nameEn: "Air Burkina",
            nameDe: "Air Burkina"
        },
        {
            iataCode: "SM",
            iataLabel: "SM",
            icaoCode: "MSC",
            icaoLabel: "MSC",
            nameTr: "Air Cairo",
            nameEn: "Air Cairo",
            nameDe: "Air Cairo"
        },
        {
            iataCode: "TY",
            iataLabel: "TY",
            icaoCode: "TPC",
            icaoLabel: "TPC",
            nameTr: "Air Caledonie",
            nameEn: "Air Caledonie",
            nameDe: "Air Caledonie"
        },
        {
            iataCode: "AC",
            iataLabel: "AC",
            icaoCode: "ACA",
            icaoLabel: "ACA",
            nameTr: "Air Canada",
            nameEn: "Air Canada",
            nameDe: "Air Canada"
        },
        {
            iataCode: "TX",
            iataLabel: "TX",
            icaoCode: "FWI",
            icaoLabel: "FWI",
            nameTr: "Air Caraibes",
            nameEn: "Air Caraibes",
            nameDe: "Air Caraibes"
        },
        {
            iataCode: "CA",
            iataLabel: "CA",
            icaoCode: "CCA",
            icaoLabel: "CCA",
            nameTr: "Air China",
            nameEn: "Air China",
            nameDe: "Air China"
        },
        {
            iataCode: "XK",
            iataLabel: "XK",
            icaoCode: "CCM",
            icaoLabel: "CCM",
            nameTr: "Air Corsica",
            nameEn: "Air Corsica",
            nameDe: "Air Corsica"
        },
        {
            iataCode: "EN",
            iataLabel: "EN",
            icaoCode: "DLA",
            icaoLabel: "DLA",
            nameTr: "Air Dolomiti",
            nameEn: "Air Dolomiti",
            nameDe: "Air Dolomiti"
        },
        {
            iataCode: "UX",
            iataLabel: "UX",
            icaoCode: "AEA",
            icaoLabel: "AEA",
            nameTr: "Air Europa",
            nameEn: "Air Europa",
            nameDe: "Air Europa"
        },
        {
            iataCode: "AF",
            iataLabel: "AF",
            icaoCode: "AFR",
            icaoLabel: "AFR",
            nameTr: "Air France",
            nameEn: "Air France",
            nameDe: "Air France"
        },
        {
            iataCode: "GT",
            iataLabel: "GT",
            icaoCode: "CGH",
            icaoLabel: "CGH",
            nameTr: "Air Guilin",
            nameEn: "Air Guilin",
            nameDe: "Air Guilin"
        },
        {
            iataCode: "AI",
            iataLabel: "AI",
            icaoCode: "AIC",
            icaoLabel: "AIC",
            nameTr: "Air India",
            nameEn: "Air India",
            nameDe: "Air India"
        },
        {
            iataCode: "JS",
            iataLabel: "JS",
            icaoCode: "KOR",
            icaoLabel: "KOR",
            nameTr: "Air Koryo",
            nameEn: "Air Koryo",
            nameDe: "Air Koryo"
        },
        {
            iataCode: "NX",
            iataLabel: "NX",
            icaoCode: "AMU",
            icaoLabel: "AMU",
            nameTr: "Air Macau",
            nameEn: "Air Macau",
            nameDe: "Air Macau"
        },
        {
            iataCode: "MD",
            iataLabel: "MD",
            icaoCode: "MDG",
            icaoLabel: "MDG",
            nameTr: "Air Madagascar",
            nameEn: "Air Madagascar",
            nameDe: "Air Madagascar"
        },
        {
            iataCode: "KM",
            iataLabel: "KM",
            icaoCode: "AMC",
            icaoLabel: "AMC",
            nameTr: "Air Malta",
            nameEn: "Air Malta",
            nameDe: "Air Malta"
        },
        {
            iataCode: "MK",
            iataLabel: "MK",
            icaoCode: "MAU",
            icaoLabel: "MAU",
            nameTr: "Air Mauritius",
            nameEn: "Air Mauritius",
            nameDe: "Air Mauritius"
        },
        {
            iataCode: "9U",
            iataLabel: "9U",
            icaoCode: "MLD",
            icaoLabel: "MLD",
            nameTr: "Air Moldova",
            nameEn: "Air Moldova",
            nameDe: "Air Moldova"
        },
        {
            iataCode: "SW",
            iataLabel: "SW",
            icaoCode: "NMB",
            icaoLabel: "NMB",
            nameTr: "Air Namibia",
            nameEn: "Air Namibia",
            nameDe: "Air Namibia"
        },
        {
            iataCode: "NZ",
            iataLabel: "NZ",
            icaoCode: "ANZ",
            icaoLabel: "ANZ",
            nameTr: "Air New Zealand",
            nameEn: "Air New Zealand",
            nameDe: "Air New Zealand"
        },
        {
            iataCode: "PX",
            iataLabel: "PX",
            icaoCode: "ANG",
            icaoLabel: "ANG",
            nameTr: "Air Niugini",
            nameEn: "Air Niugini",
            nameDe: "Air Niugini"
        },
        {
            iataCode: "YW",
            iataLabel: "YW",
            icaoCode: "ANE",
            icaoLabel: "ANE",
            nameTr: "Air Nostrum",
            nameEn: "Air Nostrum",
            nameDe: "Air Nostrum"
        },
        {
            iataCode: "P4",
            iataLabel: "P4",
            icaoCode: "APK",
            icaoLabel: "APK",
            nameTr: "Air Peace",
            nameEn: "Air Peace",
            nameDe: "Air Peace"
        },
        {
            iataCode: "JU",
            iataLabel: "JU",
            icaoCode: "ASL",
            icaoLabel: "ASL",
            nameTr: "Air Serbia",
            nameEn: "Air Serbia",
            nameDe: "Air Serbia"
        },
        {
            iataCode: "HM",
            iataLabel: "HM",
            icaoCode: "SEY",
            icaoLabel: "SEY",
            nameTr: "Air Seychelles",
            nameEn: "Air Seychelles",
            nameDe: "Air Seychelles"
        },
        {
            iataCode: "VT",
            iataLabel: "VT",
            icaoCode: "VTA",
            icaoLabel: "VTA",
            nameTr: "Air Tahiti",
            nameEn: "Air Tahiti",
            nameDe: "Air Tahiti"
        },
        {
            iataCode: "TN",
            iataLabel: "TN",
            icaoCode: "THT",
            icaoLabel: "THT",
            nameTr: "Air Tahiti Nui",
            nameEn: "Air Tahiti Nui",
            nameDe: "Air Tahiti Nui"
        },
        {
            iataCode: "TC",
            iataLabel: "TC",
            icaoCode: "ATC",
            icaoLabel: "ATC",
            nameTr: "Air Tanzania",
            nameEn: "Air Tanzania",
            nameDe: "Air Tanzania"
        },
        {
            iataCode: "TS",
            iataLabel: "TS",
            icaoCode: "TSC",
            icaoLabel: "TSC",
            nameTr: "Air Transat",
            nameEn: "Air Transat",
            nameDe: "Air Transat"
        },
        {
            iataCode: "NF",
            iataLabel: "NF",
            icaoCode: "AVN",
            icaoLabel: "AVN",
            nameTr: "Air Vanuatu",
            nameEn: "Air Vanuatu",
            nameDe: "Air Vanuatu"
        },
        {
            iataCode: "RU",
            iataLabel: "RU",
            icaoCode: "ABW",
            icaoLabel: "ABW",
            nameTr: "AirBridgeCargo Airlines",
            nameEn: "AirBridgeCargo Airlines",
            nameDe: "AirBridgeCargo Airlines"
        },
        {
            iataCode: "SB",
            iataLabel: "SB",
            icaoCode: "ACI",
            icaoLabel: "ACI",
            nameTr: "Aircalin",
            nameEn: "Aircalin",
            nameDe: "Aircalin"
        },
        {
            iataCode: "4Z",
            iataLabel: "4Z",
            icaoCode: "LNK",
            icaoLabel: "LNK",
            nameTr: "Airlink",
            nameEn: "Airlink",
            nameDe: "Airlink"
        },
        {
            iataCode: "AS",
            iataLabel: "AS",
            icaoCode: "ASA",
            icaoLabel: "ASA",
            nameTr: "Alaska Airlines",
            nameEn: "Alaska Airlines",
            nameDe: "Alaska Airlines"
        },
        {
            iataCode: "AP",
            iataLabel: "AP",
            icaoCode: "LAV",
            icaoLabel: "LAV",
            nameTr: "Albastar",
            nameEn: "Albastar",
            nameDe: "Albastar"
        },
        {
            iataCode: "AZ",
            iataLabel: "AZ",
            icaoCode: "AZA",
            icaoLabel: "AZA",
            nameTr: "Alitalia",
            nameEn: "Alitalia",
            nameDe: "Alitalia"
        },
        {
            iataCode: "4W",
            iataLabel: "4W",
            icaoCode: "AJK",
            icaoLabel: "AJK",
            nameTr: "Allied Air",
            nameEn: "Allied Air",
            nameDe: "Allied Air"
        },
        {
            iataCode: "UJ",
            iataLabel: "UJ",
            icaoCode: "LMU",
            icaoLabel: "LMU",
            nameTr: "AlMasria Universal Airlines",
            nameEn: "AlMasria Universal Airlines",
            nameDe: "AlMasria Universal Airlines"
        },
        {
            iataCode: "AA",
            iataLabel: "AA",
            icaoCode: "AAL",
            icaoLabel: "AAL",
            nameTr: "American Airlines",
            nameEn: "American Airlines",
            nameDe: "American Airlines"
        },
        {
            iataCode: "NH",
            iataLabel: "NH",
            icaoCode: "ANA",
            icaoLabel: "ANA",
            nameTr: "ANA",
            nameEn: "ANA",
            nameDe: "ANA"
        },
        {
            iataCode: "GP",
            iataLabel: "GP",
            icaoCode: "RIV",
            icaoLabel: "RIV",
            nameTr: "APG Airlines",
            nameEn: "APG Airlines",
            nameDe: "APG Airlines"
        },
        {
            iataCode: "W3",
            iataLabel: "W3",
            icaoCode: "ARA",
            icaoLabel: "ARA",
            nameTr: "Arik Air",
            nameEn: "Arik Air",
            nameDe: "Arik Air"
        },
        {
            iataCode: "IZ",
            iataLabel: "IZ",
            icaoCode: "AIZ",
            icaoLabel: "AIZ",
            nameTr: "Arkia Israeli Airlines",
            nameEn: "Arkia Israeli Airlines",
            nameDe: "Arkia Israeli Airlines"
        },
        {
            iataCode: "OZ",
            iataLabel: "OZ",
            icaoCode: "AAR",
            icaoLabel: "AAR",
            nameTr: "Asiana Airlines",
            nameEn: "Asiana Airlines",
            nameDe: "Asiana Airlines"
        },
        {
            iataCode: "KP",
            iataLabel: "KP",
            icaoCode: "SKK",
            icaoLabel: "SKK",
            nameTr: "ASKY",
            nameEn: "ASKY",
            nameDe: "ASKY"
        },
        {
            iataCode: "5O",
            iataLabel: "5O",
            icaoCode: "FPO",
            icaoLabel: "FPO",
            nameTr: "ASL Airlines France",
            nameEn: "ASL Airlines France",
            nameDe: "ASL Airlines France"
        },
        {
            iataCode: "RC",
            iataLabel: "RC",
            icaoCode: "FLI",
            icaoLabel: "FLI",
            nameTr: "Atlantic Airways",
            nameEn: "Atlantic Airways",
            nameDe: "Atlantic Airways"
        },
        {
            iataCode: "5Y",
            iataLabel: "5Y",
            icaoCode: "GTI",
            icaoLabel: "GTI",
            nameTr: "Atlas Air",
            nameEn: "Atlas Air",
            nameDe: "Atlas Air"
        },
        {
            iataCode: "AU",
            iataLabel: "AU",
            icaoCode: "AUT",
            icaoLabel: "AUT",
            nameTr: "Austral",
            nameEn: "Austral",
            nameDe: "Austral"
        },
        {
            iataCode: "OS",
            iataLabel: "OS",
            icaoCode: "AUA",
            icaoLabel: "AUA",
            nameTr: "Austrian",
            nameEn: "Austrian",
            nameDe: "Austrian"
        },
        {
            iataCode: "AV",
            iataLabel: "AV",
            icaoCode: "AVA",
            icaoLabel: "AVA",
            nameTr: "Avianca",
            nameEn: "Avianca",
            nameDe: "Avianca"
        },
        {
            iataCode: "LR",
            iataLabel: "LR",
            icaoCode: "LRC",
            icaoLabel: "LRC",
            nameTr: "Avianca Costa Rica",
            nameEn: "Avianca Costa Rica",
            nameDe: "Avianca Costa Rica"
        },
        {
            iataCode: "2K",
            iataLabel: "2K",
            icaoCode: "GLG",
            icaoLabel: "GLG",
            nameTr: "Avianca Ecuador",
            nameEn: "Avianca Ecuador",
            nameDe: "Avianca Ecuador"
        },
        {
            iataCode: "J2",
            iataLabel: "J2",
            icaoCode: "AHY",
            icaoLabel: "AHY",
            nameTr: "Azerbaijan Airlines",
            nameEn: "Azerbaijan Airlines",
            nameDe: "Azerbaijan Airlines"
        },
        {
            iataCode: "S4",
            iataLabel: "S4",
            icaoCode: "RZO",
            icaoLabel: "RZO",
            nameTr: "Azores Airlines",
            nameEn: "Azores Airlines",
            nameDe: "Azores Airlines"
        },
        {
            iataCode: "AD",
            iataLabel: "AD",
            icaoCode: "AZU",
            icaoLabel: "AZU",
            nameTr: "Azul Brazilian Airlines",
            nameEn: "Azul Brazilian Airlines",
            nameDe: "Azul Brazilian Airlines"
        },
        {
            iataCode: "UP",
            iataLabel: "UP",
            icaoCode: "BHS",
            icaoLabel: "BHS",
            nameTr: "Bahamasair",
            nameEn: "Bahamasair",
            nameDe: "Bahamasair"
        },
        {
            iataCode: "QH",
            iataLabel: "QH",
            icaoCode: "BAV",
            icaoLabel: "BAV",
            nameTr: "Bamboo Airways",
            nameEn: "Bamboo Airways",
            nameDe: "Bamboo Airways"
        },
        {
            iataCode: "PG",
            iataLabel: "PG",
            icaoCode: "BKP",
            icaoLabel: "BKP",
            nameTr: "Bangkok Airways",
            nameEn: "Bangkok Airways",
            nameDe: "Bangkok Airways"
        },
        {
            iataCode: "ID",
            iataLabel: "ID",
            icaoCode: "BTK",
            icaoLabel: "BTK",
            nameTr: "Batik Air",
            nameEn: "Batik Air",
            nameDe: "Batik Air"
        },
        {
            iataCode: "B2",
            iataLabel: "B2",
            icaoCode: "BRU",
            icaoLabel: "BRU",
            nameTr: "Belavia Belarusian Airlines",
            nameEn: "Belavia Belarusian Airlines",
            nameDe: "Belavia Belarusian Airlines"
        },
        {
            iataCode: "BG",
            iataLabel: "BG",
            icaoCode: "BBC",
            icaoLabel: "BBC",
            nameTr: "Biman Bangladesh Airlines",
            nameEn: "Biman Bangladesh Airlines",
            nameDe: "Biman Bangladesh Airlines"
        },
        {
            iataCode: "NT",
            iataLabel: "NT",
            icaoCode: "IBB",
            icaoLabel: "IBB",
            nameTr: "Binter Canarias",
            nameEn: "Binter Canarias",
            nameDe: "Binter Canarias"
        },
        {
            iataCode: "0B",
            iataLabel: "0B",
            icaoCode: "BMS",
            icaoLabel: "BMS",
            nameTr: "Blue Air",
            nameEn: "Blue Air",
            nameDe: "Blue Air"
        },
        {
            iataCode: "BV",
            iataLabel: "BV",
            icaoCode: "BPA",
            icaoLabel: "BPA",
            nameTr: "Blue Panorama",
            nameEn: "Blue Panorama",
            nameDe: "Blue Panorama"
        },
        {
            iataCode: "OB",
            iataLabel: "OB",
            icaoCode: "BOV",
            icaoLabel: "BOV",
            nameTr: "BoA Boliviana de Aviacion",
            nameEn: "BoA Boliviana de Aviacion",
            nameDe: "BoA Boliviana de Aviacion"
        },
        {
            iataCode: "TF",
            iataLabel: "TF",
            icaoCode: "BRX",
            icaoLabel: "BRX",
            nameTr: "Braathens Regional Airways",
            nameEn: "Braathens Regional Airways",
            nameDe: "Braathens Regional Airways"
        },
        {
            iataCode: "BA",
            iataLabel: "BA",
            icaoCode: "BAW",
            icaoLabel: "BAW",
            nameTr: "British Airways",
            nameEn: "British Airways",
            nameDe: "British Airways"
        },
        {
            iataCode: "SN",
            iataLabel: "SN",
            icaoCode: "BEL",
            icaoLabel: "BEL",
            nameTr: "Brussels Airlines",
            nameEn: "Brussels Airlines",
            nameDe: "Brussels Airlines"
        },
        {
            iataCode: "FB",
            iataLabel: "FB",
            icaoCode: "LZB",
            icaoLabel: "LZB",
            nameTr: "Bulgaria Air",
            nameEn: "Bulgaria Air",
            nameDe: "Bulgaria Air"
        },
        {
            iataCode: "5C",
            iataLabel: "5C",
            icaoCode: "ICL",
            icaoLabel: "ICL",
            nameTr: "CAL Cargo Airlines",
            nameEn: "CAL Cargo Airlines",
            nameDe: "CAL Cargo Airlines"
        },
        {
            iataCode: "QC",
            iataLabel: "QC",
            icaoCode: "CRC",
            icaoLabel: "CRC",
            nameTr: "Camair-Co",
            nameEn: "Camair-Co",
            nameDe: "Camair-Co"
        },
        {
            iataCode: "K6",
            iataLabel: "K6",
            icaoCode: "KHV",
            icaoLabel: "KHV",
            nameTr: "Cambodia Angkor Air",
            nameEn: "Cambodia Angkor Air",
            nameDe: "Cambodia Angkor Air"
        },
        {
            iataCode: "JD",
            iataLabel: "JD",
            icaoCode: "CBJ",
            icaoLabel: "CBJ",
            nameTr: "Capital Airlines",
            nameEn: "Capital Airlines",
            nameDe: "Capital Airlines"
        },
        {
            iataCode: "W8",
            iataLabel: "W8",
            icaoCode: "CJT",
            icaoLabel: "CJT",
            nameTr: "Cargojet Airways",
            nameEn: "Cargojet Airways",
            nameDe: "Cargojet Airways"
        },
        {
            iataCode: "CV",
            iataLabel: "CV",
            icaoCode: "CLX",
            icaoLabel: "CLX",
            nameTr: "Cargolux",
            nameEn: "Cargolux",
            nameDe: "Cargolux"
        },
        {
            iataCode: "BW",
            iataLabel: "BW",
            icaoCode: "BWA",
            icaoLabel: "BWA",
            nameTr: "Caribbean Airlines",
            nameEn: "Caribbean Airlines",
            nameDe: "Caribbean Airlines"
        },
        {
            iataCode: "V3",
            iataLabel: "V3",
            icaoCode: "KRP",
            icaoLabel: "KRP",
            nameTr: "Carpatair",
            nameEn: "Carpatair",
            nameDe: "Carpatair"
        },
        {
            iataCode: "CX",
            iataLabel: "CX",
            icaoCode: "CPA",
            icaoLabel: "CPA",
            nameTr: "Cathay Pacific",
            nameEn: "Cathay Pacific",
            nameDe: "Cathay Pacific"
        },
        {
            iataCode: "5J",
            iataLabel: "5J",
            icaoCode: "CEB",
            icaoLabel: "CEB",
            nameTr: "Cebu Pacific",
            nameEn: "Cebu Pacific",
            nameDe: "Cebu Pacific"
        },
        {
            iataCode: "CI",
            iataLabel: "CI",
            icaoCode: "CAL",
            icaoLabel: "CAL",
            nameTr: "China Airlines",
            nameEn: "China Airlines",
            nameDe: "China Airlines"
        },
        {
            iataCode: "CK",
            iataLabel: "CK",
            icaoCode: "CKK",
            icaoLabel: "CKK",
            nameTr: "China Cargo Airlines",
            nameEn: "China Cargo Airlines",
            nameDe: "China Cargo Airlines"
        },
        {
            iataCode: "MU",
            iataLabel: "MU",
            icaoCode: "CES",
            icaoLabel: "CES",
            nameTr: "China Eastern",
            nameEn: "China Eastern",
            nameDe: "China Eastern"
        },
        {
            iataCode: "G5",
            iataLabel: "G5",
            icaoCode: "HXA",
            icaoLabel: "HXA",
            nameTr: "China Express Airlines",
            nameEn: "China Express Airlines",
            nameDe: "China Express Airlines"
        },
        {
            iataCode: "CF",
            iataLabel: "CF",
            icaoCode: "CYZ",
            icaoLabel: "CYZ",
            nameTr: "China Postal Airlines",
            nameEn: "China Postal Airlines",
            nameDe: "China Postal Airlines"
        },
        {
            iataCode: "CZ",
            iataLabel: "CZ",
            icaoCode: "CSN",
            icaoLabel: "CSN",
            nameTr: "China Southern Airlines",
            nameEn: "China Southern Airlines",
            nameDe: "China Southern Airlines"
        },
        {
            iataCode: "WX",
            iataLabel: "WX",
            icaoCode: "BCY",
            icaoLabel: "BCY",
            nameTr: "CityJet",
            nameEn: "CityJet",
            nameDe: "CityJet"
        },
        {
            iataCode: "DE",
            iataLabel: "DE",
            icaoCode: "CFG",
            icaoLabel: "CFG",
            nameTr: "Condor",
            nameEn: "Condor",
            nameDe: "Condor"
        },
        {
            iataCode: "8Z",
            iataLabel: "8Z",
            icaoCode: "CGA",
            icaoLabel: "CGA",
            nameTr: "Congo Airways",
            nameEn: "Congo Airways",
            nameDe: "Congo Airways"
        },
        {
            iataCode: "CM",
            iataLabel: "CM",
            icaoCode: "CMP",
            icaoLabel: "CMP",
            nameTr: "COPA Airlines",
            nameEn: "COPA Airlines",
            nameDe: "COPA Airlines"
        },
        {
            iataCode: "XC",
            iataLabel: "XC",
            icaoCode: "CAI",
            icaoLabel: "CAI",
            nameTr: "Corendon Airlines",
            nameEn: "Corendon Airlines",
            nameDe: "Corendon Airlines"
        },
        {
            iataCode: "SS",
            iataLabel: "SS",
            icaoCode: "CRL",
            icaoLabel: "CRL",
            nameTr: "Corsair International",
            nameEn: "Corsair International",
            nameDe: "Corsair International"
        },
        {
            iataCode: "OU",
            iataLabel: "OU",
            icaoCode: "CTN",
            icaoLabel: "CTN",
            nameTr: "Croatia Airlines",
            nameEn: "Croatia Airlines",
            nameDe: "Croatia Airlines"
        },
        {
            iataCode: "CU",
            iataLabel: "CU",
            icaoCode: "CUB",
            icaoLabel: "CUB",
            nameTr: "Cubana",
            nameEn: "Cubana",
            nameDe: "Cubana"
        },
        {
            iataCode: "CY",
            iataLabel: "CY",
            icaoCode: "CYP",
            icaoLabel: "CYP",
            nameTr: "Cyprus Airways",
            nameEn: "Cyprus Airways",
            nameDe: "Cyprus Airways"
        },
        {
            iataCode: "OK",
            iataLabel: "OK",
            icaoCode: "CSA",
            icaoLabel: "CSA",
            nameTr: "Czech Airlines",
            nameEn: "Czech Airlines",
            nameDe: "Czech Airlines"
        },
        {
            iataCode: "DL",
            iataLabel: "DL",
            icaoCode: "DAL",
            icaoLabel: "DAL",
            nameTr: "Delta Air Lines",
            nameEn: "Delta Air Lines",
            nameDe: "Delta Air Lines"
        },
        {
            iataCode: "D0",
            iataLabel: "D0",
            icaoCode: "DHK",
            icaoLabel: "DHK",
            nameTr: "DHL Air",
            nameEn: "DHL Air",
            nameDe: "DHL Air"
        },
        {
            iataCode: "ES",
            iataLabel: "ES",
            icaoCode: "DHX",
            icaoLabel: "DHX",
            nameTr: "DHL Aviation",
            nameEn: "DHL Aviation",
            nameDe: "DHL Aviation"
        },
        {
            iataCode: "T3",
            iataLabel: "T3",
            icaoCode: "EZE",
            icaoLabel: "EZE",
            nameTr: "Eastern Airways",
            nameEn: "Eastern Airways",
            nameDe: "Eastern Airways"
        },
        {
            iataCode: "MS",
            iataLabel: "MS",
            icaoCode: "MSR",
            icaoLabel: "MSR",
            nameTr: "Egyptair",
            nameEn: "Egyptair",
            nameDe: "Egyptair"
        },
        {
            iataCode: "LY",
            iataLabel: "LY",
            icaoCode: "ELY",
            icaoLabel: "ELY",
            nameTr: "EL AL",
            nameEn: "EL AL",
            nameDe: "EL AL"
        },
        {
            iataCode: "EK",
            iataLabel: "EK",
            icaoCode: "UAE",
            icaoLabel: "UAE",
            nameTr: "Emirates",
            nameEn: "Emirates",
            nameDe: "Emirates"
        },
        {
            iataCode: "ET",
            iataLabel: "ET",
            icaoCode: "ETH",
            icaoLabel: "ETH",
            nameTr: "Ethiopian Airlines",
            nameEn: "Ethiopian Airlines",
            nameDe: "Ethiopian Airlines"
        },
        {
            iataCode: "EY",
            iataLabel: "EY",
            icaoCode: "ETD",
            icaoLabel: "ETD",
            nameTr: "Etihad Airways",
            nameEn: "Etihad Airways",
            nameDe: "Etihad Airways"
        },
        {
            iataCode: "YU",
            iataLabel: "YU",
            icaoCode: "MMZ",
            icaoLabel: "MMZ",
            nameTr: "EuroAtlantic Airways",
            nameEn: "EuroAtlantic Airways",
            nameDe: "EuroAtlantic Airways"
        },
        {
            iataCode: "QY",
            iataLabel: "QY",
            icaoCode: "BCS",
            icaoLabel: "BCS",
            nameTr: "European Air Transport",
            nameEn: "European Air Transport",
            nameDe: "European Air Transport"
        },
        {
            iataCode: "EW",
            iataLabel: "EW",
            icaoCode: "EWG",
            icaoLabel: "EWG",
            nameTr: "Eurowings",
            nameEn: "Eurowings",
            nameDe: "Eurowings"
        },
        {
            iataCode: "BR",
            iataLabel: "BR",
            icaoCode: "EVA",
            icaoLabel: "EVA",
            nameTr: "EVA Air",
            nameEn: "EVA Air",
            nameDe: "EVA Air"
        },
        {
            iataCode: "E9",
            iataLabel: "E9",
            icaoCode: "EVE",
            icaoLabel: "EVE",
            nameTr: "Evelop Airlines",
            nameEn: "Evelop Airlines",
            nameDe: "Evelop Airlines"
        },
        {
            iataCode: "FX",
            iataLabel: "FX",
            icaoCode: "FDX",
            icaoLabel: "FDX",
            nameTr: "FedEx Express",
            nameEn: "FedEx Express",
            nameDe: "FedEx Express"
        },
        {
            iataCode: "FJ",
            iataLabel: "FJ",
            icaoCode: "FJI",
            icaoLabel: "FJI",
            nameTr: "Fiji Airways",
            nameEn: "Fiji Airways",
            nameDe: "Fiji Airways"
        },
        {
            iataCode: "AY",
            iataLabel: "AY",
            icaoCode: "FIN",
            icaoLabel: "FIN",
            nameTr: "Finnair",
            nameEn: "Finnair",
            nameDe: "Finnair"
        },
        {
            iataCode: "FZ",
            iataLabel: "FZ",
            icaoCode: "FDB",
            icaoLabel: "FDB",
            nameTr: "flydubai",
            nameEn: "flydubai",
            nameDe: "flydubai"
        },
        {
            iataCode: "FT",
            iataLabel: "FT",
            icaoCode: "FEG",
            icaoLabel: "FEG",
            nameTr: "FlyEgypt",
            nameEn: "FlyEgypt",
            nameDe: "FlyEgypt"
        },
        {
            iataCode: "XY",
            iataLabel: "XY",
            icaoCode: "KNE",
            icaoLabel: "KNE",
            nameTr: "Flynas",
            nameEn: "Flynas",
            nameDe: "Flynas"
        },
        {
            iataCode: "FH",
            iataLabel: "FH",
            icaoCode: "FHY",
            icaoLabel: "FHY",
            nameTr: "Freebird Airlines",
            nameEn: "Freebird Airlines",
            nameDe: "Freebird Airlines"
        },
        {
            iataCode: "BF",
            iataLabel: "BF",
            icaoCode: "FBU",
            icaoLabel: "FBU",
            nameTr: "French Bee",
            nameEn: "French Bee",
            nameDe: "French Bee"
        },
        {
            iataCode: "FU",
            iataLabel: "FU",
            icaoCode: "FZA",
            icaoLabel: "FZA",
            nameTr: "Fuzhou Airlines",
            nameEn: "Fuzhou Airlines",
            nameDe: "Fuzhou Airlines"
        },
        {
            iataCode: "GA",
            iataLabel: "GA",
            icaoCode: "GIA",
            icaoLabel: "GIA",
            nameTr: "Garuda Indonesia",
            nameEn: "Garuda Indonesia",
            nameDe: "Garuda Indonesia"
        },
        {
            iataCode: "A9",
            iataLabel: "A9",
            icaoCode: "TGZ",
            icaoLabel: "TGZ",
            nameTr: "Georgian Airways",
            nameEn: "Georgian Airways",
            nameDe: "Georgian Airways"
        },
        {
            iataCode: "WH",
            iataLabel: "WH",
            icaoCode: "WDL",
            icaoLabel: "WDL",
            nameTr: "German Airways",
            nameEn: "German Airways",
            nameDe: "German Airways"
        },
        {
            iataCode: "G3",
            iataLabel: "G3",
            icaoCode: "GLO",
            icaoLabel: "GLO",
            nameTr: "GOL Linhas Aereas",
            nameEn: "GOL Linhas Aereas",
            nameDe: "GOL Linhas Aereas"
        },
        {
            iataCode: "GF",
            iataLabel: "GF",
            icaoCode: "GFA",
            icaoLabel: "GFA",
            nameTr: "Gulf Air",
            nameEn: "Gulf Air",
            nameDe: "Gulf Air"
        },
        {
            iataCode: "GX",
            iataLabel: "GX",
            icaoCode: "CBG",
            icaoLabel: "CBG",
            nameTr: "GX Airlines",
            nameEn: "GX Airlines",
            nameDe: "GX Airlines"
        },
        {
            iataCode: "HR",
            iataLabel: "HR",
            icaoCode: "HHN",
            icaoLabel: "HHN",
            nameTr: "Hahn Air",
            nameEn: "Hahn Air",
            nameDe: "Hahn Air"
        },
        {
            iataCode: "HU",
            iataLabel: "HU",
            icaoCode: "CHH",
            icaoLabel: "CHH",
            nameTr: "Hainan Airlines",
            nameEn: "Hainan Airlines",
            nameDe: "Hainan Airlines"
        },
        {
            iataCode: "HA",
            iataLabel: "HA",
            icaoCode: "HAL",
            icaoLabel: "HAL",
            nameTr: "Hawaiian Airlines",
            nameEn: "Hawaiian Airlines",
            nameDe: "Hawaiian Airlines"
        },
        {
            iataCode: "NS",
            iataLabel: "NS",
            icaoCode: "HBH",
            icaoLabel: "HBH",
            nameTr: "Hebei Airlines",
            nameEn: "Hebei Airlines",
            nameDe: "Hebei Airlines"
        },
        {
            iataCode: "5K",
            iataLabel: "5K",
            icaoCode: "HFY",
            icaoLabel: "HFY",
            nameTr: "Hi Fly",
            nameEn: "Hi Fly",
            nameDe: "Hi Fly"
        },
        {
            iataCode: "RH",
            iataLabel: "RH",
            icaoCode: "HKC",
            icaoLabel: "HKC",
            nameTr: "Hong Kong Air Cargo",
            nameEn: "Hong Kong Air Cargo",
            nameDe: "Hong Kong Air Cargo"
        },
        {
            iataCode: "HX",
            iataLabel: "HX",
            icaoCode: "CRK",
            icaoLabel: "CRK",
            nameTr: "Hong Kong Airlines",
            nameEn: "Hong Kong Airlines",
            nameDe: "Hong Kong Airlines"
        },
        {
            iataCode: "UO",
            iataLabel: "UO",
            icaoCode: "HKE",
            icaoLabel: "HKE",
            nameTr: "Hong Kong Express Airways",
            nameEn: "Hong Kong Express Airways",
            nameDe: "Hong Kong Express Airways"
        },
        {
            iataCode: "IB",
            iataLabel: "IB",
            icaoCode: "IBE",
            icaoLabel: "IBE",
            nameTr: "IBERIA",
            nameEn: "IBERIA",
            nameDe: "IBERIA"
        },
        {
            iataCode: "FI",
            iataLabel: "FI",
            icaoCode: "ICE",
            icaoLabel: "ICE",
            nameTr: "Icelandair",
            nameEn: "Icelandair",
            nameDe: "Icelandair"
        },
        {
            iataCode: "6E",
            iataLabel: "6E",
            icaoCode: "IGO",
            icaoLabel: "IGO",
            nameTr: "IndiGo",
            nameEn: "IndiGo",
            nameDe: "IndiGo"
        },
        {
            iataCode: "4O",
            iataLabel: "4O",
            icaoCode: "AIJ",
            icaoLabel: "AIJ",
            nameTr: "Interjet",
            nameEn: "Interjet",
            nameDe: "Interjet"
        },
        {
            iataCode: "IR",
            iataLabel: "IR",
            icaoCode: "IRA",
            icaoLabel: "IRA",
            nameTr: "Iran Air",
            nameEn: "Iran Air",
            nameDe: "Iran Air"
        },
        {
            iataCode: "B9",
            iataLabel: "B9",
            icaoCode: "IRB",
            icaoLabel: "IRB",
            nameTr: "Iran Airtour Airline",
            nameEn: "Iran Airtour Airline",
            nameDe: "Iran Airtour Airline"
        },
        {
            iataCode: "EP",
            iataLabel: "EP",
            icaoCode: "IRC",
            icaoLabel: "IRC",
            nameTr: "Iran Aseman Airlines",
            nameEn: "Iran Aseman Airlines",
            nameDe: "Iran Aseman Airlines"
        },
        {
            iataCode: "6H",
            iataLabel: "6H",
            icaoCode: "ISR",
            icaoLabel: "ISR",
            nameTr: "Israir",
            nameEn: "Israir",
            nameDe: "Israir"
        },
        {
            iataCode: "JL",
            iataLabel: "JL",
            icaoCode: "JAL",
            icaoLabel: "JAL",
            nameTr: "Japan Airlines",
            nameEn: "Japan Airlines",
            nameDe: "Japan Airlines"
        },
        {
            iataCode: "NU",
            iataLabel: "NU",
            icaoCode: "JTA",
            icaoLabel: "JTA",
            nameTr: "Japan Transocean Air",
            nameEn: "Japan Transocean Air",
            nameDe: "Japan Transocean Air"
        },
        {
            iataCode: "J9",
            iataLabel: "J9",
            icaoCode: "JZR",
            icaoLabel: "JZR",
            nameTr: "Jazeera Airways",
            nameEn: "Jazeera Airways",
            nameDe: "Jazeera Airways"
        },
        {
            iataCode: "7C",
            iataLabel: "7C",
            icaoCode: "JJA",
            icaoLabel: "JJA",
            nameTr: "Jeju Air",
            nameEn: "Jeju Air",
            nameDe: "Jeju Air"
        },
        {
            iataCode: "B6",
            iataLabel: "B6",
            icaoCode: "JBU",
            icaoLabel: "JBU",
            nameTr: "JetBlue",
            nameEn: "JetBlue",
            nameDe: "JetBlue"
        },
        {
            iataCode: "LJ",
            iataLabel: "LJ",
            icaoCode: "JNA",
            icaoLabel: "JNA",
            nameTr: "Jin Air",
            nameEn: "Jin Air",
            nameDe: "Jin Air"
        },
        {
            iataCode: "R5",
            iataLabel: "R5",
            icaoCode: "JAV",
            icaoLabel: "JAV",
            nameTr: "Jordan Aviation",
            nameEn: "Jordan Aviation",
            nameDe: "Jordan Aviation"
        },
        {
            iataCode: "HO",
            iataLabel: "HO",
            icaoCode: "DKH",
            icaoLabel: "DKH",
            nameTr: "Juneyao Airlines",
            nameEn: "Juneyao Airlines",
            nameDe: "Juneyao Airlines"
        },
        {
            iataCode: "RQ",
            iataLabel: "RQ",
            icaoCode: "KMF",
            icaoLabel: "KMF",
            nameTr: "Kam Air",
            nameEn: "Kam Air",
            nameDe: "Kam Air"
        },
        {
            iataCode: "KQ",
            iataLabel: "KQ",
            icaoCode: "KQA",
            icaoLabel: "KQA",
            nameTr: "Kenya Airways",
            nameEn: "Kenya Airways",
            nameDe: "Kenya Airways"
        },
        {
            iataCode: "KL",
            iataLabel: "KL",
            icaoCode: "KLM",
            icaoLabel: "KLM",
            nameTr: "KLM",
            nameEn: "KLM",
            nameDe: "KLM"
        },
        {
            iataCode: "KE",
            iataLabel: "KE",
            icaoCode: "KAL",
            icaoLabel: "KAL",
            nameTr: "Korean Air",
            nameEn: "Korean Air",
            nameDe: "Korean Air"
        },
        {
            iataCode: "KY",
            iataLabel: "KY",
            icaoCode: "KNA",
            icaoLabel: "KNA",
            nameTr: "Kunming Airlines",
            nameEn: "Kunming Airlines",
            nameDe: "Kunming Airlines"
        },
        {
            iataCode: "KU",
            iataLabel: "KU",
            icaoCode: "KAC",
            icaoLabel: "KAC",
            nameTr: "Kuwait Airways",
            nameEn: "Kuwait Airways",
            nameDe: "Kuwait Airways"
        },
        {
            iataCode: "TM",
            iataLabel: "TM",
            icaoCode: "LAM",
            icaoLabel: "LAM",
            nameTr: "LAM",
            nameEn: "LAM",
            nameDe: "LAM"
        },
        {
            iataCode: "QV",
            iataLabel: "QV",
            icaoCode: "LAO",
            icaoLabel: "LAO",
            nameTr: "Lao Airlines",
            nameEn: "Lao Airlines",
            nameDe: "Lao Airlines"
        },
        {
            iataCode: "JJ",
            iataLabel: "JJ",
            icaoCode: "TAM",
            icaoLabel: "TAM",
            nameTr: "LATAM Airlines Brasil",
            nameEn: "LATAM Airlines Brasil",
            nameDe: "LATAM Airlines Brasil"
        },
        {
            iataCode: "4C",
            iataLabel: "4C",
            icaoCode: "ARE",
            icaoLabel: "ARE",
            nameTr: "LATAM Airlines Colombia",
            nameEn: "LATAM Airlines Colombia",
            nameDe: "LATAM Airlines Colombia"
        },
        {
            iataCode: "XL",
            iataLabel: "XL",
            icaoCode: "LNE",
            icaoLabel: "LNE",
            nameTr: "LATAM Airlines Ecuador",
            nameEn: "LATAM Airlines Ecuador",
            nameDe: "LATAM Airlines Ecuador"
        },
        {
            iataCode: "LA",
            iataLabel: "LA",
            icaoCode: "LAN",
            icaoLabel: "LAN",
            nameTr: "LATAM Airlines Group",
            nameEn: "LATAM Airlines Group",
            nameDe: "LATAM Airlines Group"
        },
        {
            iataCode: "PZ",
            iataLabel: "PZ",
            icaoCode: "LAP",
            icaoLabel: "LAP",
            nameTr: "LATAM Airlines Paraguay",
            nameEn: "LATAM Airlines Paraguay",
            nameDe: "LATAM Airlines Paraguay"
        },
        {
            iataCode: "LP",
            iataLabel: "LP",
            icaoCode: "LPE",
            icaoLabel: "LPE",
            nameTr: "LATAM Airlines Peru",
            nameEn: "LATAM Airlines Peru",
            nameDe: "LATAM Airlines Peru"
        },
        {
            iataCode: "M3",
            iataLabel: "M3",
            icaoCode: "LTG",
            icaoLabel: "LTG",
            nameTr: "LATAM Cargo Brasil",
            nameEn: "LATAM Cargo Brasil",
            nameDe: "LATAM Cargo Brasil"
        },
        {
            iataCode: "UC",
            iataLabel: "UC",
            icaoCode: "LCO",
            icaoLabel: "LCO",
            nameTr: "LATAM Cargo Chile",
            nameEn: "LATAM Cargo Chile",
            nameDe: "LATAM Cargo Chile"
        },
        {
            iataCode: "LI",
            iataLabel: "LI",
            icaoCode: "LIA",
            icaoLabel: "LIA",
            nameTr: "LIAT Airlines",
            nameEn: "LIAT Airlines",
            nameDe: "LIAT Airlines"
        },
        {
            iataCode: "GJ",
            iataLabel: "GJ",
            icaoCode: "CDC",
            icaoLabel: "CDC",
            nameTr: "Loong Air",
            nameEn: "Loong Air",
            nameDe: "Loong Air"
        },
        {
            iataCode: "LO",
            iataLabel: "LO",
            icaoCode: "LOT",
            icaoLabel: "LOT",
            nameTr: "LOT Polish",
            nameEn: "LOT Polish",
            nameDe: "LOT Polish"
        },
        {
            iataCode: "8L",
            iataLabel: "8L",
            icaoCode: "LKE",
            icaoLabel: "LKE",
            nameTr: "Lucky Air",
            nameEn: "Lucky Air",
            nameDe: "Lucky Air"
        },
        {
            iataCode: "LH",
            iataLabel: "LH",
            icaoCode: "DLH",
            icaoLabel: "DLH",
            nameTr: "Lufthansa",
            nameEn: "Lufthansa",
            nameDe: "Lufthansa"
        },
        {
            iataCode: "CL",
            iataLabel: "CL",
            icaoCode: "CLH",
            icaoLabel: "CLH",
            nameTr: "Lufthansa CityLine",
            nameEn: "Lufthansa CityLine",
            nameDe: "Lufthansa CityLine"
        },
        {
            iataCode: "LG",
            iataLabel: "LG",
            icaoCode: "LGL",
            icaoLabel: "LGL",
            nameTr: "Luxair",
            nameEn: "Luxair",
            nameDe: "Luxair"
        },
        {
            iataCode: "W5",
            iataLabel: "W5",
            icaoCode: "IRM",
            icaoLabel: "IRM",
            nameTr: "Mahan Air",
            nameEn: "Mahan Air",
            nameDe: "Mahan Air"
        },
        {
            iataCode: "MH",
            iataLabel: "MH",
            icaoCode: "MAS",
            icaoLabel: "MAS",
            nameTr: "Malaysia Airlines",
            nameEn: "Malaysia Airlines",
            nameDe: "Malaysia Airlines"
        },
        {
            iataCode: "OD",
            iataLabel: "OD",
            icaoCode: "MXD",
            icaoLabel: "MXD",
            nameTr: "Malindo Air",
            nameEn: "Malindo Air",
            nameDe: "Malindo Air"
        },
        {
            iataCode: "AE",
            iataLabel: "AE",
            icaoCode: "MDA",
            icaoLabel: "MDA",
            nameTr: "Mandarin Airlines",
            nameEn: "Mandarin Airlines",
            nameDe: "Mandarin Airlines"
        },
        {
            iataCode: "MP",
            iataLabel: "MP",
            icaoCode: "MPH",
            icaoLabel: "MPH",
            nameTr: "Martinair Cargo",
            nameEn: "Martinair Cargo",
            nameDe: "Martinair Cargo"
        },
        {
            iataCode: "M7",
            iataLabel: "M7",
            icaoCode: "MAA",
            icaoLabel: "MAA",
            nameTr: "MasAir",
            nameEn: "MasAir",
            nameDe: "MasAir"
        },
        {
            iataCode: "L6",
            iataLabel: "L6",
            icaoCode: "MAI",
            icaoLabel: "MAI",
            nameTr: "Mauritania Airlines International",
            nameEn: "Mauritania Airlines International",
            nameDe: "Mauritania Airlines International"
        },
        {
            iataCode: "ME",
            iataLabel: "ME",
            icaoCode: "MEA",
            icaoLabel: "MEA",
            nameTr: "Middle East Airlines",
            nameEn: "Middle East Airlines",
            nameDe: "Middle East Airlines"
        },
        {
            iataCode: "OM",
            iataLabel: "OM",
            icaoCode: "MGL",
            icaoLabel: "MGL",
            nameTr: "MIAT Mongolian Airlines",
            nameEn: "MIAT Mongolian Airlines",
            nameDe: "MIAT Mongolian Airlines"
        },
        {
            iataCode: "MB",
            iataLabel: "MB",
            icaoCode: "MNB",
            icaoLabel: "MNB",
            nameTr: "MNG Airlines",
            nameEn: "MNG Airlines",
            nameDe: "MNG Airlines"
        },
        {
            iataCode: "YM",
            iataLabel: "YM",
            icaoCode: "MGX",
            icaoLabel: "MGX",
            nameTr: "Montenegro Airlines",
            nameEn: "Montenegro Airlines",
            nameDe: "Montenegro Airlines"
        },
        {
            iataCode: "8M",
            iataLabel: "8M",
            icaoCode: "MMA",
            icaoLabel: "MMA",
            nameTr: "Myanmar Airways International",
            nameEn: "Myanmar Airways International",
            nameDe: "Myanmar Airways International"
        },
        {
            iataCode: "KZ",
            iataLabel: "KZ",
            icaoCode: "NCA",
            icaoLabel: "NCA",
            nameTr: "NCA Nippon Cargo Airlines",
            nameEn: "NCA Nippon Cargo Airlines",
            nameDe: "NCA Nippon Cargo Airlines"
        },
        {
            iataCode: "NO",
            iataLabel: "NO",
            icaoCode: "NOS",
            icaoLabel: "NOS",
            nameTr: "Neos",
            nameEn: "Neos",
            nameDe: "Neos"
        },
        {
            iataCode: "NE",
            iataLabel: "NE",
            icaoCode: "NMA",
            icaoLabel: "NMA",
            nameTr: "Nesma Airlines",
            nameEn: "Nesma Airlines",
            nameDe: "Nesma Airlines"
        },
        {
            iataCode: "NP",
            iataLabel: "NP",
            icaoCode: "NIA",
            icaoLabel: "NIA",
            nameTr: "Nile Air",
            nameEn: "Nile Air",
            nameDe: "Nile Air"
        },
        {
            iataCode: "5N",
            iataLabel: "5N",
            icaoCode: "AUL",
            icaoLabel: "AUL",
            nameTr: "Nordavia Regional Airlines",
            nameEn: "Nordavia Regional Airlines",
            nameDe: "Nordavia Regional Airlines"
        },
        {
            iataCode: "Y7",
            iataLabel: "Y7",
            icaoCode: "TYA",
            icaoLabel: "TYA",
            nameTr: "NordStar",
            nameEn: "NordStar",
            nameDe: "NordStar"
        },
        {
            iataCode: "N4",
            iataLabel: "N4",
            icaoCode: "NWS",
            icaoLabel: "NWS",
            nameTr: "Nordwind Airlines",
            nameEn: "Nordwind Airlines",
            nameDe: "Nordwind Airlines"
        },
        {
            iataCode: "BJ",
            iataLabel: "BJ",
            icaoCode: "LBT",
            icaoLabel: "LBT",
            nameTr: "Nouvelair",
            nameEn: "Nouvelair",
            nameDe: "Nouvelair"
        },
        {
            iataCode: "BK",
            iataLabel: "BK",
            icaoCode: "OKA",
            icaoLabel: "OKA",
            nameTr: "Okay Airways",
            nameEn: "Okay Airways",
            nameDe: "Okay Airways"
        },
        {
            iataCode: "OA",
            iataLabel: "OA",
            icaoCode: "OAL",
            icaoLabel: "OAL",
            nameTr: "Olympic Air",
            nameEn: "Olympic Air",
            nameDe: "Olympic Air"
        },
        {
            iataCode: "WY",
            iataLabel: "WY",
            icaoCode: "OMA",
            icaoLabel: "OMA",
            nameTr: "Oman Air",
            nameEn: "Oman Air",
            nameDe: "Oman Air"
        },
        {
            iataCode: "8Q",
            iataLabel: "8Q",
            icaoCode: "OHY",
            icaoLabel: "OHY",
            nameTr: "Onur Air",
            nameEn: "Onur Air",
            nameDe: "Onur Air"
        },
        {
            iataCode: "OF",
            iataLabel: "OF",
            icaoCode: "OLA",
            icaoLabel: "OLA",
            nameTr: "Overland Airways",
            nameEn: "Overland Airways",
            nameDe: "Overland Airways"
        },
        {
            iataCode: "ZP",
            iataLabel: "ZP",
            icaoCode: "AZP",
            icaoLabel: "AZP",
            nameTr: "Paranair",
            nameEn: "Paranair",
            nameDe: "Paranair"
        },
        {
            iataCode: "EO",
            iataLabel: "EO",
            icaoCode: "KAR",
            icaoLabel: "KAR",
            nameTr: "Pegas Fly",
            nameEn: "Pegas Fly",
            nameDe: "Pegas Fly"
        },
        {
            iataCode: "PC",
            iataLabel: "PC",
            icaoCode: "PGT",
            icaoLabel: "PGT",
            nameTr: "Pegasus Airlines",
            nameEn: "Pegasus Airlines",
            nameDe: "Pegasus Airlines"
        },
        {
            iataCode: "NI",
            iataLabel: "NI",
            icaoCode: "PGA",
            icaoLabel: "PGA",
            nameTr: "PGA-Portugalia Airlines",
            nameEn: "PGA-Portugalia Airlines",
            nameDe: "PGA-Portugalia Airlines"
        },
        {
            iataCode: "PR",
            iataLabel: "PR",
            icaoCode: "PAL",
            icaoLabel: "PAL",
            nameTr: "Philippine Airlines",
            nameEn: "Philippine Airlines",
            nameDe: "Philippine Airlines"
        },
        {
            iataCode: "PK",
            iataLabel: "PK",
            icaoCode: "PIA",
            icaoLabel: "PIA",
            nameTr: "PIA Pakistan International Airlines",
            nameEn: "PIA Pakistan International Airlines",
            nameDe: "PIA Pakistan International Airlines"
        },
        {
            iataCode: "PO",
            iataLabel: "PO",
            icaoCode: "PAC",
            icaoLabel: "PAC",
            nameTr: "Polar Air Cargo",
            nameEn: "Polar Air Cargo",
            nameDe: "Polar Air Cargo"
        },
        {
            iataCode: "M4",
            iataLabel: "M4",
            icaoCode: "MGA",
            icaoLabel: "MGA",
            nameTr: "MAVI GOK HAVAYOLU",
            nameEn: "MAVI GOK AIRLINE",
            nameDe: "MAVI GOK AIRLINE"
        },
        {
            iataCode: "PW",
            iataLabel: "PW",
            icaoCode: "PRF",
            icaoLabel: "PRF",
            nameTr: "Precision Air",
            nameEn: "Precision Air",
            nameDe: "Precision Air"
        },
        {
            iataCode: "P6",
            iataLabel: "P6",
            icaoCode: "PVG",
            icaoLabel: "PVG",
            nameTr: "Privilege Style",
            nameEn: "Privilege Style",
            nameDe: "Privilege Style"
        },
        {
            iataCode: "QF",
            iataLabel: "QF",
            icaoCode: "QFA",
            icaoLabel: "QFA",
            nameTr: "Qantas",
            nameEn: "Qantas",
            nameDe: "Qantas"
        },
        {
            iataCode: "QR",
            iataLabel: "QR",
            icaoCode: "QTR",
            icaoLabel: "QTR",
            nameTr: "Qatar Airways",
            nameEn: "Qatar Airways",
            nameDe: "Qatar Airways"
        },
        {
            iataCode: "IQ",
            iataLabel: "IQ",
            icaoCode: "QAZ",
            icaoLabel: "QAZ",
            nameTr: "Qazaq Air",
            nameEn: "Qazaq Air",
            nameDe: "Qazaq Air"
        },
        {
            iataCode: "FV",
            iataLabel: "FV",
            icaoCode: "SDM",
            icaoLabel: "SDM",
            nameTr: "Rossiya Airlines",
            nameEn: "Rossiya Airlines",
            nameDe: "Rossiya Airlines"
        },
        {
            iataCode: "AT",
            iataLabel: "AT",
            icaoCode: "RAM",
            icaoLabel: "RAM",
            nameTr: "Royal Air Maroc",
            nameEn: "Royal Air Maroc",
            nameDe: "Royal Air Maroc"
        },
        {
            iataCode: "BI",
            iataLabel: "BI",
            icaoCode: "RBA",
            icaoLabel: "RBA",
            nameTr: "Royal Brunei",
            nameEn: "Royal Brunei",
            nameDe: "Royal Brunei"
        },
        {
            iataCode: "RJ",
            iataLabel: "RJ",
            icaoCode: "RJA",
            icaoLabel: "RJA",
            nameTr: "Royal Jordanian",
            nameEn: "Royal Jordanian",
            nameDe: "Royal Jordanian"
        },
        {
            iataCode: "DR",
            iataLabel: "DR",
            icaoCode: "RLH",
            icaoLabel: "RLH",
            nameTr: "Ruili Airlines",
            nameEn: "Ruili Airlines",
            nameDe: "Ruili Airlines"
        },
        {
            iataCode: "7R",
            iataLabel: "7R",
            icaoCode: "RLU",
            icaoLabel: "RLU",
            nameTr: "RusLine",
            nameEn: "RusLine",
            nameDe: "RusLine"
        },
        {
            iataCode: "WB",
            iataLabel: "WB",
            icaoCode: "RWD",
            icaoLabel: "RWD",
            nameTr: "RwandAir",
            nameEn: "RwandAir",
            nameDe: "RwandAir"
        },
        {
            iataCode: "S7",
            iataLabel: "S7",
            icaoCode: "SBI",
            icaoLabel: "SBI",
            nameTr: "S7 Airlines",
            nameEn: "S7 Airlines",
            nameDe: "S7 Airlines"
        },
        {
            iataCode: "FA",
            iataLabel: "FA",
            icaoCode: "SFR",
            icaoLabel: "SFR",
            nameTr: "Safair",
            nameEn: "Safair",
            nameDe: "Safair"
        },
        {
            iataCode: "SK",
            iataLabel: "SK",
            icaoCode: "SAS",
            icaoLabel: "SAS",
            nameTr: "SAS",
            nameEn: "SAS",
            nameDe: "SAS"
        },
        {
            iataCode: "SP",
            iataLabel: "SP",
            icaoCode: "SAT",
            icaoLabel: "SAT",
            nameTr: "SATA  Air Acores",
            nameEn: "SATA  Air Acores",
            nameDe: "SATA  Air Acores"
        },
        {
            iataCode: "SV",
            iataLabel: "SV",
            icaoCode: "SVA",
            icaoLabel: "SVA",
            nameTr: "Saudi Arabian Airlines",
            nameEn: "Saudi Arabian Airlines",
            nameDe: "Saudi Arabian Airlines"
        },
        {
            iataCode: "6S",
            iataLabel: "6S",
            icaoCode: "SGQ",
            icaoLabel: "SGQ",
            nameTr: "SaudiGulf Airlines",
            nameEn: "SaudiGulf Airlines",
            nameDe: "SaudiGulf Airlines"
        },
        {
            iataCode: "DV",
            iataLabel: "DV",
            icaoCode: "VSV",
            icaoLabel: "VSV",
            nameTr: "SCAT Airlines",
            nameEn: "SCAT Airlines",
            nameDe: "SCAT Airlines"
        },
        {
            iataCode: "O3",
            iataLabel: "O3",
            icaoCode: "CSS",
            icaoLabel: "CSS",
            nameTr: "SF Airlines",
            nameEn: "SF Airlines",
            nameDe: "SF Airlines"
        },
        {
            iataCode: "SC",
            iataLabel: "SC",
            icaoCode: "CDG",
            icaoLabel: "CDG",
            nameTr: "Shandong Airlines",
            nameEn: "Shandong Airlines",
            nameDe: "Shandong Airlines"
        },
        {
            iataCode: "FM",
            iataLabel: "FM",
            icaoCode: "CSH",
            icaoLabel: "CSH",
            nameTr: "Shanghai Airlines",
            nameEn: "Shanghai Airlines",
            nameDe: "Shanghai Airlines"
        },
        {
            iataCode: "ZH",
            iataLabel: "ZH",
            icaoCode: "CSZ",
            icaoLabel: "CSZ",
            nameTr: "Shenzhen Airlines",
            nameEn: "Shenzhen Airlines",
            nameDe: "Shenzhen Airlines"
        },
        {
            iataCode: "3U",
            iataLabel: "3U",
            icaoCode: "CSC",
            icaoLabel: "CSC",
            nameTr: "Sichuan Airlines",
            nameEn: "Sichuan Airlines",
            nameDe: "Sichuan Airlines"
        },
        {
            iataCode: "7L",
            iataLabel: "7L",
            icaoCode: "AZG",
            icaoLabel: "AZG",
            nameTr: "Silk Way West Airlines",
            nameEn: "Silk Way West Airlines",
            nameDe: "Silk Way West Airlines"
        },
        {
            iataCode: "MI",
            iataLabel: "MI",
            icaoCode: "FHM",
            icaoLabel: "FHM",
            nameTr: "Freebird Europe",
            nameEn: "Freebird Europe",
            nameDe: "Freebird Europe"
        },
        {
            iataCode: "SQ",
            iataLabel: "SQ",
            icaoCode: "SIA",
            icaoLabel: "SIA",
            nameTr: "Singapore Airlines",
            nameEn: "Singapore Airlines",
            nameDe: "Singapore Airlines"
        },
        {
            iataCode: "H2",
            iataLabel: "H2",
            icaoCode: "SKU",
            icaoLabel: "SKU",
            nameTr: "SKY Airline",
            nameEn: "SKY Airline",
            nameDe: "SKY Airline"
        },
        {
            iataCode: "IE",
            iataLabel: "IE",
            icaoCode: "SOL",
            icaoLabel: "SOL",
            nameTr: "Solomon Airlines",
            nameEn: "Solomon Airlines",
            nameDe: "Solomon Airlines"
        },
        {
            iataCode: "SZ",
            iataLabel: "SZ",
            icaoCode: "SMR",
            icaoLabel: "SMR",
            nameTr: "Somon Air",
            nameEn: "Somon Air",
            nameDe: "Somon Air"
        },
        {
            iataCode: "SA",
            iataLabel: "SA",
            icaoCode: "SAA",
            icaoLabel: "SAA",
            nameTr: "South African Airways",
            nameEn: "South African Airways",
            nameDe: "South African Airways"
        },
        {
            iataCode: "SG",
            iataLabel: "SG",
            icaoCode: "SEJ",
            icaoLabel: "SEJ",
            nameTr: "SpiceJet",
            nameEn: "SpiceJet",
            nameDe: "SpiceJet"
        },
        {
            iataCode: "UL",
            iataLabel: "UL",
            icaoCode: "ALK",
            icaoLabel: "ALK",
            nameTr: "SriLankan Airlines",
            nameEn: "SriLankan Airlines",
            nameDe: "SriLankan Airlines"
        },
        {
            iataCode: "XQ",
            iataLabel: "XQ",
            icaoCode: "SXS",
            icaoLabel: "SXS",
            nameTr: "SunExpress",
            nameEn: "SunExpress",
            nameDe: "SunExpress"
        },
        {
            iataCode: "Y8",
            iataLabel: "Y8",
            icaoCode: "YZR",
            icaoLabel: "YZR",
            nameTr: "Suparna Airlines",
            nameEn: "Suparna Airlines",
            nameDe: "Suparna Airlines"
        },
        {
            iataCode: "PY",
            iataLabel: "PY",
            icaoCode: "SLM",
            icaoLabel: "SLM",
            nameTr: "Surinam Airways",
            nameEn: "Surinam Airways",
            nameDe: "Surinam Airways"
        },
        {
            iataCode: "LX",
            iataLabel: "LX",
            icaoCode: "SWR",
            icaoLabel: "SWR",
            nameTr: "SWISS",
            nameEn: "SWISS",
            nameDe: "SWISS"
        },
        {
            iataCode: "RB",
            iataLabel: "RB",
            icaoCode: "SYR",
            icaoLabel: "SYR",
            nameTr: "Syrianair",
            nameEn: "Syrianair",
            nameDe: "Syrianair"
        },
        {
            iataCode: "TW",
            iataLabel: "TW",
            icaoCode: "TWB",
            icaoLabel: "TWB",
            nameTr: "T’way Air",
            nameEn: "T’way Air",
            nameDe: "T’way Air"
        },
        {
            iataCode: "DT",
            iataLabel: "DT",
            icaoCode: "DTA",
            icaoLabel: "DTA",
            nameTr: "TAAG Angola Airlines",
            nameEn: "TAAG Angola Airlines",
            nameDe: "TAAG Angola Airlines"
        },
        {
            iataCode: "TA",
            iataLabel: "TA",
            icaoCode: "TAI",
            icaoLabel: "TAI",
            nameTr: "TACA",
            nameEn: "TACA",
            nameDe: "TACA"
        },
        {
            iataCode: "VR",
            iataLabel: "VR",
            icaoCode: "TCV",
            icaoLabel: "TCV",
            nameTr: "TACV Cabo Verde Airlines",
            nameEn: "TACV Cabo Verde Airlines",
            nameDe: "TACV Cabo Verde Airlines"
        },
        {
            iataCode: "TP",
            iataLabel: "TP",
            icaoCode: "TAP",
            icaoLabel: "TAP",
            nameTr: "TAP Portugal",
            nameEn: "TAP Portugal",
            nameDe: "TAP Portugal"
        },
        {
            iataCode: "RO",
            iataLabel: "RO",
            icaoCode: "ROT",
            icaoLabel: "ROT",
            nameTr: "TAROM",
            nameEn: "TAROM",
            nameDe: "TAROM"
        },
        {
            iataCode: "SF",
            iataLabel: "SF",
            icaoCode: "DTH",
            icaoLabel: "DTH",
            nameTr: "Tassili Airlines",
            nameEn: "Tassili Airlines",
            nameDe: "Tassili Airlines"
        },
        {
            iataCode: "TG",
            iataLabel: "TG",
            icaoCode: "THA",
            icaoLabel: "THA",
            nameTr: "Thai Airways International",
            nameEn: "Thai Airways International",
            nameDe: "Thai Airways International"
        },
        {
            iataCode: "SL",
            iataLabel: "SL",
            icaoCode: "TLM",
            icaoLabel: "TLM",
            nameTr: "Thai Lion Air",
            nameEn: "Thai Lion Air",
            nameDe: "Thai Lion Air"
        },
        {
            iataCode: "WE",
            iataLabel: "WE",
            icaoCode: "THD",
            icaoLabel: "THD",
            nameTr: "Thai Smile",
            nameEn: "Thai Smile",
            nameDe: "Thai Smile"
        },
        {
            iataCode: "GS",
            iataLabel: "GS",
            icaoCode: "GCR",
            icaoLabel: "GCR",
            nameTr: "Tianjin Airlines",
            nameEn: "Tianjin Airlines",
            nameDe: "Tianjin Airlines"
        },
        {
            iataCode: "X3",
            iataLabel: "X3",
            icaoCode: "TUI",
            icaoLabel: "TUI",
            nameTr: "TUIfly",
            nameEn: "TUIfly",
            nameDe: "TUIfly"
        },
        {
            iataCode: "TU",
            iataLabel: "TU",
            icaoCode: "TAR",
            icaoLabel: "TAR",
            nameTr: "Tunisair",
            nameEn: "Tunisair",
            nameDe: "Tunisair"
        },
        {
            iataCode: "TK",
            iataLabel: "TK",
            icaoCode: "THY",
            icaoLabel: "THY",
            nameTr: "Türk Hava Yolları",
            nameEn: "Turkish Airlines",
            nameDe: "Turkish Airlines"
        },
        {
            iataCode: "PS",
            iataLabel: "PS",
            icaoCode: "AUI",
            icaoLabel: "AUI",
            nameTr: "Ukraine International Airlines",
            nameEn: "Ukraine International Airlines",
            nameDe: "Ukraine International Airlines"
        },
        {
            iataCode: "B7",
            iataLabel: "B7",
            icaoCode: "UIA",
            icaoLabel: "UIA",
            nameTr: "UNI AIR",
            nameEn: "UNI AIR",
            nameDe: "UNI AIR"
        },
        {
            iataCode: "UA",
            iataLabel: "UA",
            icaoCode: "UAL",
            icaoLabel: "UAL",
            nameTr: "United Airlines",
            nameEn: "United Airlines",
            nameDe: "United Airlines"
        },
        {
            iataCode: "5X",
            iataLabel: "5X",
            icaoCode: "UPS",
            icaoLabel: "UPS",
            nameTr: "UPS Airlines",
            nameEn: "UPS Airlines",
            nameDe: "UPS Airlines"
        },
        {
            iataCode: "U6",
            iataLabel: "U6",
            icaoCode: "SVR",
            icaoLabel: "SVR",
            nameTr: "Ural Airlines",
            nameEn: "Ural Airlines",
            nameDe: "Ural Airlines"
        },
        {
            iataCode: "UQ",
            iataLabel: "UQ",
            icaoCode: "CUH",
            icaoLabel: "CUH",
            nameTr: "Urumqi Air",
            nameEn: "Urumqi Air",
            nameDe: "Urumqi Air"
        },
        {
            iataCode: "UT",
            iataLabel: "UT",
            icaoCode: "UTA",
            icaoLabel: "UTA",
            nameTr: "UTair",
            nameEn: "UTair",
            nameDe: "UTair"
        },
        {
            iataCode: "HY",
            iataLabel: "HY",
            icaoCode: "UZB",
            icaoLabel: "UZB",
            nameTr: "Uzbekistan Airways",
            nameEn: "Uzbekistan Airways",
            nameDe: "Uzbekistan Airways"
        },
        {
            iataCode: "VJ",
            iataLabel: "VJ",
            icaoCode: "VJC",
            icaoLabel: "VJC",
            nameTr: "Vietjet",
            nameEn: "Vietjet",
            nameDe: "Vietjet"
        },
        {
            iataCode: "VN",
            iataLabel: "VN",
            icaoCode: "HVN",
            icaoLabel: "HVN",
            nameTr: "Vietnam Airlines",
            nameEn: "Vietnam Airlines",
            nameDe: "Vietnam Airlines"
        },
        {
            iataCode: "VS",
            iataLabel: "VS",
            icaoCode: "VIR",
            icaoLabel: "VIR",
            nameTr: "Virgin Atlantic",
            nameEn: "Virgin Atlantic",
            nameDe: "Virgin Atlantic"
        },
        {
            iataCode: "VA",
            iataLabel: "VA",
            icaoCode: "VOZ",
            icaoLabel: "VOZ",
            nameTr: "Virgin Australia",
            nameEn: "Virgin Australia",
            nameDe: "Virgin Australia"
        },
        {
            iataCode: "UK",
            iataLabel: "UK",
            icaoCode: "VTI",
            icaoLabel: "VTI",
            nameTr: "Vistara",
            nameEn: "Vistara",
            nameDe: "Vistara"
        },
        {
            iataCode: "Y4",
            iataLabel: "Y4",
            icaoCode: "VOI",
            icaoLabel: "VOI",
            nameTr: "Volaris",
            nameEn: "Volaris",
            nameDe: "Volaris"
        },
        {
            iataCode: "V7",
            iataLabel: "V7",
            icaoCode: "VOE",
            icaoLabel: "VOE",
            nameTr: "Volotea",
            nameEn: "Volotea",
            nameDe: "Volotea"
        },
        {
            iataCode: "EB",
            iataLabel: "EB",
            icaoCode: "PLM",
            icaoLabel: "PLM",
            nameTr: "Wamos Air",
            nameEn: "Wamos Air",
            nameDe: "Wamos Air"
        },
        {
            iataCode: "PN",
            iataLabel: "PN",
            icaoCode: "CHB",
            icaoLabel: "CHB",
            nameTr: "West Air",
            nameEn: "West Air",
            nameDe: "West Air"
        },
        {
            iataCode: "WS",
            iataLabel: "WS",
            icaoCode: "WJA",
            icaoLabel: "WJA",
            nameTr: "WestJet",
            nameEn: "WestJet",
            nameDe: "WestJet"
        },
        {
            iataCode: "WI",
            iataLabel: "WI",
            icaoCode: "WHT",
            icaoLabel: "WHT",
            nameTr: "White coloured by you",
            nameEn: "White coloured by you",
            nameDe: "White coloured by you"
        },
        {
            iataCode: "WF",
            iataLabel: "WF",
            icaoCode: "WIF",
            icaoLabel: "WIF",
            nameTr: "Wideroe",
            nameEn: "Wideroe",
            nameDe: "Wideroe"
        },
        {
            iataCode: "MF",
            iataLabel: "MF",
            icaoCode: "CXA",
            icaoLabel: "CXA",
            nameTr: "Xiamen Airlines",
            nameEn: "Xiamen Airlines",
            nameDe: "Xiamen Airlines"
        },
        {
            iataCode: "YG",
            iataLabel: "YG",
            icaoCode: "HYT",
            icaoLabel: "HYT",
            nameTr: "YTO Cargo Airlines",
            nameEn: "YTO Cargo Airlines",
            nameDe: "YTO Cargo Airlines"
        },
        {
            iataCode: "99",
            iataLabel: "AJ",
            icaoCode: "99 ",
            icaoLabel: "AJ ",
            nameTr: "AnadoluJet",
            nameEn: "AnadoluJet",
            nameDe: "AnadoluJet"
        },
        {
            iataCode: "WK",
            iataLabel: "WK",
            icaoCode: "EDW",
            icaoLabel: "EDW",
            nameTr: "Edelweiss Air",
            nameEn: "Edelweiss Air",
            nameDe: "Edelweiss Air"
        },
        {
            iataCode: "EE",
            iataLabel: "EE",
            icaoCode: "EST",
            icaoLabel: "EST",
            nameTr: "Nordica",
            nameEn: "Nordica",
            nameDe: "Nordica"
        },
        {
            iataCode: "N7",
            iataLabel: "N7",
            icaoCode: "FCM",
            icaoLabel: "FCM",
            nameTr: "Nordic Regional Airlines",
            nameEn: "Nordic Regional Airlines",
            nameDe: "Nordic Regional Airlines"
        },
        {
            iataCode: "4Y",
            iataLabel: "4Y",
            icaoCode: "OCN",
            icaoLabel: "OCN",
            nameTr: "EW Discover GmbH",
            nameEn: "EW Discover GmbH",
            nameDe: "EW Discover GmbH"
        },
        {
            iataCode: "XR",
            iataLabel: "XR",
            icaoCode: "CXI",
            icaoLabel: "CXI",
            nameTr: "Corendon Airlines Europe",
            nameEn: "Corendon Airlines Europe",
            nameDe: "Corendon Airlines Europe"
        },
        {
            iataCode: "CD",
            iataLabel: "CD",
            icaoCode: "CND",
            icaoLabel: "CND",
            nameTr: "Corendon Dutch Airlines B.V.",
            nameEn: "Corendon Dutch Airlines B.V.",
            nameDe: "Corendon Dutch Airlines B.V."
        },
        {
            iataCode: "HC",
            iataLabel: "HC",
            icaoCode: "SZN",
            icaoLabel: "SZN",
            nameTr: "Air Senegal",
            nameEn: "Air Senegal",
            nameDe: "Air Senegal"
        },
        {
            iataCode: "HF",
            iataLabel: "HF",
            icaoCode: "VRE",
            icaoLabel: "VRE",
            nameTr: "Air Cote D'Ivoire",
            nameEn: "Air Cote D'Ivoire",
            nameDe: "Air Cote D'Ivoire"
        },
        {
            iataCode: "AJ",
            iataLabel: "AJ",
            icaoCode: "AJ ",
            icaoLabel: "AJ ",
            nameTr: "Aztec Worldwide Airlines Inc.",
            nameEn: "Aztec Worldwide Airlines Inc.",
            nameDe: "Aztec Worldwide Airlines Inc."
        },
        {
            iataCode: "DY",
            iataLabel: "DY",
            icaoCode: "NAX",
            icaoLabel: "NAX",
            nameTr: "Norwegian Air",
            nameEn: "Norwegian Airline",
            nameDe: "Norwegische Fluggesellschaft"
        },
        {
            iataCode: "W9",
            iataLabel: "W9",
            icaoCode: "WUK",
            icaoLabel: "WUK",
            nameTr: "Wizz Air UK",
            nameEn: "Wizz Air UK",
            nameDe: "Wizz Air UK"
        },
        {
            iataCode: "D8",
            iataLabel: "D8",
            icaoCode: "NSZ",
            icaoLabel: "NSZ",
            nameTr: "Norwegian Air Sweden",
            nameEn: "Norwegian Air Sweden",
            nameDe: "Norwegian Air Sweden"
        },
        {
            iataCode: "VY",
            iataLabel: "VY",
            icaoCode: "VLG",
            icaoLabel: "VLG",
            nameTr: "Vueling Airlines",
            nameEn: "Vueling Airlines",
            nameDe: "Vueling Airlines"
        },
        {
            iataCode: "U2",
            iataLabel: "U2",
            icaoCode: "U2 ",
            icaoLabel: "U2 ",
            nameTr: "EasyJet",
            nameEn: "EasyJet",
            nameDe: "EasyJet"
        },
        {
            iataCode: "W6",
            iataLabel: "W6",
            icaoCode: "WZZ",
            icaoLabel: "WZZ",
            nameTr: "Wizz Air Hungary",
            nameEn: "Wizz Air Hungary",
            nameDe: "Wizz Air Hungary"
        },
        {
            iataCode: "FR",
            iataLabel: "FR",
            icaoCode: "RYR",
            icaoLabel: "RYR",
            nameTr: "Ryanair",
            nameEn: "Ryanair",
            nameDe: "Ryanair"
        },
        {
            iataCode: "JQ",
            iataLabel: "JQ",
            icaoCode: "JST",
            icaoLabel: "JST",
            nameTr: "Jetstar Airways",
            nameEn: "Jetstar Airways",
            nameDe: "Jetstar Airways"
        },
        {
            iataCode: "AL",
            iataLabel: "AL",
            icaoCode: "MAY",
            icaoLabel: "MAY",
            nameTr: "Malta Air",
            nameEn: "Malta Air",
            nameDe: "Malta Air"
        },
        {
            iataCode: "A5",
            iataLabel: "A5",
            icaoCode: "HOP",
            icaoLabel: "HOP",
            nameTr: "Air France Hop",
            nameEn: "Air France Hop",
            nameDe: "Air France Hop"
        },
        {
            iataCode: "2S",
            iataLabel: "2S",
            icaoCode: "STW",
            icaoLabel: "STW",
            nameTr: "Southwind Airlines",
            nameEn: "Southwind Airlines",
            nameDe: "Southwind Airlines"
        },
        {
            iataCode: "RR",
            iataLabel: "RR",
            icaoCode: "RYS",
            icaoLabel: "RYS",
            nameTr: "Ryanair Sun",
            nameEn: "Ryanair Sun",
            nameDe: "Ryanair Sun"
        },
        {
            iataCode: "HV",
            iataLabel: "HV",
            icaoCode: "TRA",
            icaoLabel: "TRA",
            nameTr: "Transavia",
            nameEn: "Transavia",
            nameDe: "Transavia"
        },
        {
            iataCode: "TO",
            iataLabel: "TO",
            icaoCode: "TVF",
            icaoLabel: "TVF",
            nameTr: "Transavia France",
            nameEn: "Transavia France",
            nameDe: "Transavia France"
        },
        {
            iataCode: "TR",
            iataLabel: "TR",
            icaoCode: "TGW",
            icaoLabel: "TGW",
            nameTr: "Scoot",
            nameEn: "Scoot",
            nameDe: "Scoot"
        },
        {
            iataCode: "A2",
            iataLabel: "A2",
            icaoCode: "AWG",
            icaoLabel: "AWG",
            nameTr: "Animawings",
            nameEn: "Animawings",
            nameDe: "Animawings"
        },
        {
            iataCode: "W2",
            iataLabel: "W2",
            icaoCode: "W2 ",
            icaoLabel: "W2 ",
            nameTr: "Flex Flight",
            nameEn: "Flex Flight",
            nameDe: "Flex Flight"
        },
        {
            iataCode: "BU",
            iataLabel: "BU",
            icaoCode: "FPY",
            icaoLabel: "FPY",
            nameTr: "Compagnie Africaine d'Aviation",
            nameEn: "Compagnie Africaine d'Aviation",
            nameDe: "Compagnie Africaine d'Aviation"
        },
        {
            iataCode: "SR",
            iataLabel: "SR",
            icaoCode: "SDR",
            icaoLabel: "SDR",
            nameTr: "Sundair GmbH",
            nameEn: "Sundair GmbH",
            nameDe: "Sundair GmbH"
        },
        {
            iataCode: "BE",
            iataLabel: "BE",
            icaoCode: "BEE",
            icaoLabel: "BEE",
            nameTr: "FlyBe",
            nameEn: "FlyBe",
            nameDe: "FlyBe"
        },
        {
            iataCode: "9F",
            iataLabel: "9F",
            icaoCode: "9F ",
            icaoLabel: "9F ",
            nameTr: "Eurostar International",
            nameEn: "Eurostar International",
            nameDe: "Eurostar International"
        },
        {
            iataCode: "RK",
            iataLabel: "RK",
            icaoCode: "RUK",
            icaoLabel: "RUK",
            nameTr: "Ryanair UK",
            nameEn: "Ryanair UK",
            nameDe: "Ryanair UK"
        },
        {
            iataCode: "2L",
            iataLabel: "2L",
            icaoCode: "OAW",
            icaoLabel: "OAW",
            nameTr: "Helvetic Airways",
            nameEn: "Helvetic Airways",
            nameDe: "Helvetic Airways"
        },
        {
            iataCode: "GQ",
            iataLabel: "GQ",
            icaoCode: "SEH",
            icaoLabel: "SEH",
            nameTr: "Sky Express",
            nameEn: "Sky Express",
            nameDe: "Sky Express"
        },
        {
            iataCode: "WM",
            iataLabel: "WM",
            icaoCode: "WIA",
            icaoLabel: "WIA",
            nameTr: "Winair",
            nameEn: "Winair",
            nameDe: "Winair"
        },
        {
            iataCode: "6Y",
            iataLabel: "6Y",
            icaoCode: "ART",
            icaoLabel: "ART",
            nameTr: "SmartLynx Airlines",
            nameEn: "SmartLynx Airlines",
            nameDe: "SmartLynx Airlines"
        },
        {
            iataCode: "5F",
            iataLabel: "5F",
            icaoCode: "FIA",
            icaoLabel: "FIA",
            nameTr: "FlyOne Airline",
            nameEn: "FlyOne Airline",
            nameDe: "FlyOne Airline"
        },
        {
            iataCode: "3K",
            iataLabel: "3K",
            icaoCode: "JSA",
            icaoLabel: "JSA",
            nameTr: "Jetstar Asia Airways",
            nameEn: "Jetstar Asia Airways",
            nameDe: "Jetstar Asia Airways"
        },
        {
            iataCode: "TB",
            iataLabel: "TB",
            icaoCode: "JAF",
            icaoLabel: "JAF",
            nameTr: "TUI fly Belgium",
            nameEn: "TUI fly Belgium",
            nameDe: "TUI fly Belgium"
        },
        {
            iataCode: "LS",
            iataLabel: "LS",
            icaoCode: "EXS",
            icaoLabel: "EXS",
            nameTr: "Jet2com",
            nameEn: "Jet2com",
            nameDe: "Jet2com"
        },
        {
            iataCode: "3F",
            iataLabel: "3F",
            icaoCode: "   ",
            icaoLabel: "   ",
            nameTr: "FLYONE ARMENIA",
            nameEn: "FLYONE ARMENIA",
            nameDe: "FLYONE ARMENIA"
        },
        {
            iataCode: "3L",
            iataLabel: "3L",
            icaoCode: "ADY",
            icaoLabel: "ADY",
            nameTr: "Air Arabia Abu Dhabi",
            nameEn: "Air Arabia Abu Dhabi",
            nameDe: "Air Arabia Abu Dhabi"
        },
        {
            iataCode: "I2",
            iataLabel: "I2",
            icaoCode: "IBS",
            icaoLabel: "IBS",
            nameTr: "Iberia Express",
            nameEn: "Iberia Express",
            nameDe: "Iberia Express"
        },
        {
            iataCode: "Q4",
            iataLabel: "Q4",
            icaoCode: "ELE",
            icaoLabel: "ELE",
            nameTr: "Euroairlines",
            nameEn: "Euroairlines",
            nameDe: "Euroairlines"
        },
        {
            iataCode: "A4",
            iataLabel: "A4",
            icaoCode: "AZO",
            icaoLabel: "AZO",
            nameTr: "Azimuth Airlines",
            nameEn: "Azimuth Airlines",
            nameDe: "Azimuth Airlines"
        },
        {
            iataCode: "DP",
            iataLabel: "DP",
            icaoCode: "PBD",
            icaoLabel: "PBD",
            nameTr: "Pobeda Airline",
            nameEn: "Pobeda Airline",
            nameDe: "Pobeda Airline"
        },
        {
            iataCode: "WZ",
            iataLabel: "WZ",
            icaoCode: "RWZ",
            icaoLabel: "RWZ",
            nameTr: "Red Wings Airlines",
            nameEn: "Red Wings Airlines",
            nameDe: "Red Wings Airlines"
        },
        {
            iataCode: "ZQ",
            iataLabel: "ZQ",
            icaoCode: "WDL",
            icaoLabel: "WDL",
            nameTr: "German Airlines",
            nameEn: "German Airlines",
            nameDe: "German Airlines"
        },
        {
            iataCode: "ZB",
            iataLabel: "ZB",
            icaoCode: "ABN",
            icaoLabel: "ABN",
            nameTr: "Air Albania",
            nameEn: "Air Albania",
            nameDe: "Air Albania"
        },
        {
            iataCode: "4M",
            iataLabel: "4M",
            icaoCode: "MGH",
            icaoLabel: "MGH",
            nameTr: "Mavi Gok Airlines",
            nameEn: "Mavi Gok Airlines",
            nameDe: "Mavi Gok Airlines"
        },
        {
            iataCode: "W4",
            iataLabel: "W4",
            icaoCode: "WMT",
            icaoLabel: "WMT",
            nameTr: "Wizz Air Malta",
            nameEn: "Wizz Air Malta",
            nameDe: "Wizz Air Malta"
        },
        {
            iataCode: "G8",
            iataLabel: "G8",
            icaoCode: "GOW",
            icaoLabel: "GOW",
            nameTr: "Go First Havayolu",
            nameEn: "Go First Airline",
            nameDe: "Go First Airline"
        },
        {
            iataCode: "2P",
            iataLabel: "2P",
            icaoCode: "GAP",
            icaoLabel: "GAP",
            nameTr: "PAL Express",
            nameEn: "PAL Express",
            nameDe: "PAL Express"
        },
        {
            iataCode: "H6",
            iataLabel: "H6",
            icaoCode: "BUC",
            icaoLabel: "BUC",
            nameTr: "Euro Charter",
            nameEn: "Euro Charter",
            nameDe: "Euro Charter"
        },
        {
            iataCode: "4R",
            iataLabel: "4R",
            icaoCode: "SEK",
            icaoLabel: "SEK",
            nameTr: "Star East Airline",
            nameEn: "Star East Airline",
            nameDe: "Star East Airline"
        },
        {
            iataCode: "SE",
            iataLabel: "SE",
            icaoCode: "XLF",
            icaoLabel: "XLF",
            nameTr: "XL Airways France",
            nameEn: "XL Airways France",
            nameDe: "XL Airways France"
        },
        {
            iataCode: "MM",
            iataLabel: "MM",
            icaoCode: "APJ",
            icaoLabel: "APJ",
            nameTr: "Peach Aviation",
            nameEn: "Peach Aviation",
            nameDe: "Peach Aviation"
        },
        {
            iataCode: "WG",
            iataLabel: "WG",
            icaoCode: "SWG",
            icaoLabel: "SWG",
            nameTr: "Sunwing Airlines",
            nameEn: "Sunwing Airlines",
            nameDe: "Sunwing Airlines"
        },
        {
            iataCode: "WA",
            iataLabel: "WA",
            icaoCode: "KLC",
            icaoLabel: "KLC",
            nameTr: "KLM Cityhopper",
            nameEn: "KLM Cityhopper",
            nameDe: "KLM Cityhopper"
        },
        {
            iataCode: "UD",
            iataLabel: "UD",
            icaoCode: "UD ",
            icaoLabel: "UD ",
            nameTr: "Fly Erbil",
            nameEn: "Fly Erbil",
            nameDe: "Fly Erbil"
        },
        {
            iataCode: "HW",
            iataLabel: "HW",
            icaoCode: "UBD",
            icaoLabel: "UBD",
            nameTr: "Fly Erbil",
            nameEn: "Fly Erbil",
            nameDe: "Fly Erbil"
        },
        {
            iataCode: "GM",
            iataLabel: "GM",
            icaoCode: "GSW",
            icaoLabel: "GSW",
            nameTr: "Chair Airlines",
            nameEn: "Chair Airlines",
            nameDe: "Chair Airlines"
        },
        {
            iataCode: "H4",
            iataLabel: "H4",
            icaoCode: "HYS",
            icaoLabel: "HYS",
            nameTr: "Sky Europe",
            nameEn: "Sky Europe",
            nameDe: "Sky Europe"
        },
        {
            iataCode: "A1",
            iataLabel: "A1",
            icaoCode: "   ",
            icaoLabel: "   ",
            nameTr: "A.P.G. Distribution System",
            nameEn: "A.P.G. Distribution System",
            nameDe: "A.P.G. Distribution System"
        },
        {
            iataCode: "TZ",
            iataLabel: "TZ",
            icaoCode: "TAH",
            icaoLabel: "TAH",
            nameTr: "Air Anka",
            nameEn: "Air Anka",
            nameDe: "Air Anka"
        },
        {
            iataCode: "VF",
            iataLabel: "VF",
            icaoCode: "   ",
            icaoLabel: "   ",
            nameTr: "AJet",
            nameEn: "AJet",
            nameDe: "AJet"
        },
        {
            iataCode: "HS",
            iataLabel: "HS",
            icaoCode: "000",
            icaoLabel: "000",
            nameTr: "Heston Airlines",
            nameEn: "Heston Airlines",
            nameDe: "Heston Airlines"
        },
        {
            iataCode: "F7",
            iataLabel: "F7",
            icaoCode: "RSY",
            icaoLabel: "RSY",
            nameTr: "I FLY Airlines",
            nameEn: "I FLY Airlines",
            nameDe: "I FLY Airlines"
        },
        {
            iataCode: "R3",
            iataLabel: "R3",
            icaoCode: "SYL",
            icaoLabel: "SYL",
            nameTr: "Yakutia Airlines",
            nameEn: "Yakutia Airlines",
            nameDe: "Yakutia Airlines"
        },
        {
            iataCode: "6R",
            iataLabel: "6R",
            icaoCode: "TNO",
            icaoLabel: "TNO",
            nameTr: "AeroUnion",
            nameEn: "AeroUnion",
            nameDe: "AeroUnion"
        },
        {
            iataCode: "QS",
            iataLabel: "QS",
            icaoCode: "TVS",
            icaoLabel: "TVS",
            nameTr: "Smartwings Havayolu",
            nameEn: "Smartwings Airline",
            nameDe: "Smartwings Airline"
        },
        {
            iataCode: "IO",
            iataLabel: "IO",
            icaoCode: "IAE",
            icaoLabel: "IAE",
            nameTr: "IrAero",
            nameEn: "IrAero",
            nameDe: "IrAero"
        },
        {
            iataCode: "3O",
            iataLabel: "3O",
            icaoCode: "MAC",
            icaoLabel: "MAC",
            nameTr: "Air Arabia",
            nameEn: "Air Arabia",
            nameDe: "Air Arabia"
        },
        {
            iataCode: "BS",
            iataLabel: "BS",
            icaoCode: "UBG",
            icaoLabel: "UBG",
            nameTr: "Bangla Airlines",
            nameEn: "Bangla Airlines",
            nameDe: "Bangla Airlines"
        },
        {
            iataCode: "H9",
            iataLabel: "H9",
            icaoCode: "HIM",
            icaoLabel: "HIM",
            nameTr: "Himalaya Airlines",
            nameEn: "Himalaya Airlines",
            nameDe: "Himalaya Airlines"
        }
    ]
};

export default airlineList;