<template>
   <div class="row-wrapper" v-bind="RowBind">
      <div class="exit-space" v-if="isExitRow"></div>
      <div class="row-inner-wrapper">
         <span class="row-number">{{ row.rowNumber }} </span>
         <div class="seat-groups-wrapper">
            <div class="seat-group" v-for="(seatgroup, seatgroupIndex) in row.seatGroups" :key="seatgroupIndex">
               <template v-for="(seat, seatIndex) in seatgroup.seats">
                  <Seat :currency="currency" :seat="seat" :key="seatIndex" />
               </template>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import Seat from "@/components/structural/flight/order-detail/ancillaries/seatmap2/Seat.vue";
export default {
   name: "SeatRow",
   components: {
      Seat,
   },
   props: {
      row: Object,
      currency: String,
   },

   computed: {
      RowBind() {
         return {
            "data-row-number": this.row.rowNumber,
            "data-row-class": this.row.class,
            "data-row-exit": this.row.isExitRow == "Exit seat" ? true : false,
         };
      },

      isExitRow() {
         return this.row.isExitRow == "Exit seat";
      },
   },
};
</script>
<style lang="scss" scoped>
.row-wrapper {
   // padding: 1rem;

   display: flex;
   flex-direction: column;
   width: fit-content;
   .exit-space {
      // background: #f00;
      // color: #fff;
      // padding: 0.5rem;
      // text-align: center;
      background-color: transparent;
      margin-left: 10px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NiIgaGVpZ2h0PSIxOSIgdmlld0JveD0iMCAwIDU2IDE5Ij4KICA8cGF0aCBpZD0iUG9seWdvbl8xIiBkYXRhLW5hbWU9IlBvbHlnb24gMSIgZD0iTTQsMCw4LDhIMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMTQpIHJvdGF0ZSgtOTApIiBmaWxsPSIjMzM1NzhjIi8+CiAgPHBhdGggaWQ9IlBvbHlnb25fMyIgZGF0YS1uYW1lPSJQb2x5Z29uIDMiIGQ9Ik00LDAsOCw4SDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1NiA2KSByb3RhdGUoOTApIiBmaWxsPSIjMzM1NzhjIi8+CiAgPHRleHQgaWQ9IkVYSVQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzIDE1KSIgZmlsbD0iIzMzNTc4YyIgZm9udC1zaXplPSIxNCIgZm9udC1mYW1pbHk9IlJvYm90by1NZWRpdW0sIFJvYm90byIgZm9udC13ZWlnaHQ9IjUwMCIgbGV0dGVyLXNwYWNpbmc9IjAuMDQyZW0iPjx0c3BhbiB4PSIwIiB5PSIwIj5FWElUPC90c3Bhbj48L3RleHQ+Cjwvc3ZnPgo=");
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 42px;
   }

   .row-inner-wrapper {
      display: flex;
      .row-number {
         display: block;
         width: 20px;
         /* text-align: center; */
         display: flex;
         align-items: center;
         justify-content: center;
         font-weight: 500;
      }
      .seat-groups-wrapper {
         display: flex;

         .seat-group {
            display: flex;
            align-items: flex-end;
            margin-right: 2rem;

            &:last-child {
               margin-right: 0 !important;
            }
         }
      }
   }
}
</style>
