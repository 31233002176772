<template>
   <div class="media-orientation-check-c">
      <div class="animation-wrapper">
         <lottie-animation :speed="0.75" path="assets/animations/mediaorientation.json" id="reverse-animation" />
         <div class="media-orientation-message">
            {{ $t("common_lblMediaOrientationMsg") }}
         </div>
      </div>
   </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
   name: "MediaOrientationCheck",
   components: {
      LottieAnimation,
   },
   data() {
      return {};
   },
};
</script>

<style lang="scss">
.media-orientation-check-c {
   position: fixed;
   background: #fff;
   inset: 0;
   z-index: 999;
   .animation-wrapper {
      width: 100vw;
      height: 100vh;
      #reverse-animation {
         transform: scale(0.5);
         //  transform-origin: top;
      }
   }
   .media-orientation-message {
      position: fixed;
      bottom: 3rem;
      width: 100%;
      text-align: center;
      padding: 0 10%;
      font-weight: 500;
      font-size: var(--xlarge);
   }
}
</style>
