<template>
   <div class="price-widget-inner" v-if="typeof totalPrice == 'object'">
      <div class="showExtras" v-if="showCommission">
         <button @click="TurnOffVisibility()">
            <div clasS="checkout-basket-header-action gts-animation-scale-and-color">
               <i class="gts-icon icon-sm" :class="isVisibleCommission ? 'icon-eye-closed ' : 'icon-eye '"> </i>
            </div>
         </button>
      </div>
      <div class="price-wrapper">
         <div class="price-list" :class="checkoutMode ? '--checkoutMode' : ''">
            <!--Expedia Provider Special Fields-->
            <template v-if="isPriceBreakdownShown">
               <div class="price-list-item col-3">
                  <div class="price-list-item-section">
                     <span class="label">{{ $t(`${label}_lblAccomodationInfo`) }}</span>
                  </div>
                  <div class="price-list-item-section total-price">
                     <span class="masked">{{ totalPrice.baseRate | price(totalPrice.currency) }}</span>
                  </div>
               </div>
               <div class="price-list-item col-3 flex-column">
                  <div class="price-list-item-section --provider">
                     <span class="label --provider">{{ $t(`${label}_lblTax${countryCode == "US" ? "US" : ""}`) }}</span>
                     <span class="masked">{{ totalPrice.tax | price(totalPrice.currency) }}</span>
                  </div>
                  <div class="price-list-item-section --provider">
                     <span class="label --provider">{{ $t(`${label}_lblproviderTotal`) }}</span>
                     <span class="masked">{{ totalPrice.providerTotal | price(totalPrice.currency) }}</span>
                  </div>
               </div>
            </template>
            <div class="price-list-item col-3" v-if="!isPriceBreakdownShown && BasePrice && totalPriceInfo.pnr?.status != 2">
               <div class="price-list-item-section">
                  <span class="label">{{ $t(`${label}_lblBaseTotal`) }}</span>
               </div>
               <div class="price-list-item-section total-price">
                  <span class="masked">{{ BasePrice | price(totalPrice.currency) }}</span>
               </div>
            </div>
            <div class="price-list-item col-3" v-if="!isPriceBreakdownShown && BasePrice && totalPriceInfo.pnr?.status == 2">
               <div class="price-list-item-section">
                  <span class="label">{{ $t(`${label}_lblBaseTotal`) }}</span>
               </div>
               <div class="price-list-item-section total-price">
                  <span class="masked">{{ totalPriceInfo.issueNetTotal | price(totalPrice.currency) }}</span>
               </div>
            </div>
            <div class="price-list-item col-3" v-if="totalPrice.reissueTotal > 0">
               <div class="price-list-item-section">
                  <span class="label">{{ $t(`${label}_lblReissueTotal`) }}</span>
               </div>
               <div class="d-flex align-items-md-center flight-additional">
                  <div class="grand-total-column action" @click="handlerShowReissues">
                     <UITooltip
                        :tooltip-title="$t('common_toggle')"
                        tooltip-icon="gts-icon icon-eye-closed"
                        tooltip-position="bottom"
                        no-padding
                        v-if="showReissues">
                     </UITooltip>
                     <UITooltip :tooltip-title="$t('common_toggle')" tooltip-icon="gts-icon icon-eye" tooltip-position="bottom" no-padding v-else>
                     </UITooltip>
                  </div>
                  <div class="price-list-item-section total-price">
                     <span class="masked">{{ totalPrice.reissueTotal | price(totalPrice.currency) }}</span>
                  </div>
               </div>
            </div>
            <div class="price-list-item col-3" v-if="!isPriceBreakdownShown && HasAncillaryTotal">
               <div class="price-list-item-section">
                  <span class="label">{{ $t(`${label}_lblAncillaryTotal`) }}</span>
               </div>
               <div class="d-flex align-items-md-center flight-additional">
                  <div class="grand-total-column action" @click="handlerShowAncillary">
                     <UITooltip
                        :tooltip-title="$t('common_toggle')"
                        tooltip-icon="gts-icon icon-eye-closed"
                        tooltip-position="bottom"
                        no-padding
                        v-if="$store.state.flight.orderDetail.showAncillary">
                     </UITooltip>
                     <UITooltip :tooltip-title="$t('common_toggle')" tooltip-icon="gts-icon icon-eye" tooltip-position="bottom" no-padding v-else>
                     </UITooltip>
                  </div>
                  <div class="price-list-item-section total-price">
                     <span class="--ancillary masked">{{ totalPrice.ancillaryTotal | price(totalPrice.currency) }}</span>
                  </div>
               </div>
            </div>
            <div class="price-list-item col-3" v-if="SaleTotal && label != 'hotelOrderDetail'">
               <div class="price-list-item-section">
                  <span class="label">{{ $t(`${label}_lblSaleTotal`) }}</span>
               </div>
               <div class="price-list-item-section per-day-amount">
                  <span class="masked txt-black">{{ SaleTotal | price(totalPrice.currency) }}</span>
               </div>
            </div>
            <template v-if="$options.filters.EOdrType(packages?.type) != $options.filters.EOdrType(5)">
               <div v-show="!isCommisionSelectHidden" v-if="!isVisibleCommission" class="price-list-item col-3">
                  <div class="price-list-item-section">
                     <span class="label">{{ $t(`${label}_lblAgencyCommission`) }}</span>
                  </div>
                  <div>
                     <div v-if="checkoutMode" class="price-list-item-section">
                        <!-- :selectedOpt="DefaultAgencyCommissionRatio" -->
                        <UISelect
                           :isRentacar="label == 'rentacarCheckout'"
                           light-theme
                           commision-variant
                           id="transferCheckout_cmbAgencyCommission"
                           :options="agencyCommission.options"
                           :selectedOpt="DefaultAgencyCommissionRatio"
                           v-model="agencyCommission.agencyCommissionRatio"
                           @input="triggerAgencyCommission('select')" />
                     </div>
                     <div class="price-list-item-section total-price">
                        <span class="float-end masked" v-if="!isNaN(Number(totalPrice.agencyCommission))">{{
                           Number(totalPrice.agencyCommission) | price(totalPrice.currency)
                        }}</span>
                     </div>
                  </div>
               </div>
            </template>
            <div class="price-list-item col-3" v-show="!isVisibleCommission" v-if="!isPriceBreakdownShown && label == 'hotelCheckout'">
               <div class="price-list-item-section">
                  <span class="label">{{ $t("hotelCheckout_lblTotalPrice") }}</span>
               </div>
               <div class="price-list-item-section total-price flexed">
                  <span class="masked">{{ totalPrice.total | price(totalPrice.currency) }}</span>
               </div>
            </div>
            <div
               class="price-list-item col-3"
               v-show="!isVisibleCommission"
               v-if="!isPriceBreakdownShown && (label == 'hotelCheckout' || label == 'hotelOrderDetail') && totalPrice.addOnTotal != 0">
               <div class="price-list-item-section">
                  <span v-if="totalPrice.addOnTotal != 0" class="label">{{ $t("hotelCheckout_lblAddOnTotalPrice") }}</span>
               </div>
               <div class="price-list-item-section total-price flexed">
                  <span v-if="totalPrice.addOnTotal != 0" class="masked">{{ totalPrice.addOnTotal | price(totalPrice.currency) }}</span>
               </div>
            </div>
            <div
               v-if="checkoutMode"
               v-show="!isVisibleCommission"
               class="price-list-item col-3"
               :class="uiLabel == 'flightCheckout' ? '--flight-variant' : ''">
               <div class="price-list-item-section">
                  <span class="label">{{ $t(`${label}_lblAgencyExtraCommission`) }}</span>
               </div>
               <div v-if="uiLabel == 'flightCheckout'" class="price-list-item-section__flight">
                  <input min="0" type="number" id="agency-commission-input" v-model="agencyCommission.agencyCommissionExtra" />
                  <div class="add-comm-button">
                     <UITooltip
                        class="gts-animation-scale"
                        @click.native="handlerAddCommission"
                        :tooltip-title="$t('AgencyExtraCommission_checkOutInformation')"
                        tooltip-icon="gts-icon icon-add-leg icon-sm"
                        tooltip-position="bottom"
                        no-padding />
                  </div>
               </div>
               <div v-else-if="uiLabel == 'flightOrderDetail' && totalPriceInfo.pnr.status == 2" class="price-list-item-section__flight">
                  <input
                     min="0"
                     :max="totalPriceInfo.issueNetTotal"
                     type="number"
                     id="agency-commission-input"
                     v-model="totalPriceInfo.issueAceTotal" />
                  <div class="add-comm-button">
                     <UITooltip
                        class="gts-animation-scale"
                        @click.native="triggerAgencyCommission('input')"
                        :tooltip-title="$t('AgencyExtraCommission_checkOutInformation')"
                        tooltip-icon="gts-icon icon-add-leg icon-sm"
                        tooltip-position="bottom"
                        no-padding />
                  </div>
               </div>
               <div v-else class="price-list-item-section">
                  <input
                     min="0"
                     type="number"
                     id="agency-commission-input"
                     @input="triggerAgencyCommission('input')"
                     v-model="agencyCommission.agencyCommissionExtra" />
               </div>
            </div>
            <!--  Flight payment fee Total -->

            <div v-show="totalPrice.paymentFeeTotal > 0" class="price-list-item col-3">
               <div class="price-list-item-section">
                  <span class="label">{{ $t(`${label}_lblPaymentFeeTotal`) }}</span>
               </div>
               <div class="price-list-item-section font-bold">
                  <span class="txt-black masked">{{ totalPrice.paymentFeeTotal | price(totalPrice.currency) }}</span>
               </div>
            </div>

            <!-- Reverse Total -->

            <div v-if="totalPrice.reverseTotal && totalPrice.reverseTotal != 0" class="price-list-item item-reverse col-3">
               <div class="price-list-item-section-wrapper">
                  <div class="price-list-item-section">
                     <span class="label">{{ $t(`${label}_lblReverseTotal`) }}</span>
                  </div>
                  <div class="price-list-item-section font-bold">
                     <span class="txt-black masked">-{{ Math.abs(totalPrice.reverseTotal) | price(totalPrice.currency) }}</span>
                  </div>
               </div>
               <ul class="reverse-detail" v-if="label == 'flightOrderDetail'">
                  <li>
                     <span>{{ $t(`${label}_lblSupplierReturn`) }}</span>
                     <!-- <span class="txt-bold">{{ (Math.abs(totalPrice.reverseTotal) - totalPriceInfo.reverseAgencyCommissionTotal) | price(totalPrice.currency) }}</span> -->
                     <span class="txt-bold"
                        >-{{ (Math.abs(totalPrice.reverseTotal) + totalPriceInfo.reverseAgencyCommissionTotal) | price(totalPrice.currency) }}</span
                     >
                  </li>
                  <li>
                     <span>{{ $t(`${label}_lblAgencyServiceFee`) }}</span>
                     <span class="txt-bold">{{ totalPriceInfo.reverseAgencyCommissionTotal | price(totalPrice.currency) }}</span>
                  </li>
               </ul>
            </div>

            <!-- Reverse Ancillary Total -->

            <div v-if="totalPrice.reverseAncillaryTotal && totalPrice?.reverseAncillaryTotal != 0" class="price-list-item col-3">
               <div class="price-list-item-section">
                  <span class="label">{{ $t(`${label}_lblReverseTotalAncillary`) }}</span>
               </div>
               <div class="price-list-item-section font-bold">
                  <span class="txt-black masked">-{{ Math.abs(totalPrice.reverseAncillaryTotal) | price(totalPrice.currency) }}</span>
               </div>
            </div>
            <!--Grand Total -->
            <div class="price-list-item col-3" v-if="$options.filters.EOdrType(packages?.type) != $options.filters.EOdrType(5)">
               <div class="price-list-item-section" id="singleTotalPrice">
                  <span class="label">{{ $t(`${label}_lblGrandTotal`) }}</span>
               </div>
               <div class="price-list-item-section total-amount">
                  <span class="masked">{{ GrandTotal | price(totalPrice.currency) }}</span>
               </div>
            </div>
            <div class="price-list-item col-3" v-else>
               <div class="price-list-item-section" id="singleTotalPrice">
                  <span class="label">{{ $t(`${label}_lblPackageGrandTotal`) }}</span>
               </div>
               <div class="price-list-item-section total-amount">
                  <span class="masked">{{ packages.total | price(packages.currency) }}</span>
               </div>
            </div>
            <div v-if="isPriceBreakdownShown && SetCityTaxLabel" class="price-list-item col-3 --city-tax">
               <div class="price-list-item-section d-flex">
                  <i class="gts-icon icon-gts-info-icon-black icon-sm"></i>
                  <span class="label city-tax ml-2 masked">{{ SetCityTaxLabel }}</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import UISelect from "@/components/unitary/form2/UISelectBox.vue";
import UITooltip from "@/components/utils/GtsToolTips.vue";
import debounce from "lodash.debounce";
export default {
   name: "UIPriceWidget",
   props: {
      packages: Object,
      countryCode: { type: String },
      totalPriceInfo: { type: Object },
      label: { type: String },
      checkoutMode: { type: Boolean },
      saleTotal: { type: [Number, String] },
      showCommission: { type: Boolean, default: false },
   },
   components: {
      UISelect,
      UITooltip,
   },
   data() {
      return {
         showReissues: false,
         uiLabel: this.label,
         agencyCommission: {
            options: [],
            agencyCommissionRatio:
               this.totalPriceInfo != null && this.totalPriceInfo.agencyCommissionRatio != null ? this.totalPriceInfo.agencyCommissionRatio : 0.1,
            agencyCommissionExtra:
               this.$store.state.flight.checkout.request.agencyCommissionExtra > 0
                  ? this.$store.state.flight.checkout.request.agencyCommissionExtra
                  : this.totalPriceInfo != null && this.totalPriceInfo.agencyCommissionExtra != null
                  ? this.totalPriceInfo.agencyCommissionExtra
                  : 0,
         },
         isVisibleCommission: false,
         alwaysCheck: true,
      };
   },
   computed: {
      SetCityTaxLabel() {
         if (!this.totalPrice.cityTax) return false;
         return this.$t("hotelCheckout_lblCityTax").replace("#VALUE1#", `${this.totalPrice.cityTax}`);
      },
      isCommisionSelectHidden() {
         return this.uiLabel == "flightOrderDetail" ||
            this.uiLabel == "flightCheckout" ||
            (this.totalPriceInfo.priceViewType == 2 && this.uiLabel == "hotelCheckout")
            ? true
            : false;
      },
      HasAncillaryTotal() {
         if (this.totalPrice.ancillaryTotal && this.totalPrice.ancillaryTotal > 0) return true;
         else return false;
      },
      BasePrice() {
         return this.totalPrice.providerTotal || this.totalPrice.issueTotal || this.totalPrice.baseAmount
            ? this.calculateBasePrice(this.totalPrice)
            : null;
      },
      GrandTotal() {
         if (this.isPriceBreakdownShown && this.checkoutMode) return this.totalPriceInfo.grandTotal;
         if (this.totalPrice.grandTotal) return Number(this.totalPrice.grandTotal);
         if (this.totalPrice.total) return Number(this.totalPrice.total);
         return 0;
      },
      SaleTotal() {
         return Number(this.totalPrice.saleTotal);
      },
      totalPrice() {
         return this.totalPriceInfo ? this.totalPriceInfo : {};
      },
      isPriceBreakdownShown() {
         // for check hotel price breakdown(related to expedia provider)
         if (!this.totalPriceInfo.priceViewType) return false;
         return this.totalPriceInfo && this.totalPriceInfo.priceViewType && this.totalPriceInfo.priceViewType == 2;
      },
      DefaultAgencyCommissionRatio() {
         return this.agencyCommission.agencyCommissionRatio;
      },
   },
   created() {
      if (this.totalPrice.cityTax) {
         this.cityTax = this.totalPrice.cityTax;
      }
      if (this.checkoutMode) {
         const MAX_AGENT_RATIO_COUNT = 25;

         for (let i = 0; i <= MAX_AGENT_RATIO_COUNT; i++) {
            let optionObj = {
               name: `${i}%`,
               value: i / 100,
            };
            this.agencyCommission.options.push(optionObj);
         }
      }
   },
   methods: {
      // emitting event to parent component to update agency commission
      triggerAgencyCommission: debounce(function (type) {
         if (type == "input" && this.uiLabel == "flightCheckout" && this.agencyCommission.agencyCommissionExtra > this.totalPrice.grandTotal) {
            this.agencyCommission.agencyCommissionExtra = parseInt(this.totalPrice.grandTotal - 1);
         }
         if (this.uiLabel != "flightOrderDetail") {
            this.$emit("commission-update", {
               type: type,
               agencyCommission: this.agencyCommission,
            });
         }
         if (type == "input" && this.uiLabel == "flightOrderDetail" && this.totalPriceInfo.pnr.status == 2) {
            if (this.totalPriceInfo.issueAceTotal < this.totalPriceInfo.issueNetTotal) {
               this.$parent.$parent.$parent.$emit("agency-commission-extra", {
                  pnrIndex: this.totalPriceInfo.pnr.pnrIndex,
                  agencyCommissionExtra: this.totalPriceInfo.issueAceTotal,
               });
            } else {
               this.totalPriceInfo.issueAceTotal = parseInt(this.totalPriceInfo.issueNetTotal - 1);
            }
         }
      }, 550),
      handlerShowAncillary() {
         const self = this;
         self.showAncillary = !self.showAncillary;
         self.$store.state.flight.orderDetail.showAncillary = !self.$store.state.flight.orderDetail.showAncillary;
      },
      handlerShowReissues() {
         const self = this;
         self.showReissues = !self.showReissues;
         self.$store.state.flight.orderDetail.showReissues = !self.$store.state.flight.orderDetail.showReissues;
      },
      handlerAddCommission() {
         this.$emit("commission-update", {
            type: "button",
            agencyCommission: this.agencyCommission,
         });
      },
      calculateBasePrice(obj) {
         return obj.providerTotal || obj.issueTotal || obj.baseAmount;
      },
      TurnOffVisibility() {
         this.isVisibleCommission = !this.isVisibleCommission;
         if (!this.alwaysCheck) {
            this.alwaysCheck == true;
         }
      },
   },
   watch: {
      agencyCommission: {
         deep: true,
         handler(newValue) {
            this.$emit("getAgencyCommisions", newValue);
         },
      },
      "$store.state.flight.checkout.request.agencyCommissionExtra": {
         handler: function (val) {
            const self = this;
            if (val == "") self.agencyCommission.agencyCommissionExtra = 0;
            self.agencyCommission.agencyCommissionExtra = val > self.totalPrice.issueTotal ? parseInt(self.totalPrice.issueTotal - 1) : val;
         },
      },
      "totalPriceInfo.agencyCommissionRatio": {
         handler: function (val) {
            this.agencyCommission.agencyCommissionRatio = val;
         },
      },
      "agencyCommission.agencyCommissionExtra": {
         handler: function (val) {
            if (Number(val) < 0) {
               this.agencyCommission.agencyCommissionExtra = "";
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.price-list-item-section-wrapper {
   display: flex;
   width: 100%;
   justify-content: space-between;
}
#singleTotalPrice {
   padding-right: 24px;
}
.showExtras {
   display: flex;
   justify-content: flex-end;
   margin-bottom: 0.1rem;
}
.showExtras button {
   color: black;
   background: transparent;
}
.price-widget-inner {
   display: flex;
   flex-direction: column;
   .price-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      .price-list {
         width: 30%;
         display: flex;
         flex-direction: column;
         align-items: flex-end;
         background: #ffffff 0% 0% no-repeat padding-box;
         border: 2px solid #ebebeb;
         border-radius: 8px;

         @media (max-width: 576px) {
            width: 100% !important;
         }

         &.--checkoutMode {
            width: auto;
         }
         .price-list-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px dashed #d9d9d9;
            padding: 16px;

            .flight-additional {
               display: flex;
               align-items: center;
               .gts-tooltip {
                  cursor: pointer;
                  padding-bottom: 0 !important;
                  margin-right: 0.5rem;
               }
            }

            &:last-child {
               margin-bottom: 0;
               border-bottom: none;
            }
            &.--city-tax {
               background: #ededed;
               max-height: 52px;
               height: 52px;
            }
            &.--flight-variant {
               padding: 16px 2px 16px 16px;
            }

            .price-list-item-section {
               & > .form-item {
                  width: 100px;
                  max-width: 100px;
               }
               @media (max-width: 767.98px) {
                  & > .form-item {
                     width: 80px;
                     max-width: 100px;
                  }
               }
               span.label {
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  font-weight: 700;
                  color: #363636;
                  &.--provider {
                     font-weight: normal;
                     color: #b3b3b3;
                     font-size: var(--small);
                  }

                  &.city-tax::after {
                     content: "";
                  }

                  &::after {
                     content: ":";
                     position: relative;
                     display: block;
                     margin-left: 8px;
                  }
               }
               &.flexed {
                  display: flex;
                  flex-direction: column;
               }
               &.total-price {
                  font-size: 16px;
                  font-weight: 700;
                  color: #363940;
                  .--ancillary {
                     color: var(--accent-primary);
                  }
               }
               &.total-amount {
                  font-size: 22px;
                  font-weight: 700;
                  color: #3e6fb3;
               }
               &.--provider {
                  color: #b3b3b3;
                  font-size: var(--small);
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
               }
               #agency-commission-input {
                  border: 1px solid #ccc;
                  border-radius: 1rem;
                  width: 70px;
                  text-indent: 0.5rem;
                  text-align: right;

                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                     -webkit-appearance: none;
                     margin: 0;
                  }
                  -moz-appearance: textfield;
               }
               &__flight {
                  display: flex;
                  align-items: center;

                  #agency-commission-input {
                     border: 1px solid #ccc;
                     border-radius: 1rem;
                     width: 70px;
                     text-indent: 0.5rem;
                     text-align: right;

                     &::-webkit-outer-spin-button,
                     &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                     }
                     -moz-appearance: textfield;
                  }
                  .add-comm-button {
                     .gts-tooltip {
                        padding-bottom: 0 !important;
                     }
                  }
               }
            }
            &.item-reverse {
               display: flex;
               flex-direction: column;
               background: #fff7ed;

               .reverse-detail {
                  width: 100%;
                  padding-left: 1.5rem;
                  opacity: 0.75;
                  padding-top: 0.125rem;
                  // font-size: 0.9rem;
                  li {
                     display: flex;
                     width: 100%;
                     justify-content: space-between;
                  }
               }
            }
         }
         @media (max-width: 767.98px) {
            .price-list-item-section {
               &:first-child {
                  width: auto;
               }
            }
         }
      }
   }
   @media (max-width: 767.98px) {
      .price-list {
         width: auto;
      }
   }
}
</style>
