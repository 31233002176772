import axios from "axios";
import https from "https";
import * as hotelService from "./hotel.api";
import * as paymentService from "./paymentService";
import * as flightService from "./flight.api";
import * as transferService from "./transfer.api";
import * as rentacarService from "./rentacar.api";
import * as financeService from "./finance.api";
import * as orderService from "./order.api";
import * as taskService from "./task.api";
import * as userService from "./user.api";
import * as customerService from "./customer.api";
import * as globalService from "./global.api";
import * as tasklistService from "./tasklist.api";
import * as notesService from "./notes.api";
import * as dashboardService from "./dashboard.api";
import * as documentService from "./documents.api";
import * as agencyService from "./agency.api";
import * as reportService from "./report.api";
import * as announcementService from "./announcement.api";
import PRIVATE_HTTP from "./private.http";

import TokenService from "./token.api";

export var AUTH_HTTP = axios.create({
   baseURL: process.env.VUE_APP_API_URL,
   timeout: process.env.VUE_AXIOS_TIMEOUT,
   headers: {
      Authorization: `Bearer ${TokenService.getJwtToken()}`,
      "Reisetech-Version": process.env.PACKAGE_VERSION,
   },
   httpAgent: new https.Agent({
      rejectUnauthorized: false,
   }),
});

export var PUBLIC_HTTP = axios.create({
   baseURL: process.env.VUE_APP_API_URL,
   timeout: process.env.VUE_AXIOS_TIMEOUT,
   headers: {
      "Reisetech-Version": process.env.PACKAGE_VERSION,
   },
   httpAgent: new https.Agent({
      rejectUnauthorized: false,
   }),
});

export {
   hotelService,
   flightService,
   transferService,
   rentacarService,
   financeService,
   orderService,
   taskService,
   userService,
   customerService,
   globalService,
   TokenService,
   tasklistService,
   notesService,
   dashboardService,
   documentService,
   agencyService,
   reportService,
   announcementService,
   paymentService,
   PRIVATE_HTTP,
};
