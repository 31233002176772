<template>
   <div class="seat-map-generator-container">
      <div class="seat-map-generator" id="seat-map-identifer">
         <template v-for="(row, rowIndex) in map">
            <SeatRow :currency="currency" :row="row" :key="rowIndex" />
         </template>
      </div>
      <div class="seat-map-navigator" v-if="false">
         <div id="navigator">
            <!-- Navigator box -->
            <div id="nav-indicator"></div>
         </div>
      </div>
   </div>
</template>

<script>
import SeatRow from "@/components/structural/flight/order-detail/ancillaries/seatmap2/SeatRow.vue";

export default {
   name: "SeatmapGenerator",
   components: {
      SeatRow,
   },
   props: {
      currency: String,
      map: Array,
   },

   mounted() {
      // document.querySelector(".seat-map-generator-container").addEventListener("scroll", function () {
      //    var container = this;
      //    var totalHeight = container.scrollHeight;
      //    var visibleHeight = container.clientHeight;
      //    var scrollPosition = container.scrollTop;
      //    console.log(totalHeight, visibleHeight, scrollPosition);
      //    var navHeight = document.getElementById("navigator").clientHeight;
      //    var indicator = document.getElementById("nav-indicator");
      //    var indicatorHeight = (visibleHeight / totalHeight) * navHeight;
      //    // indicator.style.height = indicatorHeight + "px";
      //    var indicatorPosition = (scrollPosition / totalHeight) * navHeight;
      //    indicator.style.top = indicatorPosition + "px";
      //    console.log(totalHeight, visibleHeight, scrollPosition, navHeight, indicatorHeight);
      // });
   },
};
</script>

<style lang="scss" scoped>
.seat-map-generator-container {
   display: flex;
   justify-content: center;
   align-items: flex-start;
   // border: 1px solid #eee;
   width: 100%;
   padding: 0rem;
   // padding-bottom: 5rem;
   height: 550px;
   overflow: auto;
   position: relative;

   @media only screen and (max-width: 768px) {
      height: 450px;
      transform-origin: top;
      transform: scale(0.9);
   }

   .seat-map-generator {
      background-color: #fff;
      padding: 1rem;
      border-radius: 1rem;
      border: 5px solid #ecf0f2;
      box-shadow: inset 0px 0px 30px #33538033;
      width: fit-content;
   }

   .row {
      margin-bottom: 1rem;
      border-bottom: 1px solid;
   }

   .seat-map-navigator {
      position: sticky; // Fixed positioning for the navigator
      top: 10px; // Stick to the top of the viewport
      right: 10px; // Stick to the right of the viewport
      height: 120px;
      width: 100px;
      background-color: #fff;
      border: 3px solid #eee;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      z-index: 10; // Ensure it appears above other content
   }

   #nav-indicator {
      width: 100%;
      height: 60px;
      border: 2px solid #34b7ff;
      background: #34b7ff30;
      position: relative;
   }
}

// Tooltip styling
.tooltip {
   position: absolute;
   z-index: 1000;
   background-color: rgba(0, 0, 0, 0.75);
   color: #fff;
   padding: 0.5rem;
   border-radius: 0.25rem;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.deneme {
   position: sticky;
   top: 0;
}
</style>
